import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
} from "@fluentui/react-components";

interface IDeleteDialog {
  open: boolean;
  closeDialog: React.MouseEventHandler<HTMLButtonElement>;
  deleteF: React.MouseEventHandler<HTMLButtonElement>;
}

export const DeleteDialog: React.FC<IDeleteDialog> = ({
  open,
  closeDialog,
  deleteF,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog modalType="alert" open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {t("absencesOwnHr.dialog.areYouSureYouWantToDeleteTheseUsers")}
          </DialogTitle>
          <DialogContent>
            {t(
              "absencesOwnHr.dialog.theDataAssociatedWithTheseUsersWillBeRemoved"
            )}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                type="button"
                onClick={closeDialog}
              >
                {t("absencesOwnHr.dialog.close")}
              </Button>
            </DialogTrigger>
            <Button appearance="primary" type="button" onClick={deleteF}>
              {t("absencesOwnHr.dialog.yes")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
