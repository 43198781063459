import { useState } from "react";
import "./SideNav.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { useUserStore } from "../../store/userStore";
import helpers from "../../helpers/helpers";

const SideNav: React.FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>(
    location.pathname === "/dashboard/employeesManagement"
      ? "Employees Management"
      : location.pathname === "/dashboard/absencesOwnHr"
      ? "Absences"
      : "Attendance"
  );
  const userStore = useStore(useUserStore);

  return (
    <div className={`absencesSideNav ${isOpen ? "absencesOpen" : ""}`}>
      <ul className="sideNavMenuList">
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Attendances",
          "Can Read"
        ) ? (
          <li
            className={`sideNavMenuItem ${
              activeItem === "Attendance" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/dashboard/attendanceOwnHr");
              setActiveItem("Attendance");
            }}
          >
            {t("absencesOwnHr.sideNav.attendance")}
          </li>
        ) : null}
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Absences",
          "Can Read"
        ) ? (
          <li
            className={`sideNavMenuItem ${
              activeItem === "Absences" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/dashboard/absencesOwnHr");
              setActiveItem("Absences");
            }}
          >
            {t("absencesOwnHr.sideNav.absences")}
          </li>
        ) : null}
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Employees Management",
          "Can Read"
        ) ? (
          <li
            className={`sideNavMenuItem ${
              activeItem === "Employees Management" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/dashboard/employeesManagement");
              setActiveItem("Employees Management");
            }}
          >
            {t("absencesOwnHr.sideNav.employeesManagement")}
          </li>
        ) : null}
        {/* {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Reports",
          "Can Read"
        ) ? (
          <li
            className={`sideNavMenuItem ${
              activeItem === "Reports" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/dashboard/reports");
              setActiveItem("Reports");
            }}
          >
            {t("absencesOwnHr.sideNav.reports")}
          </li>
        ) : null} */}
      </ul>
    </div>
  );
};

export default SideNav;
