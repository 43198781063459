import { create } from "zustand";
import { User } from "../types";

interface IInitialSetupUsersStore {
  users: User[];
  setUserRole: (roleId: string, userId: number) => void;
  addUsers: (users: User[]) => void;
}

export const useInitialSetupUsersStore = create<IInitialSetupUsersStore>(
  (set) => ({
    users: [],
    setUserRole: (roleId, userId) =>
      set((state) => ({
        users: state.users.map((user) =>
          user.Id === userId ? { ...user, RoleId: roleId } : user
        ),
      })),
    addUsers: (users) => set({ users }),
  })
);
