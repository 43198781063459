import { useState } from "react";
import { Divider, Button, Spinner } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./MicrosoftGraphApi.css";
import { useStore } from "zustand";
import { useUserStore } from "../../../store/userStore";
import Error from "../../Error";
import helpers from "../../../helpers/helpers";
import settingsService from "../../../services/settings";
import { toast } from "react-toastify";

export default function MicrosoftGraphApiSettings() {
  const { t } = useTranslation();
  const [syncing, setSyncing] = useState(false);
  const userStore = useStore(useUserStore);

  const handleSyncNow = async () => {
    setSyncing(true);
    try {
      const result = await settingsService.syncUsersWithAad();
      if (
        "status" in result &&
        result?.status === "error" &&
        "message" in result
      ) {
        toast.error(t(`settings.manageUsers.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else if ("users" in result) {
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("settings.manageUsers.errors.sync"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSyncing(false);
    }
  };

  return (
    <>
      {helpers.checkHasPermissions(
        userStore?.user?.Permissions,
        "Settings Integrations",
        "Can Read"
      ) ? (
        <div className="microsoftSettingsPage">
          <div className="microsoftSettingsInfoContainer">
            <div className="title">{t("settings.microsoft.title")}</div>
            <div className="detailsContainer">
              <span className="details">
                {t("settings.microsoft.description")}
              </span>
            </div>
          </div>
          <Divider appearance="strong" />
          <div className="microsoftSettingsInfoContainer">
            <div className="subtitle">{t("settings.microsoft.accessData")}</div>
            <div className="subdescription">
              {t(
                "settings.microsoft.hereSAnExampleOfHowASectionCanBeUsedToGroupInputs"
              )}
            </div>
            <Button
              type="button"
              onClick={handleSyncNow}
              className="settingsSyncNowBtn"
              appearance="primary"
              disabled={
                !helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Settings Integrations",
                  "Can Edit"
                ) || syncing
              }
            >
              {syncing ? (
                <span>
                  <Spinner size="extra-tiny" />
                </span>
              ) : null}
              {t("settings.microsoft.syncNow")}
            </Button>
          </div>
        </div>
      ) : (
        <Error message={t("settings.general.notAuthorized")} />
      )}
    </>
  );
}
