import "./TermsAndConditions.css";

export default function TermsAndConditions() {
  return (
    <div className="tacPage">
      <h1 className="tacTitle">Terms and Conditions Targo360</h1>
      <div className="tacSection">
        <h2 className="tacSectionHeader">1. Description of Product</h2>
        <p>
          Targo360 is a cloud-based software solution that efficiencies attendance
          and absence management and integrates with Azure to automatically
          synchronize the users in your organization. Users can easily select
          dates, record precise hours, deduct breaks, and receive alerts for any
          overlapping entries.
        </p>
        <p>
          Targo360 simplifies absence management by enabling users to submit
          time-off requests for vacations, sickness, or personal time. With
          predefined absence types, users can specify start and end dates,
          avoiding scheduling conflicts.
        </p>
        <p>
          This streamlined approach enhances productivity, saves time, and
          offers valuable insights. Targo360 empowers organizations to efficiently
          manage their workforce by automating tasks and ensuring compliance.
        </p>
        <p>
          In summary, Targo360 is an advanced cloud-based solution offering
          user-friendly features for efficient attendance and absence
          management, including employee management.
        </p>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">2. Use of the Product:</h2>
        <ol type="a">
          <li>
            You may only use the Service in accordance with these Terms and
            Conditions.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of your
            account and password, and for restricting access to your computer or
            device to prevent unauthorized access to your account.
          </li>
          <li>
            You must promptly notify us of any unauthorized use of your account
            or any other breach of security.
          </li>
        </ol>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">3. Payment and Fees</h2>
        <ol type="a">
          <li>
            You agree to pay all fees and charges incurred in connection with
            your use of the Service.
          </li>
          <li>All fees are non-refundable.</li>
          <li>
            We reserve the right to modify our fees and charges from time to
            time, with notice to you.
          </li>
        </ol>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">4. Customer Obligations</h2>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">Payment of Fees:</span> The
            Customer is obligated to pay all fees associated with the use of
            Targo360 in a timely manner. These fees may include subscription fees,
            usage fees, and any other charges that may be incurred by the
            Customer while using the Product. The Provider will provide the
            Customer with a clear and detailed description of all applicable
            fees prior to the commencement of the Customer's use of the Product.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Compliance with Laws and Regulations:
            </span>{" "}
            The Customer must comply with all applicable laws and regulations
            while using Targo360. This includes but is not limited to laws and
            regulations related to data protection, privacy, and intellectual
            property. The Customer is solely responsible for ensuring that their
            use of the Product does not violate any applicable laws or
            regulations.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Proper Use of the Product:
            </span>{" "}
            The Customer is responsible for using Targo360 in accordance with the
            Provider's instructions and documentation. The Customer agrees not
            to use the Product for any illegal or unauthorized purpose,
            including but not limited to the transmission of any unlawful,
            harmful, or offensive material. The Customer also agrees not to use
            the Product in a way that may damage, disable, or impair the
            Provider's servers or networks.
          </li>
          <li>
            <span className="tacSectionSubheader">Reporting of Issues:</span>{" "}
            The Customer is obligated to promptly report any issues or problems
            encountered while using Targo360 to the Provider's support team. The
            Customer must provide the Provider with all necessary information to
            assist in the investigation and resolution of any reported issues.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Data Accuracy and Security:
            </span>{" "}
            The Customer is responsible for ensuring the accuracy and security
            of all data and information inputted into Targo360. The Customer must
            ensure that all data and information is accurate, complete, and
            up-to-date. The Customer must also take all necessary precautions to
            protect their account information and prevent unauthorized access to
            Targo360.
          </li>
        </ol>
        <p>
          Failure to comply with any of these Customer Obligations may result in
          the termination of the Customer's access to Targo360, and the Provider
          may pursue any other remedies available under applicable law.
        </p>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">5. Limitation of Liability</h2>
        <p>
          The Customer agrees that the maximum liability of the product provider
          for any claim arising out of or relating to this Agreement, or the
          Product is limited to the amount paid by the Customer for the Product
          in the twelve (12) months preceding the claim.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">
              Exclusion of Certain Damages:
            </span>{" "}
            The Provider's liability to the Customer for any damages arising out
            of or related to the use of Targo360 shall be limited to direct damages
            only. The Provider shall not be liable for any indirect, special,
            incidental, punitive, or consequential damages, including but not
            limited to lost profits or lost data.
          </li>
          <li>
            <span className="tacSectionSubheader">Cap on Liability:</span> The
            Provider's total liability to the Customer for any direct damages
            arising out of or related to the use of Targo360 shall not exceed the
            amount paid by the Customer to the Provider for the Product during
            the six (6) month period immediately preceding the date on which the
            claim arose.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Exclusion of Liability for Third-Party Services:
            </span>{" "}
            The Provider shall not be liable for any damages arising out of or
            related to the use of any third-party services that are integrated
            with or utilized in conjunction with Targo360. This includes but is not
            limited to third-party software, hardware, or network services.
          </li>
          <li>
            <span className="tacSectionSubheader">Force Majeure:</span> The
            Provider shall not be liable for any failure or delay in the
            performance of its obligations under these Terms and Conditions due
            to circumstances beyond its reasonable control, including but not
            limited to acts of God, war, terrorism, fire, flood, or other
            natural disasters.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Customer's Responsibility:
            </span>{" "}
            The Customer acknowledges and agrees that they are solely
            responsible for the use of Targo360 and any consequences that may arise
            from such use. The Customer agrees to indemnify and hold harmless
            the Provider from any and all claims, damages, liabilities, costs,
            and expenses (including reasonable attorneys' fees) arising out of
            or related to the Customer's use of the Product.
          </li>
        </ol>
        <p>
          These Limitation of Liability provisions are intended to limit the
          Provider's liability to the extent permitted by applicable law and are
          a fundamental part of these Terms and Conditions. By using Targo360, the
          Customer agrees to be bound by these provisions and acknowledges that
          they have read and understood them.
        </p>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">6. Intellectual Property.</h2>
        <p>
          The Provider retains all intellectual property rights to the Product,
          including any updates, enhancements, or modifications to the Product.
          The Customer agrees not to use or reproduce any of the Provider's
          intellectual property without prior written consent.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">Ownership:</span> The Provider
            retains all right, title, and interest in and to Targo360, including
            all intellectual property rights, whether registered or
            unregistered, and any enhancements or modifications made to the
            Product during the term of these Terms and Conditions. Nothing in
            these Terms and Conditions shall be construed as transferring any
            ownership rights to the Customer.
          </li>
          <li>
            <span className="tacSectionSubheader">Use of Product:</span> The
            Customer is granted a limited, non-exclusive, non-transferable, and
            revocable license to use Targo360 during the term of these Terms and
            Conditions solely for the Customer's internal business purposes. The
            Customer agrees not to use the Product for any other purpose or to
            allow any third-party to use the Product.
          </li>
          <li>
            <span className="tacSectionSubheader">Customer Content:</span> The
            Customer retains all right, title, and interest in and to any
            content or data provided by the Customer in connection with the use
            of Targo360. The Customer grants the Provider a limited, non-exclusive,
            royalty-free license to use such content or data solely for the
            purpose of providing Targo360 to the Customer.
          </li>
          <li>
            <span className="tacSectionSubheader">Restrictions:</span> The
            Customer agrees not to reproduce, modify, distribute, display, or
            create derivative works of Targo360, in whole or in part, without the
            Provider's prior written consent. The Customer also agrees not to
            reverse engineer, decompile, or disassemble Targo360 or any part
            thereof, except to the extent that such activity is expressly
            permitted by applicable law.
          </li>
          <li>
            <span className="tacSectionSubheader">Trademarks:</span> The
            Provider owns all rights in and to its trademarks and service marks,
            including but not limited to Targo360 name and logo. The Customer
            agrees not to use the Provider's trademarks or service marks without
            the Provider's prior written consent.
          </li>
          <li>
            <span className="tacSectionSubheader">Infringement Claims:</span>{" "}
            The Provider shall defend the Customer against any third-party claim
            that Targo360 infringes any intellectual property rights of such
            third-party, and shall indemnify the Customer against any damages
            and costs awarded against the Customer as a result of such claim.
            However, the Provider shall have no liability for any claim of
            infringement to the extent it arises out of or is related to the
            Customer's use of Targo360 in violation of these Terms and Conditions.
          </li>
        </ol>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">7. Privacy and Data Protection.</h2>
        <p>
          The Provider will collect and process the Customer's personal data in
          accordance with applicable laws and regulations. The Provider will
          only use the Customer's personal data for the purpose of providing the
          Product to the Customer and will not disclose the Customer's personal
          data to any third party except as required by law.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">
              Collection and Use of Personal Data:
            </span>{" "}
            The Provider may collect and use certain personal data in connection
            with the provision of Targo360 to the Customer. The Provider shall
            comply with all applicable data protection laws and regulations and
            shall only collect and use personal data to the extent necessary for
            the provision of Targo360.
          </li>
          <li>
            <span className="tacSectionSubheader">Security Measures:</span> The
            Provider shall implement appropriate technical and organizational
            measures to protect personal data against unauthorized or unlawful
            processing and against accidental loss, destruction, or damage. The
            Provider shall also ensure that any third-party service providers
            engaged in the processing of personal data on behalf of the Provider
            comply with these same security measures.
          </li>
          <li>
            <span className="tacSectionSubheader">Data Retention:</span> The
            Provider shall retain personal data only for as long as necessary to
            provide Targo360 to the Customer or as required by applicable law. The
            Provider shall delete or anonymize personal data once it is no
            longer needed for the purposes for which it was collected.
          </li>
          <li>
            <span className="tacSectionSubheader">Customer Obligations:</span>{" "}
            The Customer shall comply with all applicable data protection laws
            and regulations and shall only provide personal data to the Provider
            to the extent necessary for the provision of Targo360. The Customer
            shall also ensure that any personal data provided to the Provider is
            accurate and up to date.
          </li>
          <li>
            <span className="tacSectionSubheader">Transfer of Data:</span> The
            Provider may transfer personal data to third-party service providers
            located outside of the Customer's jurisdiction, provided that such
            transfers are made in compliance with applicable data protection
            laws and regulations.
          </li>
          <li>
            <span className="tacSectionSubheader">Confidentiality:</span> The
            Provider shall keep confidential all personal data and other
            confidential information provided by the Customer in connection with
            the use of Targo360 and shall not disclose such information to any
            third-party without the Customer's prior written consent.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Data Breach Notification:
            </span>{" "}
            In the event of a data breach involving personal data, the Provider
            shall notify the Customer without undue delay and shall cooperate
            with the Customer in any investigation or remediation efforts
            related to such breach.
          </li>
        </ol>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">8. Termination and Cancellation.</h2>
        <p>
          Either party may terminate this Agreement upon written notice if the
          other party breaches any material provision of this Agreement and
          fails to remedy such breach within thirty (30) days of receiving
          written notice of the breach. The Customer may cancel their
          subscription to the Product at any time by providing written notice to
          the Provider.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">
              Termination by Provider:
            </span>{" "}
            The Provider may terminate these Terms and Conditions immediately
            upon notice to the Customer if the Customer breaches any material
            term or condition of these Terms and Conditions and fails to remedy
            such breach within thirty (30) days after receiving written notice
            of such breach from the Provider.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Termination by Customer:
            </span>{" "}
            The Customer may terminate these Terms and Conditions upon notice to
            the Provider if the Provider breaches any material term or condition
            of these Terms and Conditions and fails to remedy such breach within
            thirty (30) days after receiving written notice of such breach from
            the Customer.
          </li>
          <li>
            <span className="tacSectionSubheader">Effect of Termination:</span>
            Upon termination of these Terms and Conditions, the Customer shall
            immediately cease all use of Targo360 and shall return or destroy all
            copies of Targo360 in its possession or control. The Customer shall
            also promptly pay any outstanding fees or charges owed to the
            Provider.
          </li>
          <li>
            <span className="tacSectionSubheader">Cancellation:</span> The
            Customer may cancel their subscription to Targo360 at any time by
            providing notice to the Provider. The cancellation will be effective
            at the end of the then-current subscription period.
          </li>
          <li>
            <span className="tacSectionSubheader">Refunds:</span> The Provider
            may offer refunds in certain circumstances, such as if Targo360 is not
            functioning properly or if the Customer is dissatisfied with the
            Product. The Provider may also offer refunds for cancellations made
            within a certain timeframe after the initial purchase. Any refund
            offered by the Provider shall be at its sole discretion.
          </li>
          <li>
            <span className="tacSectionSubheader">Survival:</span> The following
            provisions shall survive termination of these Terms and Conditions:
            Limitation of Liability, Intellectual Property, Privacy and Data
            Protection, and Confidentiality.
          </li>
        </ol>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">9. Governing Law and Jurisdiction.</h2>
        <p>
          This agreement shall be governed by and construed in accordance with
          the laws of the country where the European company is located. Any
          disputes arising out of or in connection with this agreement shall be
          exclusively submitted to the competent courts of the country where the
          European company is located, unless otherwise required by applicable
          law.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">Governing Law:</span> These
            Terms and Conditions and any disputes arising out of or related to
            these Terms and Conditions shall be governed by and construed in
            accordance with the laws of the jurisdiction where the Provider is
            located.
          </li>
          <li>
            <span className="tacSectionSubheader">Jurisdiction:</span> Any
            disputes arising out of or related to these Terms and Conditions
            shall be resolved exclusively by the courts of the jurisdiction
            where the Provider is located. The Customer consents to the
            jurisdiction and venue of such courts and waives any objection based
            on inconvenient forum or lack of personal jurisdiction.
          </li>
          <li>
            <span className="tacSectionSubheader">Waiver of Class Action:</span>{" "}
            The Customer waives any right to participate in a class action
            lawsuit or class-wide arbitration against the Provider. Any dispute
            resolution proceedings will be conducted only on an individual basis
            and not in a class or representative action.
          </li>
          <li>
            <span className="tacSectionSubheader">Severability:</span> If any
            provision of these Terms and Conditions is found to be invalid or
            unenforceable, the remaining provisions shall remain in full force
            and effect.
          </li>
          <li>
            <span className="tacSectionSubheader">Entire Agreement:</span> These
            Terms and Conditions constitute the entire agreement between the
            Provider and the Customer and supersede all prior agreements,
            understandings, or communications, whether written or oral, between
            the parties with respect to the subject matter hereof.
          </li>
        </ol>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">10. Modifications to Agreement.</h2>
        <p>
          The Provider may modify this Agreement from time to time upon written
          notice to the Customer. If the Customer does not agree to the
          modifications, they may terminate this Agreement upon written notice
          to the Provider.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">
              Provider's Right to Modify:
            </span>{" "}
            The Provider reserves the right to modify these Terms and Conditions
            at any time in its sole discretion. Any modifications will be
            effective immediately upon posting of the modified Terms and
            Conditions on the Provider's website or other platform where Targo360
            is made available.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Notice of Modifications:
            </span>{" "}
            The Provider will notify the Customer of any material modifications
            to these Terms and Conditions by email or other reasonable means at
            least 30 days prior to the effective date of such modifications.
          </li>
          <li>
            <span className="tacSectionSubheader">
              Customer's Right to Object:
            </span>{" "}
            If the Customer objects to any material modification to these Terms
            and Conditions, the Customer may terminate these Terms and
            Conditions by providing notice to the Provider prior to the
            effective date of such modifications.
          </li>
          <li>
            <span className="tacSectionSubheader">Continued Use:</span> If the
            Customer continues to use Targo360 after any modifications to these
            Terms and Conditions become effective, the Customer is deemed to
            have accepted such modifications.
          </li>
          <li>
            <span className="tacSectionSubheader">No Other Modifications:</span>{" "}
            No modifications to these Terms and Conditions will be effective
            unless made in writing and signed by both the Provider and the
            Customer.
          </li>
        </ol>
        <p>
          These Modifications to Agreement provisions are intended to provide a
          mechanism for the Provider to modify these Terms and Conditions as
          needed, while also providing the Customer with notice and an
          opportunity to object to material modifications.
        </p>
      </div>
      <div className="tacSection">
        <h2 className="tacSectionHeader">11. Disclaimers.</h2>
        <p>
          The Provider does not warrant that the Product will be error-free or
          uninterrupted, and the Provider disclaims all warranties, express or
          implied, including any warranties of merchantability, fitness for a
          particular purpose, or non-infringement.
        </p>
        <ol type="a">
          <li>
            <span className="tacSectionSubheader">No Warranty:</span> Targo360 is
            provided "as is" without warranty of any kind, either express or
            implied, including without limitation any warranty of
            merchantability, fitness for a particular purpose, or
            non-infringement. The Provider does not warrant that Targo360 will meet
            the Customer's requirements or that the operation of Targo360 will be
            uninterrupted or error-free.
          </li>
          <li>
            <span className="tacSectionSubheader">Use at Own Risk:</span> The
            Customer assumes all risk and responsibility for the use of Targo360.
            The Provider shall not be liable for any damages resulting from the
            use or inability to use Targo360, including but not limited to direct,
            indirect, incidental, punitive, and consequential damages.
          </li>
          <li>
            <span className="tacSectionSubheader">No Endorsement:</span> The
            Provider does not endorse, nor is it responsible for, any content,
            advertising, products, or other materials on or available from
            third-party sites or resources that may be accessed through links in
            Targo360.
          </li>
          <li>
            <span className="tacSectionSubheader">
              No Guarantee of Results:
            </span>{" "}
            The Provider does not guarantee any specific results from the use of
            Targo360. The success of the Customer's business depends on many
            factors, including but not limited to the Customer's own efforts and
            market conditions.
          </li>
          <li>
            <span className="tacSectionSubheader">No Professional Advice:</span>{" "}
            Targo360 is not intended to provide and does not constitute
            professional advice of any kind. The Customer should consult with a
            professional advisor for advice regarding their specific situation.
          </li>
        </ol>
        <p className="tacSectionSubheader">
          By using the Product, the Customer agrees to be bound by this
          Agreement. If the Customer does not agree to this Agreement, they
          should not use the Product.
        </p>
        <p>
          By using the Product, the Customer agrees to be bound by this
          Agreement. This means that the Customer agrees to all of the terms and
          conditions outlined in this Agreement, including any future
          modifications that may be made to the Agreement.
        </p>
        <p>
          If the Customer does not agree to this Agreement, they should not use
          the Product. This provision is intended to make it clear that the use
          of the Product is contingent upon the Customer's agreement to the
          terms and conditions outlined in this Agreement. If the Customer does
          not agree to these terms and conditions, they should not use the
          Product.
        </p>
        <p>
          By agreeing to this provision, the Customer acknowledges that they
          have read and understood the terms and conditions of this Agreement,
          and that they agree to be bound by them. This provision is intended to
          protect the Provider by ensuring that the Customer is aware of and
          agrees to the terms and conditions of the Agreement before using the
          Product.
        </p>
      </div>
    </div>
  );
}
