import httpService from "./http";
import {
  HrSystemObj,
  HrSystem,
  User,
  AdminUser,
  InitialUserScreen,
  InitialRole,
  UserRole,
  Permission,
} from "../types";

const checkOrganization = async () => {
  const response = await httpService.put("/organization/check");
  return response?.data;
};

const getHrSystems = async (): Promise<HrSystemObj> => {
  const response = await httpService.get("/organization/hr-systems");
  let hrSystems: HrSystem[] = [];
  response.data.hrSystems.forEach((system: any) => {
    hrSystems.push({ Id: system?.HrSystemId, Name: system?.HrSystemName });
  });
  return {
    hrSystems: hrSystems,
    existingHrSystemId: response.data.existingHrSystemId,
  };
};

const setHrSystem = async (id: number) => {
  const response = await httpService.put("/organization/hr-system", { id });
  return response.data;
};

const getCurrentUser = async () => {
  const response = await httpService.get("/organization/user");
  const roles: UserRole[] = [];
  const permissions: Permission[] = [];
  response?.data?.user?.Roles?.forEach((role: any) => {
    roles.push({ Type: "builtIn", Id: role?.RoleId, RoleName: role?.RoleName });
    role?.Permissions?.forEach((permission: any) => {
      if (!permissions.some((el) => el?.Id === permission?.PermissionId)) {
        permissions.push({
          Id: permission?.PermissionId,
          Category: permission?.PermissionCategory,
          Name: permission?.PermissionName,
          Description: permission?.PermissionDescription,
        });
      }
    });
  });
  response?.data?.user?.CustomRoles?.forEach((role: any) => {
    roles.push({
      Type: "custom",
      Id: role?.CustomRoleId,
      RoleName: role?.CustomRoleName,
    });
    role?.Permissions?.forEach((permission: any) => {
      if (!permissions.some((el) => el?.Id === permission?.PermissionId)) {
        permissions.push({
          Id: permission?.PermissionId,
          Category: permission?.PermissionCategory,
          Name: permission?.PermissionName,
          Description: permission?.PermissionDescription,
        });
      }
    });
  });
  const user: User = {
    Id: response?.data?.user?.UserId,
    EmployeeId: response?.data?.user?.Employee?.EmployeeId,
    FirstName: response?.data?.user?.FirstName,
    LastName: response?.data?.user?.LastName,
    Email: response?.data?.user?.Email,
    Role: roles,
    Permissions: permissions,
  };
  return user;
};

const getInitialAdmins = async (): Promise<AdminUser[]> => {
  const response = await httpService.get("/organization/admins");
  const admins: AdminUser[] = [];
  response.data.forEach((userData: any) => {
    admins.push({
      Id: userData?.UserId,
      Name: `${userData?.FirstName} ${userData?.LastName}`,
      DepartmentName: userData?.OwnDepartment
        ? userData?.OwnDepartment?.DepartmentName
        : null,
      JobTitle: userData?.JobTitle,
      EmploymentType: userData?.EmploymentType,
      RoleName:
        userData?.Roles.length > 0 ? userData?.Roles[0]?.RoleName : "No Role",
    });
  });
  return admins;
};

const setInitialAdmins = async (admins: number[]) => {
  const response = await httpService.patch("/organization/admins", { admins });
  return response?.data;
};

const getInitialUsers = async (): Promise<InitialUserScreen> => {
  const response = await httpService.get("/organization/users");
  const users: User[] = [];
  const roles: InitialRole[] = [];
  response.data.users.forEach((userData: any) => {
    users.push({
      Id: userData?.UserId,
      FullName: `${userData?.FirstName ?? ""} ${userData?.LastName ?? ""}`,
      Department: userData?.OwnDepartment
        ? {
            Id: userData?.OwnDepartment?.DepartmentId,
            Name: userData?.OwnDepartment?.DepartmentName,
          }
        : null,
      JobTitle: userData?.JobTitle,
      EmploymentType: userData?.EmploymentType,
      Role:
        userData?.Roles.length > 0
          ? [
              {
                Type: "builtIn",
                Id: userData?.Roles[0]?.RoleId,
                RoleName: userData?.Roles[0]?.RoleName,
              },
            ]
          : undefined,
    });
  });
  response?.data?.roles?.forEach((roleData: any) => {
    roles.push({
      Id: roleData?.RoleId,
      Name: roleData?.RoleName,
    });
  });
  return { users, roles };
};

const setRoleForUser = async (userId: number, roleId: number) => {
  const response = await httpService.patch("/organization/user/role", {
    userId,
    roleId,
  });
  return response;
};

const activateOrganization = async () => {
  const response = await httpService.patch("/organization/activate");
  return response;
};

const setOtherHrSystem = async (comment: string) => {
  const response = await httpService.put("/organization/hr-system/other", {
    comment,
  });
  return response;
};

const checkPersonioCredentials = async (
  clientId: string,
  clientSecret: string
) => {
  const response = await httpService.post("/organization/personio/check", {
    clientId,
    clientSecret,
  });
  return response.data;
};

const organizationService = {
  checkOrganization,
  getHrSystems,
  setHrSystem,
  getInitialAdmins,
  setInitialAdmins,
  getInitialUsers,
  setRoleForUser,
  activateOrganization,
  setOtherHrSystem,
  getCurrentUser,
  checkPersonioCredentials,
};
export default organizationService;
