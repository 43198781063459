import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  TableHeaderCell,
} from "@fluentui/react-components";
import "./AnualOrTakenLeave.css";
import { useTimeOffsStore } from "../../../store/timeOffsStore";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const AnualLeave = () => {
  const { t } = useTranslation();
  const timeOffStore = useStore(useTimeOffsStore);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  return (
    <div className="anualOrTakenLeaveContainer">
      <h1 className="anualOrTakenLeaveTitle">
        {t("absencesOwnHr.annualLeave.annualLeave")}
      </h1>
      {screenWidth <= 768 ? (
        <div className="statisticsContainer">
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.annualLeave.totalDays")}
            </div>
            <div className="statisticsValue">
              {timeOffStore?.userBalance?.AnnualDays}
            </div>
          </div>
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.annualLeave.takenDays")}
            </div>
            <div className="statisticsValue">
              {timeOffStore?.userBalance?.AnnualBalance}
            </div>
          </div>
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.annualLeave.remainingDays")}
            </div>
            <div className="statisticsValue">
              {(timeOffStore?.userBalance?.AnnualDays ?? 0) -
                (timeOffStore?.userBalance?.AnnualBalance ?? 0)}
            </div>
          </div>
        </div>
      ) : (
        <div className="statisticsContainer">
          <Table aria-label="Anual Leave table">
            <TableHeader className="statisticsHeader">
              <TableRow className="statisticsRow">
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.annualLeave.totalDays")}
                </TableHeaderCell>
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.annualLeave.takenDays")}
                </TableHeaderCell>
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.annualLeave.remainingDays")}
                </TableHeaderCell>
                <TableHeaderCell className="statisticsItem"></TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className="statisticsValuesContainer">
                <TableCell className="statisticsValue">
                  {timeOffStore?.userBalance?.AnnualDays}
                </TableCell>
                <TableCell className="statisticsValue">
                  {timeOffStore?.userBalance?.AnnualBalance}
                </TableCell>
                <TableCell className="statisticsValue">
                  {(timeOffStore?.userBalance?.AnnualDays ?? 0) -
                    (timeOffStore?.userBalance?.AnnualBalance ?? 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AnualLeave;
