import { Dropdown, Image, Option } from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import "./RoleSelector.css";
import OrganizationService from "../../services/organization";
import { InitialRole, User } from "../../types";
import { useInitialSetupUsersStore } from "../../store/initialSetupUsersStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";

const setRoleForUser = async (userId: number, roleId: number) =>
  await OrganizationService.setRoleForUser(userId, roleId);
export const RoleSelector = (props: { user: User; roles: InitialRole[] }) => {
  const { t } = useTranslation();
  const options = props?.roles;
  const initialUsersStore = useStore(useInitialSetupUsersStore);
  const updateRole = async (roleId: any) => {
    try {
      await setRoleForUser(props?.user?.Id, roleId || 4);
      initialUsersStore.setUserRole(roleId || 4, props?.user?.Id);
    } catch (e) {
      toast.error(t("initialSetup.steps.step3.error"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    }
  };

  return (
    <div>
      <Dropdown
        onOptionSelect={async (event, data) => {
          if ("optionValue" in data) {
            await updateRole(data.optionValue);
          }
        }}
        style={{ minWidth: "auto" }}
        className="selectRoleDropdown"
        placeholder={"No role"}
        defaultValue={
          props?.roles.find(
            (el) =>
              props?.user?.Role &&
              props?.user?.Role[0]?.Type === "builtIn" &&
              el.Id === props?.user?.Role[0]?.Id
          )?.Name
        }
        defaultSelectedOptions={[
          props?.user?.Role && props?.user?.Role[0]?.Type === "builtIn"
            ? props?.user?.Role[0]?.Id?.toString()
            : "",
        ]}
        expandIcon={
          <div className="dropdownIcons">
            <span>
              <Image
                className="line"
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/verticalRoleLine.svg`}
              />
            </span>
            <span>
              <Image
                className="arrow"
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/arrowDown.svg`}
              />
            </span>
          </div>
        }
      >
        {options.map((option) => (
          <Option key={option?.Id} value={option?.Id.toString()}>
            {option?.Name}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};
