import "./MenuItemNavigation.css";
import {
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Image,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStore } from "zustand";
import { useUserStore } from "../../store/userStore";
import helpers from "../../helpers/helpers";

export const MenuItemNavigation = () => {
  const { t } = useTranslation();
  const userStore = useStore(useUserStore);

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Image
          className="menuItemIcon"
          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/menu.svg`}
          alt="menu"
        />
      </MenuTrigger>

      <MenuPopover className="menuPopover">
        <MenuList>
          <MenuItem>
            <span className="menuItemNavigationName">
              <Link to="/" className="menuItemNavigationLink">
                {t("absencesOwnHr.navbar.menuItemNavigation.home")}
              </Link>
            </span>
          </MenuItem>
          <MenuItem>
            <span className="menuItemNavigationName">
              <Link
                to="/dashboard/attendanceOwnHr"
                className="menuItemNavigationLink"
              >
                {t(
                  "absencesOwnHr.navbar.menuItemNavigation.workforceManagement"
                )}
              </Link>
            </span>
          </MenuItem>
          <Image
            src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalLine.svg`}
            alt="horizontalLine"
          />
          {helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Settings General",
            "Can Read"
          ) ||
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Settings Integrations",
            "Can Read"
          ) ||
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Roles and Permissions",
            "Can Read"
          ) ||
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Manage Users",
            "Can Read"
          ) ||
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Organigram",
            "Can Read"
          ) ? (
            <MenuItem>
              <span className="menuItemNavigationName">
                <Link to="/menu/settings" className="menuItemNavigationLink">
                  {t("absencesOwnHr.navbar.menuItemNavigation.settings")}
                </Link>
              </span>
            </MenuItem>
          ) : null}
          <MenuItem>
            <span className="menuItemNavigationName">
              <Link to="/menu/contact-us" className="menuItemNavigationLink">
                {t("absencesOwnHr.navbar.menuItemNavigation.contactUs")}
              </Link>
            </span>
          </MenuItem>
          <MenuItem>
            <span className="menuItemNavigationName">
              <Link to="/menu/about" className="menuItemNavigationLink">
                {t("absencesOwnHr.navbar.menuItemNavigation.about")}
              </Link>
            </span>
          </MenuItem>
          <MenuItem>
            <span className="menuItemNavigationName">
              <Link to="/menu/privacy" className="menuItemNavigationLink">
                {t("absencesOwnHr.navbar.menuItemNavigation.privacyPolicy")}
              </Link>
            </span>
          </MenuItem>
          <MenuItem>
            <span className="menuItemNavigationName">
              <Link to="/menu/termsofuse" className="menuItemNavigationLink">
                {t(
                  "absencesOwnHr.navbar.menuItemNavigation.termsAndConditions"
                )}
              </Link>
            </span>
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
