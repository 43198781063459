import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import "./Organigram.css";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import Error from "../Error";
import settingsService from "../../services/settings";
import { User } from "../../types";
import { Avatar } from "@fluentui/react-components";
import { useStore } from "zustand";
import { useUserStore } from "../../store/userStore";
import helpers from "../../helpers/helpers";
import { useSearchField } from "../../contexts/SearchContext";

export default function OrganigramSettings() {
  const { t } = useTranslation();
  const userStore = useStore(useUserStore);
  const searchField = useSearchField();
  const [organigramData, setOrganigramData] = useState<User[][] | undefined>();

  const getOrganigram = async () => {
    const result = await settingsService.getOrganigram();
    let users: User[][] = [];
    result?.users?.forEach((user) => {
      const level = user?.Level;
      if (level) {
        if (!users[level - 1]) {
          users[level - 1] = [];
        }
        users[level - 1].push(user);
      }
    });
    return users;
  };

  const { data, isPending, error, isFetching } = useQuery({
    queryKey: ["organigramData"],
    queryFn: getOrganigram,
  });

  useEffect(() => {
    if (searchField === "") {
      setOrganigramData(data);
    } else {
      let newData: User[][] = [];
      data?.forEach((arr) => {
        newData.push(
          arr.filter(
            (el) =>
              (el?.FullName &&
                el?.FullName.toLowerCase().includes(
                  searchField.toLowerCase()
                )) ||
              (el?.EmploymentType &&
                el?.EmploymentType.toLowerCase().includes(
                  searchField.toLowerCase()
                )) ||
              (el?.JobTitle &&
                el?.JobTitle.toLowerCase().includes(
                  searchField.toLowerCase()
                )) ||
              (el?.Department &&
                el?.Department?.Name?.toLowerCase().includes(
                  searchField.toLowerCase()
                ))
          )
        );
      });
      setOrganigramData(newData);
    }
  }, [searchField, data]);

  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error) return <Error message={t("settings.organigram.errors.general")} />;

  return (
    <>
      {helpers.checkHasPermissions(
        userStore?.user?.Permissions,
        "Organigram",
        "Can Read"
      ) ? (
        <div className="organigramSettingsPage">
          <div className="organigramSettingsInfoContainer">
            <div className="title">{t("settings.organigram.title")}</div>
            <div className="detailsContainer">
              <span className="details">
                {t("settings.organigram.description")}
              </span>
            </div>
          </div>
          <div className="organigramSettingsOrganigramSection">
            {organigramData?.map((usersRow, indexUsersRow) => (
              <div
                key={indexUsersRow}
                className="organigramSettingsOrganigramRow"
              >
                {usersRow.map((user, index) => (
                  <div key={index} className="organigramSettingsUserCard">
                    <div className="organigramSettingsUserCardHeader">
                      <Avatar
                        aria-label={user?.FullName}
                        name={user?.FullName}
                      />
                      <div>
                        <div className="organigramSettingsUserCardName">
                          {user?.FullName}
                        </div>
                        <div className="organigramSettingsUserCardJobTitle">
                          {user?.JobTitle}
                        </div>
                      </div>
                    </div>
                    <div className="organigramSettingsUserCardTags">
                      {user?.Role && user?.Role[0]?.RoleName ? (
                        <div className="organigramSettingsUserCardTag">
                          {user?.Role[0]?.RoleName}
                        </div>
                      ) : null}
                      {user?.Department ? (
                        <div className="organigramSettingsUserCardTag">
                          {user?.Department?.Name}
                        </div>
                      ) : null}
                      {user?.EmploymentType ? (
                        <div className="organigramSettingsUserCardTag">
                          {user?.EmploymentType}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Error message={t("settings.general.notAuthorized")} />
      )}
    </>
  );
}
