import { User } from "../types";
import httpService from "./http";

const getEmployeesManagementData = async () => {
  try {
    const response = await httpService.get("employee-management");
    const managers: User[] = response?.data?.managers?.map((manager: any) => ({
      Id: manager.UserId,
      EmployeeId: manager.Employee?.EmployeeId,
      FirstName: manager.FirstName,
      LastName: manager.LastName,
      Email: manager.Email,
      Role:
        manager.Roles?.length > 0
          ? {
              Id: manager.Roles[0].RoleId,
              RoleName: manager.Roles[0].RoleName,
            }
          : null,
    }));
    return { employees: response.data?.employees, managers: managers };
  } catch (error) {
    console.error("Error fetching employees:", error);
    throw error;
  }
};

const editEmployee = async (
  employeeId: number,
  annualDays: number,
  contractType: string,
  dailyHours: string,
  hireDate: string,
  managerUserId: number,
  employeeName: string,
  position: string,
  status: string,
  terminationDate: string,
  worksite: string
) => {
  const response = await httpService.patch(
    "/employee-management/edit-employee",
    {
      employeeId,
      annualDays,
      contractType,
      dailyHours,
      hireDate,
      managerUserId,
      employeeName,
      position,
      status,
      terminationDate,
      worksite,
    }
  );
  return response.data;
};

const importCsv = async (csvData: any) => {
  const response = await httpService.post("/employee-management/import", {
    csvData,
  });
  return response.data;
};

const employeeManagementService = {
  getEmployeesManagementData,
  editEmployee,
  importCsv,
};
export default employeeManagementService;
