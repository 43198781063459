import { create } from "zustand";

interface IInitialSetupAdminsStore {
  admins: number[];
  toggleSelectedAdmin: (adminId: number) => void;
}

export const useInitialSetupAdminsStore = create<IInitialSetupAdminsStore>(
  (set) => ({
    admins: [],
    toggleSelectedAdmin: (adminId) =>
      set((state) => ({
        admins: state.admins.includes(adminId)
          ? state.admins.filter((id) => id !== adminId)
          : [...state.admins, adminId],
      })),
  })
);
