import { toast } from "react-toastify";
import { Image, Button, Input } from "@fluentui/react-components";
import Papa from "papaparse";
import "./Navbar.css";
import { useState } from "react";
import { MenuItemNavigation } from "../AbsencesOwnHr/MenuItemNavigation";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ImportCsvModal from "../ImportCsvModal";
import timeOffService from "../../services/timeOff";
import { TimeOffType } from "../../types";
import helpers from "../../helpers/helpers";
import { useUserStore } from "../../store/userStore";
import { useStore } from "zustand";
import attendanceService from "../../services/attendance";
import moment from "moment";
import { useAttendancesStore } from "../../store/attendancesStore";
import employeeManagementService from "../../services/employeesManagement";
import { useEmployeeStore } from "../../store/ownEmployeeManagementStore";
import { useTimeOffsStore } from "../../store/timeOffsStore";
import Filters from "../Filters/Filters";
import { useFiltersContext } from "../../contexts/FiltersContext";

interface INavbar {
  searchField: string;
  setSearchField: (searchField: string) => void;
  filtersSections?: any;
}

const Navbar: React.FC<INavbar> = ({ searchField, setSearchField }) => {
  const { setIsSelectingFilters } = useFiltersContext();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [svgUrl, setSvgUrl] = useState<string | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const location = useLocation();
  const userStore = useStore(useUserStore);
  const attendancesStore = useStore(useAttendancesStore);
  const employeeManagementStore = useStore(useEmployeeStore);
  const timeOffsStore = useStore(useTimeOffsStore);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCsvFile(null);
    setErrors([]);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchField(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setCsvFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target?.result;
        setSvgUrl(url as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      setCsvFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target?.result;
        setSvgUrl(url as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleImportButtonClick = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput instanceof HTMLInputElement) {
      fileInput.click();
    }
  };

  const handleImportAbsencesSubmit = async () => {
    if (!csvFile) return;
    setErrors([]);
    setIsImporting(true);
    try {
      await Papa.parse(csvFile, {
        header: true,
        complete: async (results, file) => {
          const csvData: any[] = [];
          try {
            if (results) {
              results?.data?.forEach((resultRow: any) => {
                if (resultRow?.Email !== "") {
                  csvData.push({
                    ApprovalDate:
                      resultRow["Approval Date"] !== ""
                        ? moment(
                            resultRow["Approval Date"],
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")
                        : "",
                    Comments: resultRow["Comments"],
                    Email: resultRow?.Email,
                    EndDate: moment(resultRow["End Date"], "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    ),
                    LeaveType: resultRow["Leave Type"],
                    RequestStatus: resultRow["Request Status"],
                    RequestedDate:
                      resultRow["Requested Date"] !== ""
                        ? moment(
                            resultRow["Requested Date"],
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")
                        : "",
                    StartDate: moment(
                      resultRow["Start Date"],
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD"),
                  });
                }
              });
              const result = await timeOffService.importCsvFile(csvData);
              if ("errors" in result) {
                setErrors(result?.errors);
                setCsvFile(null);
                removeFileFromInput();
              } else if ("timeOffs" in result) {
                timeOffsStore.setTimeOffs(result?.timeOffs);
                closeModal();
              } else {
                closeModal();
              }
            }
          } catch (e) {
            closeModal();
            toast.error(t("absencesOwnHr.navbar.errors.importCsv"), {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            });
          } finally {
            setIsImporting(false);
          }
        },
      });
    } catch (e) {
      setErrors([
        "There was an error sending the csv file. Please try again later",
      ]);
    }
  };

  const handleImportAttendancesSubmit = async () => {
    if (!csvFile) return;
    setErrors([]);
    setIsImporting(true);
    try {
      await Papa.parse(csvFile, {
        header: true,
        complete: async (results, file) => {
          const csvData: any[] = [];
          try {
            if (results) {
              results?.data?.forEach((resultRow: any) => {
                if (resultRow?.Email !== "") {
                  let date = moment(resultRow?.Date, "DD/MM/YYYY");
                  csvData.push({
                    Email: resultRow?.Email,
                    Date: date.format("YYYY-MM-DD"),
                    StartTime: date
                      .clone()
                      .startOf("day")
                      .add(moment.duration(resultRow?.StartTime)),
                    EndTime: date
                      .clone()
                      .startOf("day")
                      .add(moment.duration(resultRow?.EndTime)),
                    Location: resultRow?.Location,
                    Type: resultRow?.Type,
                  });
                }
              });
              const result = await attendanceService.importCsvFile(csvData);
              if ("errors" in result) {
                setErrors(result?.errors);
                setCsvFile(null);
                removeFileFromInput();
              } else if ("attendances" in result) {
                attendancesStore.addAttendances(result?.attendances);
                closeModal();
              } else {
                closeModal();
              }
            }
          } catch (e) {
            closeModal();
            toast.error(t("absencesOwnHr.navbar.errors.importCsv"), {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            });
          } finally {
            setIsImporting(false);
          }
        },
      });
    } catch (e) {
      setErrors([
        "There was an error sending the csv file. Please try again later",
      ]);
    }
  };

  const handleImportEmployeesManagementSubmit = async () => {
    if (!csvFile) return;
    setErrors([]);
    setIsImporting(true);
    try {
      await Papa.parse(csvFile, {
        header: true,
        complete: async (results, file) => {
          const csvData: any[] = [];
          if (results) {
            results?.data?.forEach((resultRow: any) => {
              if (resultRow?.Email !== "") {
                csvData.push(resultRow);
              }
            });
            const result = await employeeManagementService.importCsv(csvData);
            if ("errors" in result) {
              setErrors(result?.errors);
              setCsvFile(null);
              removeFileFromInput();
            } else if ("employees" in result) {
              employeeManagementStore.setEmployees(result?.employees);
              closeModal();
            } else {
              closeModal();
            }
            setIsImporting(false);
          }
        },
      });
    } catch (e) {
      setErrors([
        "There was an error sending the csv file. Please try again later",
      ]);
    }
  };

  const removeFileFromInput = () => {
    document.querySelectorAll(".fileInput").forEach((fileInput) => {
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = "";
      }
    });
  };

  const convertToCSV = (data: any) => {
    return Papa.unparse(data);
  };

  const handleDownloadAbsencesTemplate = async () => {
    const result = await timeOffService.getTimeOffTypes();
    if (result) {
      let csvData = [
        [
          "Email",
          "Start Date",
          "End Date",
          "Leave Type",
          "Request Status",
          "Requested Date",
          "Approval Date",
          "Comments",
        ],
      ];
      const promises = result?.map(async (timeOffType: TimeOffType) => {
        let randomDate = helpers.getRandomDate();
        csvData.push([
          `demo${timeOffType?.Id}@example.com`,
          randomDate.format("DD/MM/YYYY"),
          randomDate.clone().add(1, "days").format("DD/MM/YYYY"),
          timeOffType?.Name,
          helpers.getRandomElement(["Approved", "Pending", "Rejected"]),
        ]);
      });
      await Promise.all(promises);
      const csvDataCoverted = convertToCSV(csvData);
      const blob = new Blob([csvDataCoverted], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "absences-template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadAttendancesTemplate = async () => {
    let csvData = [
      ["Email", "Date", "StartTime", "EndTime", "Location", "Type"],
    ];
    for (let i = 8; i <= 12; i++) {
      let randomDate = helpers.getRandomDate();
      csvData.push([
        `demo${i - 7}@example.com`,
        randomDate.format("DD/MM/YYYY"),
        randomDate.add(i, "hours").format("HH:mm"),
        randomDate.add(1, "hours").format("HH:mm"),
        helpers.getRandomElement(["remote", "office"]),
        helpers.getRandomElement(["break", "work"]),
      ]);
    }
    const csvDataCoverted = convertToCSV(csvData);
    const blob = new Blob([csvDataCoverted], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "attendances-template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadEmployeesManagementTemplate = async () => {
    let csvData = [["Email", "DailyWorkingHours", "AnnualDays"]];
    for (let i = 1; i <= 5; i++) {
      csvData.push([
        `demo${i}@example.com`,
        helpers.getRandomElement(["08:00", "7", "7:30"]),
        Math.floor(Math.random() * 20),
      ]);
    }
    const csvDataCoverted = convertToCSV(csvData);
    const blob = new Blob([csvDataCoverted], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "employees.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openFiltersModal = () => {
    setIsFiltersModalOpen(true);
    setIsSelectingFilters(true);
  };

  return (
    <nav className="absencesNavbar">
      <div className="absencesNavContainer">
        <div className="absencesInfoContainer">
          <div className="absencensInfoWrapper">
            <div className="absencesLogoContainer">
              <Image
                className="absencesLogo"
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/Avatar.svg`}
                alt="logo"
              />
            </div>
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/separationLine.svg`}
              alt="line"
            />
            <MenuItemNavigation />
          </div>
        </div>
        <div className="absencesActionContainer">
          <div className="absencesActionSearchContainer">
            {location?.pathname !== "/dashboard/reports" ? (
              <>
                <div className="filterSection" onClick={openFiltersModal}>
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/filter.svg`}
                    alt="filter"
                  />
                  <span className="absencesFilter">
                    {t("absencesOwnHr.navbar.filter")}
                  </span>
                </div>
                <Input
                  className="absencesSearch"
                  contentAfter={
                    <Image
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/search.svg`}
                      alt="search"
                    />
                  }
                  placeholder={t("absencesOwnHr.navbar.find")}
                  value={searchField}
                  onChange={handleSearchValueChange}
                />
              </>
            ) : null}
          </div>
          {location.pathname === "/dashboard/absencesOwnHr" &&
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Absences",
            "Can Import"
          ) ? (
            <Button
              appearance="primary"
              className="importCsvButton"
              icon={
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/import.svg`}
                  alt="import"
                />
              }
              onClick={openModal}
            >
              <span className="importCsvButtonName">
                {t("absencesOwnHr.navbar.importCsv")}
              </span>
            </Button>
          ) : null}
          {location.pathname === "/dashboard/attendanceOwnHr" &&
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Attendances",
            "Can Import"
          ) ? (
            <Button
              appearance="primary"
              className="importCsvButton"
              icon={
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/import.svg`}
                  alt="import"
                />
              }
              onClick={openModal}
            >
              <span className="importCsvButtonName">
                {t("absencesOwnHr.navbar.importCsv")}
              </span>
            </Button>
          ) : null}
          {location.pathname === "/dashboard/employeesManagement" &&
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Employees Management",
            "Can Import"
          ) ? (
            <Button
              appearance="primary"
              className="importCsvButton"
              icon={
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/import.svg`}
                  alt="import"
                />
              }
              onClick={openModal}
            >
              <span className="importCsvButtonName">
                {t("absencesOwnHr.navbar.importCsv")}
              </span>
            </Button>
          ) : null}
        </div>

        {location.pathname === "/dashboard/absencesOwnHr" ? (
          <ImportCsvModal
            isImporting={isImporting}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            csvFile={csvFile}
            handleSubmit={handleImportAbsencesSubmit}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileChange={handleFileChange}
            handleImportButtonClick={handleImportButtonClick}
            handleDownloadTemplate={handleDownloadAbsencesTemplate}
            svgUrl={svgUrl}
            errors={errors}
            title="Targo360"
            subtitle="Absences"
            contentTitle="CSV"
            headerStyle={""}
          />
        ) : location.pathname === "/dashboard/attendanceOwnHr" ? (
          <ImportCsvModal
            isImporting={isImporting}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            csvFile={csvFile}
            handleSubmit={handleImportAttendancesSubmit}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileChange={handleFileChange}
            handleImportButtonClick={handleImportButtonClick}
            handleDownloadTemplate={handleDownloadAttendancesTemplate}
            svgUrl={svgUrl}
            errors={errors}
            title="Targo360"
            subtitle="Attendance"
            contentTitle="CSV"
            headerStyle={""}
          />
        ) : location.pathname === "/dashboard/employeesManagement" ? (
          <ImportCsvModal
            isImporting={isImporting}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            csvFile={csvFile}
            handleSubmit={handleImportEmployeesManagementSubmit}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileChange={handleFileChange}
            handleImportButtonClick={handleImportButtonClick}
            handleDownloadTemplate={handleDownloadEmployeesManagementTemplate}
            svgUrl={svgUrl}
            errors={errors}
            title="Targo360"
            subtitle="Employee Management"
            contentTitle="CSV"
            headerStyle={""}
          />
        ) : null}
        <Filters
          isOpen={isFiltersModalOpen}
          onClose={() => {
            setIsFiltersModalOpen(false);
            setIsSelectingFilters(false);
          }}
        />
      </div>
    </nav>
  );
};

export default Navbar;
