import { Image, Label, Input, Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./PersonioStep4.css";
import { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import OrganizationService from "../../../../services/organization";
import Support from "../../Support";
import { toast } from "react-toastify";

export function PersonioStep4(props: {
  setIsError: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
  setIsNextDisabled: (value: boolean) => void;
}) {
  const { t } = useTranslation();

  const initialValues = {
    clientId: "",
    clientSecret: "",
  };

  const validationSchema = Yup.object().shape({
    clientId: Yup.string().required(
      t("personio.initialSetup.steps.step4.typeRequired")
    ),
    clientSecret: Yup.string().required(
      t("personio.initialSetup.steps.step4.typeRequired")
    ),
  });

  const handleCheckPersonioCredentials = async (
    values: any,
    { setSubmitting }: any
  ) => {
    try {
      const result = await OrganizationService.checkPersonioCredentials(
        values.clientId,
        values.clientSecret
      );
      if ("status" in result && result?.status === "error") {
        toast.error(t(`personio.initialSetup.steps.step4.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else if ("status" in result && result?.status === "success") {
        toast.success(t("personio.initialSetup.steps.step4.success"), {
          position: "bottom-right",
        });
        props.setIsNextDisabled(false);
      }
    } catch (e) {
      toast.error(t("personio.initialSetup.steps.step4.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="step4PersonioContainer">
      <div className="step4PersonioHeaderContainer">
        <div className="step4HeaderTextSection">
          <div className="step4PersonioTitleSection">
            <h2 className="step4PersonioTitle">
              {t("personio.initialSetup.steps.step4.title")}
            </h2>
            <p className="step4PersonioSubtitle">
              {t("personio.initialSetup.steps.step4.subtitle")}
            </p>
          </div>
          <div className="step4PersonioSectionTitle">
            <h3 className="step4PersonioSection2Title">
              {t("personio.initialSetup.steps.step4.sectionTitle")}
            </h3>
            <p className="step4PersonioSubtitle">
              {t("personio.initialSetup.steps.step4.sectionTitleDescription")}
            </p>
          </div>
        </div>
        <Support />
      </div>
      <div className="step4PersonioSetup">
        <Formik
          initialValues={initialValues}
          onSubmit={handleCheckPersonioCredentials}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => (
            <Form className="step4PersonioSetupForm">
              <div className="step4PersonioSetupInputContainer">
                <Label
                  htmlFor="personio-client-id"
                  className="step4PersonioSetupLabel"
                >
                  {t("personio.initialSetup.steps.step4.clientId")}
                </Label>
                <Input
                  name="clientId"
                  id="personio-client-id"
                  className="step4PersonioSetupInput"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="clientId"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="step4PersonioSetupInputContainer">
                <Label
                  htmlFor="personio-client-secret"
                  className="step4PersonioSetupLabel"
                >
                  {t("personio.initialSetup.steps.step4.cliendSecret")}
                </Label>
                <Input
                  name="clientSecret"
                  id="personio-client-secret"
                  className="step4PersonioSetupInput"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="clientSecret"
                  component="div"
                  className="error-message"
                />
              </div>
              <Button
                appearance="primary"
                type="submit"
                disabled={isSubmitting}
                className="step4PersonioSetupSubmitBtn"
              >
                {t("personio.initialSetup.steps.step4.check")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
