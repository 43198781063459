import moment, { Moment } from "moment";
import { Attendance, AttendanceSummarized, User } from "../types";
import httpService from "./http";

const getInitialStartAttendance = async () => {
  const response = await httpService.get("/attendance/start");
  return response.data;
};

const startAttendance = async (location: string, type: string, date: Date) => {
  const response = await httpService.post("/attendance/start", {
    location,
    type,
    date,
  });
  return response.data;
};

const endAttendance = async (attendanceId: number) => {
  const response = await httpService.post("/attendance/end", {
    attendanceId,
  });
  let duration = formatDuration(response?.data?.attendance?.AttendanceDuration);
  const attendance: Attendance = {
    Id: response?.data?.attendance?.AttendanceId,
    EmployeeId: response?.data?.attendance?.EmployeeId,
    EmployeeName: response?.data?.attendance?.OwnEmployee?.EmployeeName,
    DepartmentName:
      response?.data?.attendance?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.attendance?.SupervisorId,
    SupervisorName:
      response?.data?.attendance?.SupervisorName !== "" &&
      response?.data?.attendance?.SupervisorName !== null
        ? response?.data?.attendance?.SupervisorName
        : "Unassigned",
    Date: moment(response?.data?.attendance?.AttendanceDate),
    Type:
      response?.data?.attendance?.AttendanceType === "break" ? "Break" : "Work",
    Duration: duration,
    Location:
      response?.data?.attendance?.AttendanceLocation === "remote"
        ? "Remote"
        : "Office",
    StartTime: moment(response?.data?.attendance?.AttendanceStartTime),
    EndTime: moment(response?.data?.attendance?.AttendanceEndTime),
  };
  return { attendance };
};

const getAttendancesData = async () => {
  const response = await httpService.get("/attendance");
  const attendances: Attendance[] = [];
  response?.data?.attendances?.forEach((att: any) => {
    let duration = formatDuration(att?.AttendanceDuration);
    attendances.push({
      Id: att?.AttendanceId,
      EmployeeId: att?.EmployeeId,
      EmployeeName: att?.OwnEmployee?.EmployeeName,
      DepartmentName: att?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: att?.SupervisorId,
      SupervisorName:
        att?.SupervisorName !== "" && att?.SupervisorName !== null
          ? att?.SupervisorName
          : "Unassigned",
      Date: moment(att?.AttendanceDate),
      Type: att?.AttendanceType === "break" ? "Break" : "Work",
      Duration: duration,
      Location: att?.AttendanceLocation === "remote" ? "Remote" : "Office",
      StartTime: moment(att?.AttendanceStartTime),
      EndTime: moment(att?.AttendanceEndTime),
    });
  });
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    users.push({
      Id: user?.UserId,
      Email: user?.Email,
      FirstName: user?.FirstName,
      LastName: user?.LastName,
      EmployeeId: user?.Employee?.EmployeeId,
    });
  });
  return { attendances, users };
};

const formatDuration = (duration: number): string => {
  const seconds = duration % 60;
  const minutes = Math.floor((duration % 3600) / 60);
  const hours = Math.floor(duration / 3600);
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
};

const sendTracks = async (tracks: any[]) => {
  const response = await httpService.post("/attendance/tracks", { tracks });
  if ("status" in response.data && response?.data?.status === "error") {
    return response.data;
  }
  const attendances: Attendance[] = [];
  response?.data?.attendances?.forEach((att: any) => {
    let duration = formatDuration(att?.AttendanceDuration);
    attendances.push({
      Id: att?.AttendanceId,
      EmployeeId: att?.EmployeeId,
      EmployeeName: att?.OwnEmployee?.EmployeeName,
      DepartmentName: att?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: att?.SupervisorId,
      SupervisorName:
        att?.SupervisorName !== "" && att?.SupervisorName !== null
          ? att?.SupervisorName
          : "Unassigned",
      Date: moment(att?.AttendanceDate),
      Type: att?.AttendanceType === "break" ? "Break" : "Work",
      Duration: duration,
      Location: att?.AttendanceLocation === "remote" ? "Remote" : "Office",
      StartTime: moment(att?.AttendanceStartTime),
      EndTime: moment(att?.AttendanceEndTime),
    });
  });
  return { attendances };
};

const getSummarizedAttendances = async () => {
  const response = await httpService.get("/attendance/detailed");
  let attendances: AttendanceSummarized[] = [];
  response?.data?.attendances?.forEach((att: any) => {
    attendances.push({
      EmployeeId: att?.EmployeeId,
      EmployeeName: att?.OwnEmployee?.EmployeeName,
      DepartmentName: att?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: att?.SupervisorId,
      SupervisorName:
        att?.SupervisorName !== "" && att?.SupervisorName !== null
          ? att?.SupervisorName
          : "Unassigned",
      Date: moment(att?.AttendanceDate),
      WorkTime: formatDuration(att?.totalWorkTime),
      BreakTime: formatDuration(att?.totalBreakTime),
      Location:
        att?.AttendanceLocation === "remote"
          ? "Remote"
          : att?.AttendanceLocation === "office"
          ? "Office"
          : "",
    });
  });
  return { attendances };
};

const addAttendance = async (
  date: string,
  type: string,
  location: string,
  userId: number,
  startTime: Date,
  endTime: Date
) => {
  const response = await httpService.post("/attendance/add", {
    date,
    type,
    location,
    userId,
    startTime,
    endTime,
  });
  if ("attendance" in response.data) {
    let duration = formatDuration(
      response?.data?.attendance?.AttendanceDuration
    );
    let attendance: Attendance = {
      Id: response?.data?.attendance?.AttendanceId,
      EmployeeId: response?.data?.attendance?.EmployeeId,
      EmployeeName: response?.data?.attendance?.OwnEmployee?.EmployeeName,
      DepartmentName:
        response?.data?.attendance?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: response?.data?.attendance?.SupervisorId,
      SupervisorName:
        response?.data?.attendance?.SupervisorName !== "" &&
        response?.data?.attendance?.SupervisorName !== null
          ? response?.data?.attendance?.SupervisorName
          : "Unassigned",
      Date: moment(response?.data?.attendance?.AttendanceDate),
      Type:
        response?.data?.attendance?.AttendanceType === "break"
          ? "Break"
          : "Work",
      Duration: duration,
      Location:
        response?.data?.attendance?.AttendanceLocation === "remote"
          ? "Remote"
          : "Office",
      StartTime: moment(response?.data?.attendance?.AttendanceStartTime),
      EndTime: moment(response?.data?.attendance?.AttendanceEndTime),
    };
    return { attendance };
  }
  return response.data;
};

const editAttendance = async (
  attendanceId: number,
  date: string,
  type: string,
  location: string,
  userId: string,
  startTime: Date,
  endTime: Date
) => {
  const response = await httpService.patch("/attendance/edit", {
    attendanceId,
    date,
    type,
    location,
    userId,
    startTime,
    endTime,
  });
  if ("attendance" in response.data) {
    let duration = formatDuration(
      response?.data?.attendance?.AttendanceDuration
    );
    let attendance: Attendance = {
      Id: response?.data?.attendance?.AttendanceId,
      EmployeeId: response?.data?.attendance?.EmployeeId,
      EmployeeName: response?.data?.attendance?.OwnEmployee?.EmployeeName,
      DepartmentName:
        response?.data?.attendance?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: response?.data?.attendance?.SupervisorId,
      SupervisorName:
        response?.data?.attendance?.SupervisorName !== "" &&
        response?.data?.attendance?.SupervisorName !== null
          ? response?.data?.attendance?.SupervisorName
          : "Unassigned",
      Date: moment(response?.data?.attendance?.AttendanceDate),
      Type:
        response?.data?.attendance?.AttendanceType === "break"
          ? "Break"
          : "Work",
      Duration: duration,
      Location:
        response?.data?.attendance?.AttendanceLocation === "remote"
          ? "Remote"
          : "Office",
      StartTime: moment(response?.data?.attendance?.AttendanceStartTime),
      EndTime: moment(response?.data?.attendance?.AttendanceEndTime),
    };
    return { attendance };
  }
  return response.data;
};

const importCsvFile = async (csvData: any) => {
  const response = await httpService.post("/attendance/import", { csvData });
  if ("attendances" in response?.data) {
    const attendances: Attendance[] = [];
    response?.data?.attendances?.forEach((att: any) => {
      let duration = formatDuration(att?.AttendanceDuration);
      attendances.push({
        Id: att?.AttendanceId,
        EmployeeId: att?.EmployeeId,
        EmployeeName: att?.OwnEmployee?.EmployeeName,
        DepartmentName: att?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: att?.SupervisorId,
        SupervisorName:
          att?.SupervisorName !== "" && att?.SupervisorName !== null
            ? att?.SupervisorName
            : "Unassigned",
        Date: moment(att?.AttendanceDate),
        Type: att?.AttendanceType === "break" ? "Break" : "Work",
        Duration: duration,
        Location: att?.AttendanceLocation === "remote" ? "Remote" : "Office",
        StartTime: moment(att?.AttendanceStartTime),
        EndTime: moment(att?.AttendanceEndTime),
      });
    });
    return { attendances };
  }
  return response.data;
};

const deleteAttendance = async (attendanceId: number) => {
  const response = await httpService.post("/attendance/delete", {
    attendanceId,
  });
  return response.data;
};

const deleteSumAttendances = async (
  date: string,
  employeeId: number,
  location: string
) => {
  const response = await httpService.post("/attendance/summarized/delete", {
    date,
    employeeId,
    location,
  });
  return response.data;
};

const attendanceService = {
  startAttendance,
  getInitialStartAttendance,
  endAttendance,
  getAttendancesData,
  sendTracks,
  getSummarizedAttendances,
  addAttendance,
  editAttendance,
  importCsvFile,
  deleteAttendance,
  deleteSumAttendances,
};
export default attendanceService;
