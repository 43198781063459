import { useEffect } from "react";

export const AuthSuccess = (): React.ReactElement => {
  useEffect(() => {
    return () => window.close();
  }, []);
  return (
    <div>
      <p>Authentication for channel app successful!</p>
      <p>Please close this window and reload the application.</p>
    </div>
  );
};
