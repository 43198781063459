import Modal from "../../../Modal";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Image,
  useTableSort,
  TableColumnId,
  Tooltip,
} from "@fluentui/react-components";
import "./SummarizedAttendances.css";
import { Attendance, AttendanceSummarized } from "../../../../types";
import { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useAttendancesStore } from "../../../../store/attendancesStore";
import helpers from "../../../../helpers/helpers";
import { useUserStore } from "../../../../store/userStore";

interface ISummarizedAttendances {
  isModalOpen: boolean;
  closeModal: () => void;
  selectedSummarizedAttendance: AttendanceSummarized | null;
  setIsDeleteDialogOpen: (isDeleteDialogOpen: boolean) => void;
  setSelectedAttendanceId: (selectedAttendanceId: number) => void;
  setIsEditModalOpen: (isEditModalOpen: boolean) => void;
}
const columnSizingOptions = {
  EmployeeName: {
    minWidth: 243,
    defaultWidth: 243,
  },
  SupervisorName: {
    minWidth: 243,
    defaultWidth: 243,
  },
  Date: {
    minWidth: 150,
    defaultWidth: 150,
  },
  StartTime: {
    minWidth: 150,
    defaultWidth: 150,
  },
  EndTime: {
    minWidth: 150,
    defaultWidth: 150,
  },
  Duration: {
    minWidth: 150,
    defaultWidth: 150,
  },
  Location: {
    minWidth: 150,
    defaultWidth: 150,
  },
  actions: {
    minWidth: 44,
    defaultWidth: 44,
  },
};

const SummarizedAttendances: React.FC<ISummarizedAttendances> = ({
  isModalOpen,
  closeModal,
  selectedSummarizedAttendance,
  setIsDeleteDialogOpen,
  setSelectedAttendanceId,
  setIsEditModalOpen,
}) => {
  const { t } = useTranslation();
  const attendancesStore = useStore(useAttendancesStore);
  const [items, setItems] = useState<Attendance[]>([]);
  const userStore = useStore(useUserStore);
  const columnsDef: TableColumnDefinition<Attendance>[] = [
    createTableColumn<Attendance>({
      columnId: "EmployeeName",
      compare: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
      renderHeaderCell: () => (
        <> {t("attendanceOwnHr.detailedAttendanceTable.name")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "SupervisorName",
      compare: (a, b) => a.SupervisorName.localeCompare(b.SupervisorName),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.managerName")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "Date",
      compare: (a, b) => (a.Date.isBefore(b.Date) ? 1 : -1),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.date")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "Type",
      compare: (a, b) => a.Type.localeCompare(b.Type),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.type")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "StartTime",
      compare: (a, b) => (a.StartTime.isBefore(b.StartTime) ? 1 : -1),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.startTime")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "EndTime",
      compare: (a, b) => (a.EndTime.isBefore(b.EndTime) ? 1 : -1),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.endTime")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "Duration",
      compare: (a, b) => (a >= b ? 1 : -1),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.duration")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "Location",
      compare: (a, b) => a.Location.localeCompare(b.Location),
      renderHeaderCell: () => (
        <>{t("attendanceOwnHr.detailedAttendanceTable.location")}</>
      ),
    }),
    createTableColumn<Attendance>({
      columnId: "actions",
      renderHeaderCell: () => <></>,
    }),
  ];
  const [columns] = useState<TableColumnDefinition<Attendance>[]>(columnsDef);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const {
    getRows,
    columnSizing_unstable,
    tableRef,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSort({
        defaultSortState: { sortColumn: "file", sortDirection: "ascending" },
      }),
    ]
  );

  const currentPageRows = sort(
    getRows((row) => {
      return {
        ...row,
      };
    })
  );
  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  useEffect(() => {
    if (selectedSummarizedAttendance) {
      const attendances = attendancesStore.attendances.filter(
        (att) =>
          att?.EmployeeId === selectedSummarizedAttendance?.EmployeeId &&
          att?.Date.format("YYYY-MM-DD") ===
            selectedSummarizedAttendance?.Date.format("YYYY-MM-DD") &&
          att?.Location === selectedSummarizedAttendance?.Location
      );
      setItems(attendances);
    } else {
      setItems([]);
    }
  }, [
    selectedSummarizedAttendance,
    attendancesStore.attendances,
    attendancesStore.summarizedAttendances,
  ]);

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={t("attendanceOwnHr.attendancesTable.summarized.modal.title")}
        subtitle={t(
          "attendanceOwnHr.attendancesTable.summarized.modal.subtitle"
        )}
        contentTitle=""
        headerStyle="summarizedModalHeader"
      >
        <div
          style={{ overflowX: "auto" }}
          className="summarizedModalTableContainer"
        >
          <Table
            ref={tableRef}
            sortable
            {...columnSizing_unstable.getTableProps()}
            noNativeElements={true}
            className="detailedAttendanceTable"
          >
            <TableHeader className="detailedAttendanceHeaderRow">
              <TableRow>
                {columns.map((column) => (
                  <Menu openOnContext key={column.columnId}>
                    <MenuTrigger>
                      <TableHeaderCell
                        key={column.columnId}
                        {...headerSortProps(column.columnId)}
                        {...columnSizing_unstable.getTableHeaderCellProps(
                          column.columnId
                        )}
                      >
                        {column.renderHeaderCell()}
                      </TableHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={columnSizing_unstable.enableKeyboardMode(
                            column.columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {getCurrentPageRows().map(({ item }) => (
                <TableRow key={item.Id}>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("EmployeeName")}
                  >
                    <TableCellLayout
                      truncate
                      media={<Avatar name={item.EmployeeName} />}
                    >
                      <div className="detailedAttendanceNameContainer">
                        <div className="detailedAttendanceLabelSection">
                          {item.EmployeeName}
                        </div>
                        <div className="detailedAttendanceDepartmentSection">
                          {item.DepartmentName}
                        </div>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps(
                      "SupervisorName"
                    )}
                  >
                    <TableCellLayout
                      truncate
                      media={
                        item?.SupervisorName !== "Unassigned" ? (
                          <Avatar name={item.SupervisorName} />
                        ) : null
                      }
                    >
                      <div className="detailedAttendanceLabelSection">
                        {item.SupervisorName}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("Date")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendancePeriodContainer">
                        <div className="detailedAttendanceLabelSection">
                          {item.Date.format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("Type")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        {item.Type}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("StartTime")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        {item?.StartTime.format("HH:mm")}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("EndTime")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        {item.EndTime.format("HH:mm")}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("Duration")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        {item.Duration}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("Location")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        <span>{item.Location}</span>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("actions")}
                  >
                    <TableCellLayout className="detailedAttendanceActionsCell">
                      <div className="detailedAttendanceActionsSection">
                        <Menu>
                          <MenuTrigger disableButtonEnhancement>
                            <Tooltip
                              content="Menu action"
                              withArrow
                              positioning="below"
                              appearance="inverted"
                              relationship={"label"}
                            >
                              <Image
                                className="actionIcon"
                                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                alt="horizontalMenu"
                              />
                            </Tooltip>
                          </MenuTrigger>

                          <MenuPopover>
                            <MenuList>
                              {helpers.checkHasPermissions(
                                userStore?.user?.Permissions,
                                "Attendances",
                                "Can Edit"
                              ) ? (
                                <MenuItem
                                  onClick={() => {
                                    setSelectedAttendanceId(item?.Id);
                                    setIsEditModalOpen(true);
                                  }}
                                >
                                  <span>
                                    {t(
                                      "attendanceOwnHr.detailedAttendanceTable.edit"
                                    )}
                                  </span>
                                </MenuItem>
                              ) : null}
                              {helpers.checkHasPermissions(
                                userStore?.user?.Permissions,
                                "Attendances",
                                "Can Delete"
                              ) ? (
                                <MenuItem
                                  onClick={() => {
                                    setIsDeleteDialogOpen(true);
                                    setSelectedAttendanceId(item?.Id);
                                  }}
                                >
                                  <span>
                                    {t(
                                      "attendanceOwnHr.detailedAttendanceTable.delete"
                                    )}
                                  </span>
                                </MenuItem>
                              ) : null}
                            </MenuList>
                          </MenuPopover>
                        </Menu>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </div>
  );
};

export default SummarizedAttendances;
