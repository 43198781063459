import "./SetupSteps.css";
import { Step1 } from "./Steps/Step1/Step1";
import Support from "./Support";
import Footer from "./Footer";
import SideNav from "./SideNav";
import { useEffect, useState } from "react";
import { Step2 } from "./Steps/Step2/Step2";
import { Step3 } from "./Steps/Step3/Step3";
import { Step4 } from "./Steps/Step4/Step4";
import { PersonioStep4 } from "./Steps/Step4/PersonioStep4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrganizationService from "../../services/organization";
import { useStore } from "zustand";
import { useInitialSetupAdminsStore } from "../../store/initialSetupAdminsStore";
import { useInitialSetupOrganizationStore } from "../../store/initialSetupOrganizationStore";

const setHrSystem = async (id: number) =>
  await OrganizationService.setHrSystem(id);
const setInitialAdmins = async (admins: number[]) =>
  await OrganizationService.setInitialAdmins(admins);

export function SetupSteps() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const navigate = useNavigate();
  const [selectedHrSystem, setSelectedHrSystem] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("test");
  const adminsStore = useStore(useInitialSetupAdminsStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  useEffect(() => {
    if (
      currentStep === 1 ||
      (currentStep === 4 &&
        initialSetupOrganizationStore.hrSystem &&
        initialSetupOrganizationStore?.hrSystem === 2)
    ) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [currentStep]);

  const handleNext = async () => {
    if (currentStep === 1 && isNextDisabled) {
      return;
    }
    if (currentStep === 1) {
      try {
        const result = await setHrSystem(
          parseInt(selectedHrSystem === "otherHr" ? "1" : selectedHrSystem)
        );
        initialSetupOrganizationStore.setHrSystem(result?.HrSystemId);
      } catch (e) {
        setIsError(true);
        setErrorMessage(t("errorSettingTheHrSystem"));
      } finally {
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep === 2) {
      if (adminsStore.admins.length > 0) {
        setIsNextDisabled(true);
        try {
          await setInitialAdmins(adminsStore.admins);
        } catch (e) {
          setIsError(true);
          setErrorMessage(t("initialSetup.errors.errorSettingTheAdmins"));
        } finally {
          setIsNextDisabled(false);
          setCurrentStep(currentStep + 1);
        }
      }
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3) {
      setCurrentStep(currentStep + 1);
    } else if (
      currentStep === 4 &&
      initialSetupOrganizationStore.hrSystem &&
      initialSetupOrganizationStore.hrSystem === 2
    ) {
      setCurrentStep(5);
    } else if (currentStep === 4 || currentStep === 5) {
      initialSetupOrganizationStore.setSetupCompleted(true);
    }
  };

  const handleCancel = () => {
    setCurrentStep(1);
    navigate("welcome");
  };

  const handleSkip = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleOptionSelected = (value: boolean) => {
    setIsNextDisabled(!value);
  };

  return (
    <div className="app">
      {isError ? (
        <div className="generalErrorContainer">{errorMessage}</div>
      ) : (
        <>
          <SideNav
            currentStep={currentStep}
            totalSteps={
              initialSetupOrganizationStore.hrSystem &&
              initialSetupOrganizationStore?.hrSystem === 2
                ? 5
                : 4
            }
            setCurrentStep={setCurrentStep}
          />

          <div className="setupContainer">
            <div className="content">
              <div className="main-content">
                <div className="details-content">
                  {currentStep === 1 ? (
                    <Step1
                      setCurrentStep={setCurrentStep}
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                      setSelectedHrSystem={setSelectedHrSystem}
                      setIsNextDisabled={handleOptionSelected}
                    />
                  ) : currentStep === 2 ? (
                    <Step2
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                    />
                  ) : currentStep === 3 ? (
                    <Step3
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                    />
                  ) : currentStep === 4 &&
                    initialSetupOrganizationStore.hrSystem &&
                    initialSetupOrganizationStore.hrSystem === 2 ? (
                    <PersonioStep4
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  ) : currentStep === 4 || currentStep === 5 ? (
                    <Step4
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <Footer
              onNext={handleNext}
              onCancel={handleCancel}
              onBack={handleBack}
              onSkip={handleSkip}
              currentStep={currentStep}
              isNextDisabled={isNextDisabled}
            />
          </div>
        </>
      )}
    </div>
  );
}
