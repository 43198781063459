import { useTranslation } from "react-i18next";
import "./Reports.css";

export default function Reports() {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="title">{t("reports.placeholder")}</h1>
      {/* <iframe
        title="20.03.2024User360 (1) (1) 1 (1)"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiNDQ5M2ZlMWEtZmI5NC00YWVmLThlYWQtMDhkMjIzMGE0NWFjIiwidCI6IjUxMWM4ZTVjLWIwNzMtNDU1MC1iZjc0LTZkODllNmVhMTE5ZCIsImMiOjl9"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe> */}
    </div>
  );
}
