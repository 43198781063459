import React from "react";
import "./SideNav.css";
import { useTranslation } from "react-i18next";

interface SideNavProps {
  currentStep: number;
  totalSteps: number;
  setCurrentStep: (step: number) => void;
}

const SideNav: React.FC<SideNavProps> = ({ currentStep, totalSteps }) => {
  const { t } = useTranslation();

  const renderSteps = () => {
    const steps = [];
    for (let i = 1; i <= totalSteps; i++) {
      const stepClassName =
        currentStep >= i + 1
          ? "side-nav-li completed"
          : currentStep === i
          ? "currentStep"
          : "side-nav-li";
      steps.push(
        <div key={i} className={stepClassName}>
          <span className="step">
            {t("initialSetup.step")} {i}
          </span>
        </div>
      );
    }
    return steps;
  };

  return (
    <div className="side-nav">
      <div className="side-nav-ul">{renderSteps()}</div>
    </div>
  );
};

export default SideNav;
