import { useEffect } from "react";
import * as msTeams from "@microsoft/teams-js";
import FullScreenSpinner from "../components/FullScreenSpinner";

interface ITeamsConfigWrapper {
  children: React.ReactNode;
  isAppInitialized: boolean;
  isInSettings: boolean;
  isInTeams: boolean;
  entityId: string;
  contentUrl: string;
  suggestedDisplayName: string;
  setIsInTeams: (isInTeams: boolean) => void;
  setIsAppInitialized: (isAppInitialized: boolean) => void;
  setIsInSettings: (isInSettings: boolean) => void;
  onSubmitConfig: () => Promise<void>;
}

const renderLoading = (): React.ReactElement => <FullScreenSpinner />;

const renderNotInTeams = (): React.ReactElement => (
  <>
    <p>Not in Teams</p>

    <p>
      Unfortunately, at this moment, the app can only be configured from inside
      Teams. 😔
    </p>
  </>
);

const renderNotInSettings = (): React.ReactElement => (
  <>
    <p>Not in Settings</p>

    <p>
      Unfortunately, at this moment, the app can only be configured from the
      Settings page. 😔
    </p>
  </>
);

export const TeamsConfigWrapper = ({
  children,
  contentUrl,
  entityId,
  suggestedDisplayName,
  isAppInitialized,
  isInSettings,
  isInTeams,
  setIsInTeams,
  setIsAppInitialized,
  setIsInSettings,
  onSubmitConfig,
}: ITeamsConfigWrapper): React.ReactElement => {
  const isDev = process.env.NODE_ENV === "development";
  useEffect(() => {
    const registerHandler = async (): Promise<void> => {
      if (!isAppInitialized) {
        try {
          await msTeams.app.initialize();
          setIsAppInitialized(msTeams.app.isInitialized());
          setIsInTeams(true);
          msTeams.pages.config.setValidityState(true);
        } catch (e) {
          if (
            !["resourceDisabled", "resourceRequiresConsent"].includes(
              (e as Error).message
            )
          ) {
            setIsInTeams(false);
          }
        } finally {
          msTeams.app.notifySuccess();
        }
      } else {
        setIsInSettings(
          msTeams.app.getFrameContext() === msTeams.FrameContexts.settings
        );
      }
    };
    void registerHandler();
  }, [isAppInitialized, isInSettings]);

  useEffect(() => {
    if (!isInTeams || !isInSettings) {
      return;
    }
    msTeams.pages.config.registerOnSaveHandler(async (saveEvent) => {
      try {
        await onSubmitConfig();
        await msTeams.pages.config.setConfig({
          suggestedDisplayName,
          entityId,
          contentUrl,
          websiteUrl: contentUrl,
        });
        saveEvent.notifySuccess();
      } catch (e: any) {
        saveEvent.notifyFailure(e.message);
      }
    });
  }, [isInSettings, isInTeams]);

  if (!isDev) {
    if (!isAppInitialized) {
      return renderLoading();
    }

    if (!isInTeams) {
      return renderNotInTeams();
    }

    if (!isInSettings) {
      return renderNotInSettings();
    }
  }
  return <>{children}</>;
};
