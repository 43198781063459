import { Image } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./Step4.css";
import { useEffect } from "react";
import OrganizationService from "../../../../services/organization";

export function Step4(props: {
  setIsError: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const activateOrganization = async () => {
      try {
        const result = await OrganizationService.activateOrganization();
      } catch (e) {
        props.setIsError(true);
        props.setErrorMessage(
          t("initialSetup.errors.errorActivatingOrganization")
        );
      }
    };
    activateOrganization();
  }, []);

  return (
    <div className="welcome page center">
      <div className="step4Narrow page-padding">
        <Image src="hello.png" />
        <div className="welcomeContainer">
          <h1 className="welcomeMessage">{t("initialSetup.welcomeToTargo")}</h1>
          <div className="finishSection">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0ZM10.5 1.5C5.80558 1.5 2 5.30558 2 10C2 14.6944 5.80558 18.5 10.5 18.5C15.1944 18.5 19 14.6944 19 10C19 5.30558 15.1944 1.5 10.5 1.5ZM9.25 11.4393L13.7197 6.96967C14.0126 6.67678 14.4874 6.67678 14.7803 6.96967C15.0466 7.23594 15.0708 7.6526 14.8529 7.94621L14.7803 8.03033L9.78033 13.0303C9.51406 13.2966 9.0974 13.3208 8.80379 13.1029L8.71967 13.0303L6.21967 10.5303C5.92678 10.2374 5.92678 9.76256 6.21967 9.46967C6.48594 9.2034 6.9026 9.1792 7.19621 9.39705L7.28033 9.46967L9.25 11.4393L13.7197 6.96967L9.25 11.4393Z"
                fill="#107C10"
              />
            </svg>

            <p className="completeMessage">
              {t("initialSetup.completeMessage")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
