import "./Layout.css";
import SideNav from "./SideNav";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { SearchContext } from "../../contexts/SearchContext";
import { FiltersProvider } from "../../contexts/FiltersContext";
import { ChevronDoubleRightRegular } from "@fluentui/react-icons";
import { Image } from "@fluentui/react-components";

export default function Layout(props: {
  showFunction?: boolean;
  environment?: string;
}) {
  const [searchField, setSearchField] = useState<string>("");
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [sideNavOpen, setSideNavOpen] = useState(
    screenWidth <= 768 ? false : true
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (screenWidth > 768) {
      setSideNavOpen(true);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  useEffect(() => {
    setSearchField("");
  }, [location]);

  return (
    <div className="absencesLayout">
      <FiltersProvider>
        <Navbar searchField={searchField} setSearchField={setSearchField} />
        <div className="absencesContentLayout">
          <SideNav isOpen={sideNavOpen} />
          {screenWidth <= 768 ? (
            <>
              <div
                className={`toggleSideNavBtn ${
                  sideNavOpen ? "hideToggleSidebar" : ""
                }`}
                onClick={() => setSideNavOpen(true)}
              >
                <ChevronDoubleRightRegular fontSize={24} />
              </div>
              <div
                className={`closeSidebarNav ${
                  sideNavOpen ? "showSidenavClose" : ""
                }`}
                onClick={() => setSideNavOpen(false)}
              >
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/close.svg`}
                  alt="close"
                />
              </div>
            </>
          ) : null}

          <div
            className={`mainAbsencesContentLayout ${
              sideNavOpen && screenWidth <= 768 ? "hideContent" : ""
            }`}
          >
            <SearchContext.Provider value={searchField}>
              <Outlet />
            </SearchContext.Provider>
          </div>
        </div>
      </FiltersProvider>
    </div>
  );
}
