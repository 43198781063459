import { Image, Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./Filters.css";
import FiltersSection from "./FiltersSection";

interface IFiltersModal {
  isOpen: boolean;
  onClose: () => void;
  nameFilters: string[];
  departmentFilters: string[];
  positionFilters: string[];
  statusFilters: string[];
  roleFilters: string[];
  customRoleFilters: string[];
  selectedNameFilters: string[];
  setSelectedNameFilters: (selectedNameFilters: string[]) => void;
  selectedDepartmentFilters: string[];
  setSelectedDepartmentFilters: (selectedDepartmentFilters: string[]) => void;
  selectedPositionFilters: string[];
  setSelectedPositionFilters: (selectedPositionFilters: string[]) => void;
  selectedStatusFilters: string[];
  setSelectedStatusFilters: (selectedStatusFilters: string[]) => void;
  selectedRoleFilters: string[];
  setSelectedRoleFilters: (selectedRoleFilters: string[]) => void;
  selectedCustomRoleFilters: string[];
  setSelectedCustomRoleFilters: (selectedCustomRoleFilters: string[]) => void;
  clearFilters: () => void;
}

const Filters: React.FC<IFiltersModal> = ({
  isOpen,
  onClose,
  nameFilters,
  departmentFilters,
  positionFilters,
  statusFilters,
  roleFilters,
  customRoleFilters,
  selectedNameFilters,
  setSelectedNameFilters,
  selectedDepartmentFilters,
  setSelectedDepartmentFilters,
  selectedPositionFilters,
  setSelectedPositionFilters,
  selectedStatusFilters,
  setSelectedStatusFilters,
  selectedRoleFilters,
  setSelectedRoleFilters,
  selectedCustomRoleFilters,
  setSelectedCustomRoleFilters,
  clearFilters,
}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="usersFiltersModalOverlay">
      <div className="usersFiltersModal">
        <div className="usersFiltersModalHeaderContainer">
          <div className="usersFiltersModalTitle">{t("filters.filters")}</div>
          <button
            className="users-filters-modal-close-button"
            onClick={onClose}
          >
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/close.svg`}
              alt="close"
            />
          </button>
        </div>
        <div className="usersFiltersModalContent">
          <div className="usersFiltersModalContentSection">
            <FiltersSection
              type="name"
              filters={nameFilters}
              selectedFilters={selectedNameFilters}
              setSelectedFilters={setSelectedNameFilters}
            />
            <FiltersSection
              type="department"
              filters={departmentFilters}
              selectedFilters={selectedDepartmentFilters}
              setSelectedFilters={setSelectedDepartmentFilters}
            />
            <FiltersSection
              type="position"
              filters={positionFilters}
              selectedFilters={selectedPositionFilters}
              setSelectedFilters={setSelectedPositionFilters}
            />
            <FiltersSection
              type="status"
              filters={statusFilters}
              selectedFilters={selectedStatusFilters}
              setSelectedFilters={setSelectedStatusFilters}
            />
            <FiltersSection
              type="role"
              filters={roleFilters}
              selectedFilters={selectedRoleFilters}
              setSelectedFilters={setSelectedRoleFilters}
            />
            {customRoleFilters.length > 0 ? (
              <FiltersSection
                type="customRole"
                filters={customRoleFilters}
                selectedFilters={selectedCustomRoleFilters}
                setSelectedFilters={setSelectedCustomRoleFilters}
              />
            ) : null}
          </div>
          <div className="usersFiltersModalActionButtons">
            <div className="usersClearAllButton" onClick={clearFilters}>
              {t("filters.buttons.clearAll")}
            </div>
            <div className="usersFiltersModalButtons">
              <Button type="button" onClick={onClose}>
                {t("filters.buttons.cancel")}
              </Button>
              <Button
                appearance="primary"
                type="button"
                className="usersApplyButton"
                onClick={onClose}
              >
                {t("filters.buttons.apply")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
