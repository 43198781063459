import { useState } from "react";
import "./SimpleLayout.css";
import SimpleNavbar from "./SimpleNavbar";
import { Outlet, useLocation } from "react-router-dom";

export default function SimpleLayout() {
  const location = useLocation();
  const [searchField, setSearchField] = useState<string>("");

  return (
    <div className="simpleLayout">
      <SimpleNavbar searchField={searchField} setSearchField={setSearchField} />
      <div
        className={`contentSimpleLayout ${
          location.pathname === "/menu/about" ? "about" : ""
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
}
