import { create } from "zustand";

export interface ITeamsContextStore {
  aadToken: string;
  isAppInitialized: boolean;
  isInSettings: boolean;
  isInTeams: boolean;
  setAadToken: (aadToken: string) => void;
  setIsAppInitialized: (isAppInitialized: boolean) => void;
  setIsInSettings: (isInSettings: boolean) => void;
  setIsInTeams: (isInTeams: boolean) => void;
}

export const useTeamsContextStore = create<ITeamsContextStore>((set) => ({
  aadToken: "",
  isAppInitialized: false,
  isInSettings: false,
  isInTeams: false,
  setAadToken: (aadToken) => set({ aadToken }),
  setIsAppInitialized: (isAppInitialized) => set({ isAppInitialized }),
  setIsInSettings: (isInSettings) => set({ isInSettings }),
  setIsInTeams: (isInTeams) => set({ isInTeams }),
}));
