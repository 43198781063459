import { useTranslation } from "react-i18next";
import {
  Button,
  Image,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
} from "@fluentui/react-components";
import { Add20Filled, Subtract20Filled } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import "./About.css";
import { useCallback, useState } from "react";

export default function About() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openItem, setOpenItem] = useState(0);
  const handleToggle = useCallback<AccordionToggleEventHandler>((_, data) => {
    setOpenItem(data.value as number);
  }, []);

  return (
    <div className="aboutPageContainer">
      <div className="aboutPageHeaderContainer">
        <div className="aboutPageHeaderTitleContainer">
          <div className="aboutPageHeaderTitle">{t("about.empoweredByUs")}</div>
          <div className="aboutPageHeaderDescription">
            {t("about.description")}
          </div>
        </div>
      </div>
      <div className="aboutPageSection2">
        <div className="aboutPageSection2Card">
          <div className="aboutPageSection2ImageContainer">
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/about/services-1-2.png`}
              className="aboutPageSection2Image"
              alt=""
            />
          </div>
          <div className="aboutPageSection2CardTitle">
            {t("about.ourValues")}
          </div>
          <div className="aboutPageSection2CardDescription">
            {t("about.ourValuesDescription")}
          </div>
        </div>
        <div className="aboutPageSection2Card">
          <div className="aboutPageSection2ImageContainer">
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/about/services-2-2.png`}
              className="aboutPageSection2Image"
              alt=""
            />
          </div>
          <div className="aboutPageSection2CardTitle">
            {t("about.ourApproach")}
          </div>
          <div className="aboutPageSection2CardDescription">
            {t("about.ourApproachDescription")}
          </div>
        </div>
      </div>
      <div className="aboutPageSection3">
        <div className="aboutPageSection3ContentCard">
          <div className="aboutPageSection3ContentCardTitle">
            {t("about.meetOurTeam")}
          </div>
          <div className="aboutPageSection3ContentCardDescription">
            {t("about.meetOurTeamDescription")}
          </div>
          <ul className="aboutPageSection3ContentList">
            <li>
              Armand Sullot - Founder & CEO - 25+ years in IT - 19 years
              Microsoft Germany
            </li>
            <li>Andra Colac - CFOO - 19+ years in banking industry</li>
            <li>Daniela Stanciu - CPIO - 14+ years Vodafone</li>
            <li>Mihai Stanciu - CTO - 13+ years in IT industry</li>
          </ul>
          <Button
            appearance="primary"
            style={{ width: "100%" }}
            onClick={() => navigate("/menu/contact-us")}
          >
            {t("about.contactUs")}
          </Button>
        </div>
        <div className="aboutPageSection3ImageContainer">
          <Image
            src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/about/about-img.jpg`}
            className="aboutPageSection3Image"
            alt=""
          />
        </div>
      </div>
      <div className="aboutPageSection4">
        <div className="aboutPageSection4AccordionContainer">
          <Accordion onToggle={handleToggle} openItems={openItem}>
            <AccordionItem value={1} className="accordionItem">
              <AccordionHeader
                expandIconPosition="end"
                expandIcon={
                  openItem === 1 ? <Subtract20Filled /> : <Add20Filled />
                }
              >
                <div className="accordionItemTitle">
                  {t("about.accordionTitle1")}
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div className="accordionItemDescription">
                  {t("about.accordionDescription1")}
                </div>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value={2} className="accordionItem">
              <AccordionHeader
                expandIconPosition="end"
                expandIcon={
                  openItem === 2 ? <Subtract20Filled /> : <Add20Filled />
                }
              >
                <div className="accordionItemTitle">
                  {t("about.accordionTitle2")}
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div className="accordionItemDescription">
                  {t("about.accordionDescription2")}
                </div>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value={3} className="accordionItem">
              <AccordionHeader
                expandIconPosition="end"
                expandIcon={
                  openItem === 3 ? <Subtract20Filled /> : <Add20Filled />
                }
              >
                <div className="accordionItemTitle">
                  {t("about.accordionTitle3")}
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div className="accordionItemDescription">
                  {t("about.accordionDescription3")}
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="aboutPageSection4Card">
          <div className="aboutPageSection4CardTitle">
            {t("about.customerFocus")}
          </div>
          <div className="aboutPageSection4CardDescription">
            {t("about.customerFocusDescription")}
          </div>
        </div>
      </div>
      <div className="aboutPageSection5">
        <div className="aboutPageSection5Container aboutPageSection5FirstContainer">
          <div className="aboutPageSection5ContainerTitle">
            {t("about.tARGOYourHRIntegrationHub")}
          </div>
          <div className="aboutPageSection5ContainerDescription">
            {t("about.tARGOYourHRIntegrationHubDescription")}
          </div>
        </div>
        <div className="aboutPageSection5Container aboutPageSection5SecondContainer">
          <div className="aboutPageSection5ContainerTitle">
            {t("about.expertConsultancyForTransformationalGrowth")}
          </div>
          <div className="aboutPageSection5ContainerDescription">
            {t("about.expertConsultancyForTransformationalGrowthDescription")}
          </div>
        </div>
      </div>
    </div>
  );
}
