import React from "react";
import "./Modal.css";
import { Image } from "@fluentui/react-components";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  subtitle: string;
  contentTitle: string;
  headerStyle: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  subtitle,
  contentTitle,
  headerStyle,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="modal">
        <div className="modalHeaderContainer">
          <div className={headerStyle || "modalHeader"}>
            <div className="logoAndTitleContainer">
              <Image
                className="targoLogo"
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/Avatar.svg`}
                alt="import"
              />

              <div className="titleContainer">
                <span className="modalTitle">{title}</span>
                <span className="modalSubtitle">{subtitle}</span>
              </div>
            </div>
            <button className="modal-close-button" onClick={onClose}>
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/close.svg`}
                alt="close"
              />
            </button>
          </div>
          <div className="modalContentTitle">
            <span className="modalContentTitleName">{contentTitle}</span>
          </div>
        </div>

        <div className="modalContent">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
