import { useTranslation } from "react-i18next";
import { Checkbox, Image } from "@fluentui/react-components";
import { useState } from "react";

interface IFiltersSection {
  type: string;
  filters?: string[];
  selectedFilters: string[];
  setSelectedFilters: (filters: string[]) => void;
}

const FiltersSection: React.FC<IFiltersSection> = ({
  type,
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<boolean>(false);
  const visibleFiltersCount = 6;
  return (
    <>
      <div className="usersFilterSectionTitle">
        <span>
          {t(`filters.sections.${type}`)}{" "}
          <span className="usersFilterSectionTitleNumber">
            ({filters?.length})
          </span>
        </span>
        <button
          className="users-filters-modal-close-button"
          onClick={() => setVisible(!visible)}
        >
          {visible ? (
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/filters/chevron_up.svg`}
              alt="hide"
            />
          ) : (
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/filters/chevron_down.svg`}
              alt="show"
            />
          )}
        </button>
      </div>
      {visible ? (
        <div className="usersFilterSectionCheckboxesContainer">
          {filters
            ?.slice(0, showMore ? filters.length : visibleFiltersCount)
            .map((filter, index) => (
              <Checkbox
                key={index}
                label={
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(97,97,97,1)",
                    }}
                  >
                    {filter}
                  </div>
                }
                checked={selectedFilters.includes(filter)}
                onChange={() => {
                  if (selectedFilters.includes(filter)) {
                    setSelectedFilters(
                      selectedFilters.filter((el) => el !== filter)
                    );
                  } else {
                    setSelectedFilters([...selectedFilters, filter]);
                  }
                }}
              />
            ))}
          {filters && filters?.length > visibleFiltersCount ? (
            <div
              className="usersFiltersSeeMoreBtn"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore
                ? t("filters.sections.seeLess")
                : t("filters.sections.seeMore")}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default FiltersSection;
