import {
  Dropdown,
  Image,
  Option,
  OptionGroup,
} from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { useSettingsStore } from "../../../store/settingsStore";
import { User } from "../../../types";
import { useState } from "react";
import { toast } from "react-toastify";
import settingsService from "../../../services/settings";
import "./RoleSelector.css";
import { useUserStore } from "../../../store/userStore";
import helpers from "../../../helpers/helpers";

interface IRoleSelector {
  item: User;
}

export const RoleSelector: React.FC<IRoleSelector> = ({ item }) => {
  const { t } = useTranslation();
  const settingsStore = useStore(useSettingsStore);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );
  const userStore = useStore(useUserStore);

  useState(() => {
    const initialSelection = item?.Role?.map((rl) => `${rl?.Type}-${rl?.Id}`);
    const initialValuesArray = item?.Role?.map((rl) => rl?.RoleName);
    if (initialSelection) {
      setSelectedOptions(initialSelection);
    }
    if (initialValuesArray) {
      setSelectedValue(initialValuesArray.join(", "));
    }
  });

  const setUserRole = async (userId: number, selectedOptions: string[]) => {
    try {
      const result = await settingsService.setUserRole(userId, selectedOptions);
      if ("status" in result && result?.status === "error") {
        toast.error(t(`settings.manageUsers.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
        return false;
      } else if (
        "status" in result &&
        result?.status === "success" &&
        result?.message === "rolesRemoved"
      ) {
        settingsStore.removeUserRoles(userId);
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      } else if ("userRole" in result) {
        settingsStore.replaceUserRole(result?.userRole, item.Id);
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      } else if ("roles" in result) {
        settingsStore.setUserRoles(result?.roles, item.Id);
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      }
      return true;
    } catch (e) {
      toast.error(t("settings.manageUsers.errors.role"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
      return false;
    }
  };

  const onOptionSelect: DropdownProps["onOptionSelect"] = async (ev, data) => {
    if (!data.optionValue) {
      return;
    }
    const optionValue = data?.optionValue;
    const rawSelectedDataValue = data?.optionValue?.split("-");
    if (rawSelectedDataValue) {
      const group = rawSelectedDataValue[0];
      if (data?.selectedOptions.includes(optionValue)) {
        const newSelected =
          selectedOptions?.filter((opt) => !opt.startsWith(group)) ?? [];
        if (optionValue) {
          newSelected.push(optionValue);
        }
        if (newSelected) {
          const result = await setUserRole(item.Id, newSelected);
          if (result) {
            setSelectedOptions(newSelected);
          }
        }
      } else {
        const newSelected = selectedOptions?.filter(
          (opt) => opt !== optionValue
        );
        if (newSelected) {
          const result = await setUserRole(item.Id, newSelected);
          if (result) {
            setSelectedOptions(newSelected);
          }
        }
      }
    }
  };

  return (
    <Dropdown
      disabled={
        !helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Manage Users",
          "Can Edit"
        )
      }
      multiselect={true}
      onOptionSelect={onOptionSelect}
      placeholder={t("settings.manageUsers.noRole")}
      className="usersRoleDropdown"
      defaultValue={selectedValue}
      selectedOptions={selectedOptions}
      expandIcon={
        <div className="dropdownIcons">
          <span>
            <Image
              className="line"
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/verticalRoleLine.svg`}
            />
          </span>
          <span>
            <Image
              className="arrow"
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/arrowDown.svg`}
            />
          </span>
        </div>
      }
    >
      <OptionGroup label={t("settings.manageUsers.roleSelector.default")}>
        {settingsStore?.allRoles
          ?.filter((rl) => rl?.Type === "builtIn")
          .map((role, index) => (
            <Option key={index} value={`${role?.Type}-${role?.Id}`}>
              {role?.RoleName}
            </Option>
          ))}
      </OptionGroup>
      <OptionGroup label={t("settings.manageUsers.roleSelector.custom")}>
        {settingsStore?.allRoles
          ?.filter((rl) => rl?.Type === "custom")
          .map((role, index) => (
            <Option key={index} value={`${role?.Type}-${role?.Id}`}>
              {role?.RoleName}
            </Option>
          ))}
      </OptionGroup>
    </Dropdown>
  );
};
