import { Form, Formik } from "formik";
import Modal from "./Modal";
import { Button, Image, Spinner } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

export default function ImportCsvModal({
  isImporting,
  isModalOpen,
  closeModal,
  title,
  subtitle,
  currentTime,
  headerStyle,
  csvFile,
  handleSubmit,
  handleDrop,
  handleDragOver,
  handleFileChange,
  handleImportButtonClick,
  handleDownloadTemplate,
  svgUrl,
  errors,
}: any) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      title={title}
      subtitle={subtitle}
      contentTitle={currentTime}
      headerStyle={headerStyle}
    >
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div
              className="dragDropImportContainer"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <div className="detailsContainer">
                <span className="contentTitle">
                  {t("importCsv.dragDropImport")}
                </span>
                <span className="details">
                  {t("importCsv.importAllYourData")}
                </span>
              </div>

              <input
                className="fileInput"
                name="file"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />

              <div className="actionButtons">
                <Button
                  appearance="secondary"
                  type="button"
                  disabled={isSubmitting || isImporting}
                  className="downloadButton"
                  onClick={handleDownloadTemplate}
                >
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/download.svg`}
                    alt="download"
                  />
                  {t("importCsv.downloadCSVTemplate")}
                </Button>
                <Button
                  appearance="primary"
                  type="button"
                  className="importCsvFile"
                  onClick={handleImportButtonClick}
                >
                  {isSubmitting || isImporting ? <Spinner /> : null}
                  {t("importCsv.importCSV")}
                </Button>
              </div>
              {svgUrl && <div className="svgPreview">{csvFile?.name}</div>}
              {isImporting ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              ) : null}
              {errors.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  {errors.slice(0, 4).map((error: string, index: number) => (
                    <div key={index} className="svgFileErrors">
                      <span className="errorText">{error}</span>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            <div className="actionButtons">
              <Button
                type="submit"
                disabled={isSubmitting || isImporting || !csvFile}
              >
                {isSubmitting || isImporting
                  ? t("importCsv.submitting")
                  : t("importCsv.saveCSV")}
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || isImporting || !csvFile}
              >
                {isSubmitting || isImporting
                  ? t("importCsv.submitting")
                  : t("importCsv.import")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
