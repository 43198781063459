import { create } from "zustand";
import { EmployeeStoreState, EmployeeManagement, User } from "../types";

export const useEmployeeStore = create<EmployeeStoreState>((set) => ({
  employees: [],
  managers: [],
  loading: false,
  setEmployees: (employees: EmployeeManagement[]) => set({ employees }),
  setManagers: (managers: User[]) => set({ managers }),
  updateEmployee: (updatedEmployee: EmployeeManagement) => {
    set((state) => ({
      employees: state.employees.map((employee) =>
        employee.Id === updatedEmployee.Id ? updatedEmployee : employee
      ),
    }));
  },
  checkManager: (currentUser: User) =>
    set((state) => ({
      employees: state.employees.filter(
        (employee) =>
          employee.SupervisorId === currentUser.Id ||
          employee.Id === currentUser.EmployeeId ||
          (currentUser?.Role &&
            currentUser?.Role[0]?.Type === "builtIn" &&
            currentUser?.Role[0]?.Id === 2)
      ),
    })),
  setLoading: (loading: boolean) => set({ loading }),
}));
