import * as React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  useTableFeatures,
  TableColumnDefinition,
  useTableSelection,
  useTableColumnSizing_unstable,
  useTableSort,
  createTableColumn,
  TableColumnId,
  Avatar,
  useArrowNavigationGroup,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./Step3Table.css";
import Pagination from "../../Pagination";
import { RoleSelector } from "../../RoleSelectors";
import { InitialRole, User } from "../../../../types";
import { useInitialSetupUsersStore } from "../../../../store/initialSetupUsersStore";
import { useStore } from "zustand";

const columnSizingOptions = {
  Name: {
    minWidth: 243,
    defaultWidth: 243,
  },
  JobTitle: {
    minWidth: 243,
    defaultWidth: 243,
  },
  RoleName: {
    minWidth: 150,
    defaultWidth: 150,
  },
};

const columns: TableColumnDefinition<User>[] = [
  createTableColumn<User>({
    columnId: "Name",
    compare: (a, b) => {
      if (!a?.FullName && !b?.FullName) {
        return 0;
      } else if (!a?.FullName) {
        return 1;
      } else if (!b?.FullName) {
        return -1;
      } else {
        return a?.FullName.trim().localeCompare(b?.FullName.trim());
      }
    },
  }),
  createTableColumn<User>({
    columnId: "JobTitle",
    compare: (a, b) => {
      if (!a.JobTitle && !b.JobTitle) {
        return 0;
      } else if (!a.JobTitle) {
        return 1;
      } else if (!b.JobTitle) {
        return -1;
      } else {
        return a.JobTitle.localeCompare(b.JobTitle);
      }
    },
  }),
  createTableColumn<User>({
    columnId: "RoleId",
  }),
];

export const Step3Table = (props: { roles: InitialRole[] }) => {
  const initialUsersStore = useStore(useInitialSetupUsersStore);
  const { t } = useTranslation();
  const items = initialUsersStore?.users;
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const {
    getRows,
    columnSizing_unstable,
    tableRef,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSelection({
        selectionMode: "multiselect",
      }),
      useTableSort({
        defaultSortState: { sortColumn: "Name", sortDirection: "ascending" },
      }),
    ]
  );

  const currentPageRows = sort(
    getRows((row) => {
      return {
        ...row,
      };
    })
  );

  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });

  return (
    <div className="step3TableContainer">
      <Table
        ref={tableRef}
        {...keyboardNavAttr}
        {...columnSizing_unstable.getTableProps()}
        role="grid"
        sortable
        aria-label="DataGrid implementation with Table primitives"
        className="table"
        noNativeElements={true}
      >
        <TableHeader className="headerRow">
          <TableRow>
            <TableHeaderCell
              {...headerSortProps("Name")}
              {...columnSizing_unstable.getTableHeaderCellProps("Name")}
            >
              {t("initialSetup.steps.step3.table.name")}
            </TableHeaderCell>
            <TableHeaderCell
              {...headerSortProps("JobTitle")}
              {...columnSizing_unstable.getTableHeaderCellProps("JobTitle")}
            >
              {t("initialSetup.steps.step3.table.position")}
            </TableHeaderCell>
            <TableHeaderCell
              {...columnSizing_unstable.getTableHeaderCellProps("RoleName")}
            >
              {t("initialSetup.steps.step3.table.role")}
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {getCurrentPageRows().map((item, index) => (
            <TableRow key={item.item.Id}>
              <TableCell
                tabIndex={0}
                role="gridcell"
                {...columnSizing_unstable.getTableCellProps("Name")}
              >
                <TableCellLayout
                  media={
                    <Avatar
                      aria-label={item.item.FullName}
                      name={item.item.FullName}
                    />
                  }
                >
                  <div className="nameContainer">
                    <div className="labelSection">{item.item.FullName}</div>
                    <div className="departmentSection">
                      {item.item.Department?.Name}
                    </div>
                  </div>
                </TableCellLayout>
              </TableCell>
              <TableCell
                tabIndex={0}
                role="gridcell"
                {...columnSizing_unstable.getTableCellProps("JobTitle")}
              >
                <TableCellLayout>
                  <div className="positionContainer">
                    <div className="labelSection">{item.item.JobTitle}</div>
                    {item.item.EmploymentType ? (
                      <div className="positionTypeContainer">
                        <span className="positionType">
                          {item.item.EmploymentType}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </TableCellLayout>
              </TableCell>
              <TableCell
                tabIndex={0}
                role="gridcell"
                {...columnSizing_unstable.getTableCellProps("RoleName")}
              >
                <RoleSelector user={item.item} roles={props?.roles} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};
