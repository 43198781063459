import { useStore } from "zustand";
import { useQuery } from "@tanstack/react-query";
import AttendanceLogHours from "../components/AttendanceOwnHr/AttendanceComponents/AttendanceLogHours";
import AttendancesTable from "../components/AttendanceOwnHr/AttendanceComponents/AttendancesTable/AttendancesTable";
import { useAttendancesStore } from "../store/attendancesStore";
import attendanceService from "../services/attendance";
import Error from "../components/Error";
import { useTranslation } from "react-i18next";
import FullScreenSpinner from "../components/FullScreenSpinner";
import "./Attendance.css";
import TimerComponent from "../components/AttendanceOwnHr/AttendanceComponents/TimerComponent";

export default function Attendance() {
  const { t } = useTranslation();
  const attendancesStore = useStore(useAttendancesStore);

  const getAttendancesData = async () => {
    const result = await attendanceService.getAttendancesData();
    attendancesStore.setAttendances(result.attendances);
    attendancesStore.setUsers(result.users);
    return result;
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["attendancesData"],
    queryFn: getAttendancesData,
  });

  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error)
    return <Error message={t("attendanceOwnHr.errors.generalError")} />;

  return (
    <div className="attendancePageContainer">
      <TimerComponent />
      <AttendanceLogHours />
      <AttendancesTable />
    </div>
  );
}
