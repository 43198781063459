import { Spinner } from "@fluentui/react-components";
import "./FullScreenSpinner.css";

export default function FullScreenSpinner() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
}
