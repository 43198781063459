import * as Yup from "yup";
import "./ContactUsForm.css";
import { ErrorMessage, Form, Formik } from "formik";
import {
  Button,
  Image,
  Input,
  Select,
  Textarea,
  Field,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useUserStore } from "../../store/userStore";
import contactUsService from "../../services/contactUs";
import { toast } from "react-toastify";

export default function ContactUsForm() {
  const { t } = useTranslation();
  const userStore = useStore(useUserStore);

  const initialValues = {
    firstName: userStore?.user?.FirstName ?? "",
    lastName: userStore?.user?.LastName ?? "",
    title: "",
    topic: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("contactUs.validations.fieldRequired")),
    lastName: Yup.string().required(t("contactUs.validations.fieldRequired")),
    title: Yup.string().required(t("contactUs.validations.fieldRequired")),
    topic: Yup.string().required(t("contactUs.validations.fieldRequired")),
    message: Yup.string().required(t("contactUs.validations.fieldRequired")),
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      const result = await contactUsService.sendMessage(
        values?.firstName,
        values?.lastName,
        values?.title,
        values?.topic,
        values?.message
      );
      if ("status" in result && result?.status === "success") {
        toast.success(t("contactUs.success"), {
          position: "bottom-right",
        });
        resetForm({
          values: {
            firstName: userStore?.user?.FirstName ?? "",
            lastName: userStore?.user?.LastName ?? "",
            title: "",
            topic: "",
            message: "",
          },
        });
      } else if ("status" in result && result?.status === "error") {
        toast.error(t("contactUs.errors.sending"), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      }
    } catch (e) {
      toast.error(t("contactUs.errors.sending"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="contactUsFormContainer">
      <div className="contactUsInformationContainer">
        <span className="formTitle">
          {t("contactUs.contactUsForm.formTitle")}
        </span>

        <span className="formDescription">
          {t("contactUs.contactUsForm.formDescription")}
        </span>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            resetForm,
          }) => (
            <Form className="contactUsForm">
              <div className="contactUsFormFirstSection">
                <div className="contactUsInputContainer">
                  <label className="contactUsInputLabel">
                    {t("contactUs.contactUsForm.firstName")}
                  </label>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    value={values.firstName}
                    className="contactUsLighterInput"
                    appearance="filled-lighter"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#eefafd",
                      border: "none",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    contentAfter={
                      values.firstName && !errors.firstName ? (
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/inputCheck.svg`}
                          alt="Check Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : touched.firstName && errors.firstName ? (
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/errorIcon.svg`}
                          alt="Error Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : null
                    }
                  />
                  <ErrorMessage
                    className="error-message"
                    name="firstName"
                    component="div"
                  />
                </div>
                <div className="contactUsInputContainer">
                  <label className="contactUsInputLabel">
                    {t("contactUs.contactUsForm.lastName")}
                  </label>
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    value={values.lastName}
                    className="contactUsLighterInput"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#eefafd",
                      border: "none",
                    }}
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    contentAfter={
                      values.lastName && !errors.lastName ? (
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/inputCheck.svg`}
                          alt="Check Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : touched.lastName && errors.lastName ? (
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/errorIcon.svg`}
                          alt="Error Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : null
                    }
                  />
                  <ErrorMessage
                    className="error-message"
                    name="lastName"
                    component="div"
                  />
                </div>
              </div>
              <div className="contactUsFormSecondSection">
                <div className="contactUsInputContainer">
                  <label className="contactUsInputLabel">
                    {t("contactUs.contactUsForm.title")}
                  </label>
                  <Input
                    name="title"
                    value={values.title}
                    className="contactUsFilledInput"
                    contentAfter={
                      values.title && !errors.title ? (
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/inputCheck.svg`}
                          alt="Check Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : touched.title && errors.title ? (
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/errorIcon.svg`}
                          alt="Error Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : null
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="title"
                    component="div"
                  />
                </div>
                <div className="contactUsInputContainer">
                  <label className="contactUsInputLabel">
                    {" "}
                    {t("contactUs.contactUsForm.topic")}
                  </label>
                  <Select
                    name="topic"
                    value={values.topic}
                    className="contactUsLighterInput"
                    appearance="filled-darker"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#eefafd",
                      border: "none",
                    }}
                  >
                    <option>{t("contactUs.contactUsForm.selectTopic")}</option>
                    <option value="Customer Support">
                      {t("contactUs.contactUsForm.customerSupport")}
                    </option>
                    <option value="Technical Issues">
                      {t("contactUs.contactUsForm.technicalIssues")}
                    </option>
                    <option value="Subscription and Payments">
                      {t("contactUs.contactUsForm.subscriptionAndPayments")}
                    </option>
                    <option value="General Questions">
                      {t("contactUs.contactUsForm.generalQuestions")}
                    </option>
                    <option value="Product Feedback">
                      {t("contactUs.contactUsForm.productFeedback")}
                    </option>
                    <option value="Business Partnerships">
                      {t("contactUs.contactUsForm.businessPartnerships")}
                    </option>
                    <option value="New Feature Ideas">
                      {t("contactUs.contactUsForm.newFeatureIdeas")}
                    </option>
                    <option value="Other">
                      {t("contactUs.contactUsForm.other")}
                    </option>
                  </Select>
                  <ErrorMessage
                    className="error-message"
                    name="topic"
                    component="div"
                  />
                </div>
                <div className="contactUsInputContainer">
                  <label className="contactUsInputLabel">
                    {t("contactUs.contactUsForm.message")}
                  </label>
                  <Field>
                    <Textarea
                      name="message"
                      value={values.message}
                      appearance="filled-lighter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#eefafd",
                        border: "none",
                      }}
                      resize="vertical"
                    />
                  </Field>
                  <ErrorMessage
                    className="error-message"
                    name="message"
                    component="div"
                  />
                </div>
              </div>
              <div className="contactUsFormThirdSection">
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() =>
                    resetForm({
                      values: {
                        firstName: userStore?.user?.FirstName ?? "",
                        lastName: userStore?.user?.LastName ?? "",
                        title: "",
                        topic: "",
                        message: "",
                      },
                    })
                  }
                >
                  {t("contactUs.contactUsForm.discard")}
                </Button>
                <Button
                  className="sendContactUsForm"
                  appearance="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("contactUs.contactUsForm.send")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
