import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  TableHeaderCell,
} from "@fluentui/react-components";
import "./AnualOrTakenLeave.css";
import { useTimeOffsStore } from "../../../store/timeOffsStore";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const TakenLeave = () => {
  const { t } = useTranslation();
  const timeOffStore = useStore(useTimeOffsStore);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  return (
    <div className="anualOrTakenLeaveContainer">
      <h1 className="anualOrTakenLeaveTitle">
        {t("absencesOwnHr.taken.takenLeave")}
      </h1>
      {screenWidth <= 768 ? (
        <div className="statisticsContainer">
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.taken.sickLeave")}
            </div>
            <div className="statisticsValue">
              {timeOffStore?.userBalance?.SickBalance ?? 0}
            </div>
          </div>
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.taken.maternityPaternityLeave")}
            </div>
            <div className="statisticsValue">
              {" "}
              {timeOffStore?.userBalance?.ParentalBalance ?? 0}
            </div>
          </div>
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.taken.bereavementLeave")}
            </div>
            <div className="statisticsValue">
              {timeOffStore?.userBalance?.BereavementBalance ?? 0}
            </div>
          </div>
          <div className="statisticsMobRow">
            <div className="statisticsItem">
              {t("absencesOwnHr.taken.unpaidLeave")}
            </div>
            <div className="statisticsValue">
              {timeOffStore?.userBalance?.UnpaidBalance ?? 0}
            </div>
          </div>
        </div>
      ) : (
        <div className="statisticsContainer">
          <Table aria-label="Anual Leave table">
            <TableHeader className="statisticsHeader">
              <TableRow className="statisticsRow">
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.taken.sickLeave")}
                </TableHeaderCell>
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.taken.maternityPaternityLeave")}
                </TableHeaderCell>
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.taken.bereavementLeave")}
                </TableHeaderCell>
                <TableHeaderCell className="statisticsItem">
                  {t("absencesOwnHr.taken.unpaidLeave")}
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className="statisticsValuesContainer">
                <TableCell className="statisticsValue">
                  {timeOffStore?.userBalance?.SickBalance ?? 0}
                </TableCell>
                <TableCell className="statisticsValue">
                  {timeOffStore?.userBalance?.ParentalBalance ?? 0}
                </TableCell>
                <TableCell className="statisticsValue">
                  {timeOffStore?.userBalance?.BereavementBalance ?? 0}
                </TableCell>
                <TableCell className="statisticsValue">
                  {timeOffStore?.userBalance?.UnpaidBalance ?? 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default TakenLeave;
