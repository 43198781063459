import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "./en.json";
import translationDeutsch from "./de.json";

const resources = {
  en: {
    translation: translationEnglish,
  },
  de: {
    translation: translationDeutsch,
  },
};

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
});

export default i18next;
