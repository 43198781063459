import ContactUsCard from "./ContactUsCard";
import "./MoreActions.css";
import { useTranslation } from "react-i18next";

const MoreActions = () => {
  const { t } = useTranslation();

  return (
    <div className="moreActionsContainer">
      <ContactUsCard
        title={t("contactUs.moreActions.visitWebiste")}
        cardLink="CodeMosaic.com"
        cardLinkUrl="https://code-mosaic.com/"
        imageUrl={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/image1.png`}
        iconUrl={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/web.svg`}
      />
      <ContactUsCard
        title={t("contactUs.moreActions.sendEmail")}
        cardLink={t("contactUs.moreActions.clickToSend")}
        cardLinkUrl="https://code-mosaic.com/contact/"
        imageUrl={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/image2.png`}
        iconUrl={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/email.svg`}
      />
    </div>
  );
};

export default MoreActions;
