import { create } from "zustand";
import { IInitialSetupOrganizationStore } from "../types";

export const useInitialSetupOrganizationStore =
  create<IInitialSetupOrganizationStore>((set) => ({
    setupCompleted: false,
    hrSystem: null,
    setSetupCompleted: (value: boolean) => set({ setupCompleted: value }),
    setHrSystem: (hrSystem: number) => set({ hrSystem }),
  }));
