import React, { useEffect, useState } from "react";
import Modal from "../../../Modal";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Avatar,
  TableColumnDefinition,
  createTableColumn,
  useArrowNavigationGroup,
  TableColumnId,
  useTableFeatures,
  useTableSort,
  Image,
} from "@fluentui/react-components";
import "./OverlapsModal.css";
import { useTimeOffsStore } from "../../../../store/timeOffsStore";
import { useStore } from "zustand";
import { TimeOff } from "../../../../types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { OverlapsModalType } from "../../../../types";

const OverlapsModal: React.FC<OverlapsModalType> = ({
  isModalOpen,
  closeModal,
  timeOffId,
  getImageForAction,
}) => {
  const { t } = useTranslation();
  const [selectedTimeOff, setSelectedTimeOff] = useState<TimeOff>();
  const [items, setItems] = useState<TimeOff[]>([]);
  const timeOffStore = useStore(useTimeOffsStore);

  useEffect(() => {
    if (timeOffId) {
      const timeOff = timeOffStore.timeOffs.find((to) => to?.Id === timeOffId);
      setSelectedTimeOff(timeOff);
      if (timeOff?.Overlaps) {
        setItems(timeOff?.Overlaps);
      }
    }
  }, [timeOffId]);

  const columns: TableColumnDefinition<TimeOff>[] = [
    createTableColumn<TimeOff>({
      columnId: "EmployeeName",
    }),
    createTableColumn<TimeOff>({
      columnId: "StartDate",
    }),
    createTableColumn<TimeOff>({
      columnId: "Overlaps",
    }),
  ];

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns: columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: {
          sortColumn: "StartDate",
          sortDirection: "ascending",
        },
      }),
    ]
  );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });

  const calculateOverlapDays = (
    timeOffStartDate: Date | undefined,
    timeOffEndDate: Date | undefined,
    overlapStartDate: Date | undefined,
    overlapEndDate: Date | undefined
  ): string => {
    let overlapDuration = 0;
    if (
      timeOffStartDate &&
      timeOffEndDate &&
      overlapStartDate &&
      overlapEndDate
    ) {
      const start1 = moment.utc(timeOffStartDate);
      const start2 = moment.utc(overlapStartDate);
      const end1 = moment.utc(timeOffEndDate);
      const end2 = moment.utc(overlapEndDate);
      const overlapStart = moment.max(start1, start2);
      const overlapEnd = moment.min(end1, end2);
      let currentDate = overlapStart.clone();

      while (currentDate.isSameOrBefore(overlapEnd, "day")) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          overlapDuration++;
        }
        currentDate.add(1, "day");
      }
    }
    if (overlapDuration === 0) return "";
    if (overlapDuration === 1) return `1 ${t("absencesOwnHr.overlaps.day")}`;
    return `${overlapDuration} ${t("absencesOwnHr.overlaps.days")}`;
  };

  return (
    <div className="overlapsModalContainer">
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Targo360"
        subtitle="Absences"
        contentTitle="Overlaps"
        headerStyle={""}
      >
        <div className="overlapsModalContent">
          <Table
            {...keyboardNavAttr}
            role="grid"
            sortable
            aria-label="DataGrid implementation with Table primitives"
            className="overlapsTable"
          >
            <TableHeader className="overlapsHeaderRow">
              <TableRow>
                <TableHeaderCell {...headerSortProps("name")}>
                  {t("absencesOwnHr.overlaps.Name")}
                </TableHeaderCell>
                <TableHeaderCell {...headerSortProps("period")}>
                  {t("absencesOwnHr.overlaps.Period")}
                </TableHeaderCell>
                <TableHeaderCell {...headerSortProps("overlap")}>
                  {t("absencesOwnHr.overlaps.Overlap")}
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell tabIndex={0} role="gridcell">
                    <div className="overlapsTableRow">
                      <Image
                        className="overlapsStatusIcon"
                        src={getImageForAction(item.RequestStatus)}
                        alt="status"
                      />
                      <TableCellLayout
                        media={
                          <Avatar
                            aria-label={item.EmployeeName}
                            name={item.EmployeeName}
                          />
                        }
                      >
                        <div className="overlapsNameContainer">
                          <div className="overlapsLabelSection">
                            {item.EmployeeName}
                          </div>
                          <div className="overlapsDepartmentSection">
                            {item.DepartmentName}
                          </div>
                        </div>
                      </TableCellLayout>
                    </div>
                  </TableCell>
                  <TableCell tabIndex={0} role="gridcell">
                    <TableCellLayout>
                      <div className="overlapsTablePeriodContainer">
                        <div className="overlapsTableLabelSection">
                          {`${moment
                            .utc(item.StartDate)
                            .format("DD/MM/YYYY")} - ${moment
                            .utc(item.EndDate)
                            .format("DD/MM/YYYY")}`}
                        </div>
                        <div className="overlapsTablePeriodDaysContainer">
                          <span className="overlapsTablePeriodDays">
                            {item?.DaysNumber}{" "}
                            {item?.DaysNumber === 1
                              ? t("absencesOwnHr.overlaps.day")
                              : t("absencesOwnHr.overlaps.days")}
                          </span>
                        </div>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell tabIndex={0} role="gridcell">
                    <TableCellLayout>
                      <div className="overlapsTableOverlapSection">
                        <div className="overlapsTableLabelSection">
                          {calculateOverlapDays(
                            selectedTimeOff?.StartDate,
                            selectedTimeOff?.EndDate,
                            item?.StartDate,
                            item?.EndDate
                          )}
                        </div>
                        <Image
                          className="overlapIcon"
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/info.svg`}
                          alt="info"
                        />
                      </div>
                    </TableCellLayout>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </div>
  );
};

export default OverlapsModal;
