import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  Divider,
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Image,
  Spinner,
  useTableSort,
  TableColumnId,
  useTableSelection,
  TableSelectionCell,
  Avatar,
  TableRowId,
} from "@fluentui/react-components";
import "./ManageUsers.css";
import { useEffect, useState } from "react";
import { User } from "../../types";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { useSettingsStore } from "../../store/settingsStore";
import settingsService from "../../services/settings";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import Error from "../Error";
import Pagination from "../../components/InitialSetupWizard/Pagination";
import { RoleSelector } from "../../components/Settings/ManageUsers/RoleSelector";
import { DeleteDialog } from "../../components/Settings/ManageUsers/DeleteDialog";
import { useUserStore } from "../../store/userStore";
import Filters from "../../components/Settings/Filters";
import helpers from "../../helpers/helpers";

const columnSizingOptions = {
  Name: {
    minWidth: 240,
    defaultWidth: 240,
  },
  JobTitle: {
    minWidth: 300,
    defaultWidth: 300,
  },
  Status: {
    minWidth: 80,
    defaultWidth: 80,
  },
  Roles: {
    minWidth: 150,
    defaultWidth: 150,
  },
};

export default function ManageUsersSettings() {
  const { t } = useTranslation();
  const columnsDef: TableColumnDefinition<User>[] = [
    createTableColumn<User>({
      columnId: "Name",
      compare: (a, b) => {
        if (!a.FullName && !b.FullName) {
          return 0;
        } else if (!a.FullName) {
          return 1;
        } else if (!b.FullName) {
          return -1;
        } else {
          return a.FullName.trim().localeCompare(b.FullName.trim());
        }
      },
      renderHeaderCell: () => <>{t("settings.manageUsers.name")}</>,
    }),
    createTableColumn<User>({
      columnId: "JobTitle",
      compare: (a, b) => {
        if (!a.JobTitle && !b.JobTitle) {
          return 0;
        } else if (!a.JobTitle) {
          return 1;
        } else if (!b.JobTitle) {
          return -1;
        } else {
          return a.JobTitle.localeCompare(b.JobTitle);
        }
      },
      renderHeaderCell: () => <>{t("settings.manageUsers.position")}</>,
    }),
    createTableColumn<User>({
      columnId: "Status",
      compare: (a, b) => {
        if (!a.Status && !b.Status) {
          return 0;
        } else if (!a.Status) {
          return 1;
        } else if (!b.Status) {
          return -1;
        } else {
          return a.Status.localeCompare(b.Status);
        }
      },
      renderHeaderCell: () => <>{t("settings.manageUsers.status")}</>,
    }),
    createTableColumn<User>({
      columnId: "RoleId",
      renderHeaderCell: () => <>{t("settings.manageUsers.roles")}</>,
    }),
  ];
  const [seats, setSeats] = useState("0");
  const [columns] = useState<TableColumnDefinition<User>[]>(columnsDef);
  const [isAddingUsers, setIsAddingUsers] = useState<boolean>(false);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const settingsStore = useStore(useSettingsStore);
  const [items, setItems] = useState<User[]>(settingsStore?.users);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState(
    () => new Set<TableRowId>([])
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const userStore = useStore(useUserStore);
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [nameFilters, setNameFilters] = useState<string[]>([]);
  const [departmentFilters, setDepartmentFilters] = useState<string[]>([]);
  const [positionFilters, setPositionFilters] = useState<string[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [roleFilters, setRoleFilters] = useState<string[]>([]);
  const [customRoleFilters, setCustomRoleFilters] = useState<string[]>([]);
  const [selectedNameFilters, setSelectedNameFilters] = useState<string[]>([]);
  const [selectedDepartmentFilters, setSelectedDepartmentFilters] = useState<
    string[]
  >([]);
  const [selectedPositionFilters, setSelectedPositionFilters] = useState<
    string[]
  >([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>(
    []
  );
  const [selectedRoleFilters, setSelectedRoleFilters] = useState<string[]>([]);
  const [selectedCustomRoleFilters, setSelectedCustomRoleFilters] = useState<
    string[]
  >([]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      if (isOpenFilters) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [isOpenFilters]);

  useEffect(() => {
    let tempNameFilters: string[] = [];
    let tempDepartmentFilters: string[] = [];
    let tempPositionFilters: string[] = [];
    let tempStatusFilters: string[] = [];
    let tempRoleFilters: string[] = [];
    let tempCustomRoleFilters: string[] = [];
    settingsStore?.users.forEach((item) => {
      if (item?.FullName && !tempNameFilters.includes(item?.FullName)) {
        tempNameFilters.push(item?.FullName);
      }
      if (
        item?.Department?.Name &&
        !tempDepartmentFilters.includes(item?.Department?.Name)
      ) {
        tempDepartmentFilters.push(item?.Department?.Name);
      }
      if (item?.JobTitle && !tempPositionFilters.includes(item?.JobTitle)) {
        tempPositionFilters.push(item?.JobTitle);
      }
      if (
        item?.Status &&
        !tempStatusFilters.includes(
          item?.Status[0].toUpperCase() + item?.Status.slice(1)
        )
      ) {
        tempStatusFilters.push(
          item?.Status[0].toUpperCase() + item?.Status.slice(1)
        );
      }
      if (item?.Role && item?.Role?.length > 0) {
        item?.Role?.forEach((role) => {
          if (role?.Type === "builtIn") {
            if (!tempRoleFilters.includes(role?.RoleName)) {
              tempRoleFilters.push(role?.RoleName);
            }
          } else if (role?.Type === "custom") {
            if (!tempCustomRoleFilters.includes(role?.RoleName)) {
              tempCustomRoleFilters.push(role?.RoleName);
            }
          }
        });
      }
    });
    setNameFilters(tempNameFilters);
    setDepartmentFilters(tempDepartmentFilters);
    setPositionFilters(tempPositionFilters);
    setStatusFilters(tempStatusFilters);
    setRoleFilters(tempRoleFilters);
    setCustomRoleFilters(tempCustomRoleFilters);
  }, [settingsStore?.users]);

  useEffect(() => {
    if (!isOpenFilters) {
      let filteredItems = settingsStore?.users;
      if (searchValue !== "") {
        filteredItems = filteredItems.filter(
          (item) =>
            item?.FullName?.toLowerCase().includes(
              searchValue?.toLowerCase()
            ) ||
            item?.Department?.Name?.toLowerCase().includes(
              searchValue?.toLowerCase()
            ) ||
            item?.JobTitle?.toLowerCase().includes(
              searchValue?.toLowerCase()
            ) ||
            item?.Status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item?.Role?.some((el) =>
              el?.RoleName?.toLowerCase().includes(searchValue?.toLowerCase())
            )
        );
      }
      if (selectedNameFilters.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          if (item?.FullName)
            return selectedNameFilters.includes(item?.FullName);
          return false;
        });
      }
      if (selectedDepartmentFilters.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          if (item?.Department?.Name)
            return selectedDepartmentFilters.includes(item?.Department?.Name);
          return false;
        });
      }
      if (selectedPositionFilters.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          if (item?.JobTitle)
            return selectedPositionFilters.includes(item?.JobTitle);
          return false;
        });
      }
      if (selectedStatusFilters.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          if (item?.Status)
            return selectedStatusFilters.includes(
              item?.Status[0].toUpperCase() + item?.Status.slice(1)
            );
          return false;
        });
      }
      if (selectedRoleFilters.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          if (item?.Role?.length === 0) return false;
          let checkRole = false;
          item?.Role?.forEach((role) => {
            if (selectedRoleFilters.includes(role?.RoleName)) checkRole = true;
          });
          return checkRole;
        });
      }
      if (selectedCustomRoleFilters.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          if (item?.Role?.length === 0) return false;
          let checkRole = false;
          item?.Role?.forEach((role) => {
            if (selectedCustomRoleFilters.includes(role?.RoleName))
              checkRole = true;
          });
          return checkRole;
        });
      }
      setItems(filteredItems);
      setCurrentPage(1);
    }
  }, [
    isOpenFilters,
    selectedNameFilters,
    selectedDepartmentFilters,
    selectedPositionFilters,
    selectedStatusFilters,
    selectedRoleFilters,
    selectedCustomRoleFilters,
    searchValue,
    settingsStore?.users,
  ]);

  const clearFilters = () => {
    setSelectedNameFilters([]);
    setSelectedDepartmentFilters([]);
    setSelectedPositionFilters([]);
    setSelectedStatusFilters([]);
    setSelectedRoleFilters([]);
    setSelectedCustomRoleFilters([]);
  };

  const getUsersFromAad = async () => {
    setIsAddingUsers(true);
    try {
      const result = await settingsService.addUsersFromAad();
      if ("status" in result && result?.status === "error") {
        toast.error(t(`settings.manageUsers.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else if ("users" in result) {
        settingsStore.setUsers(result?.users);
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("settings.manageUsers.errors.addUsersFromAad"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setIsAddingUsers(false);
    }
  };

  const syncUsers = async () => {
    setIsSyncing(true);
    try {
      const result = await settingsService.syncUsersWithAad();
      if (
        "status" in result &&
        result?.status === "error" &&
        "message" in result
      ) {
        toast.error(t(`settings.manageUsers.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else if ("users" in result) {
        settingsStore.setUsers(result?.users as User[]);
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      }
    } catch {
      toast.error(t("settings.manageUsers.errors.sync"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setIsSyncing(false);
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    let seats = 0;
    settingsStore.users.forEach((user) => {
      user?.Role?.forEach((userRole) => {
        if (userRole?.Type === "builtIn") seats += 1;
      });
    });
    setSeats(seats.toString());
  }, [settingsStore.users]);

  const getUsers = async () => {
    const result = await settingsService.getUsers();
    if ("users" in result) {
      settingsStore.setUsers(result?.users);
    }
    if ("allRoles" in result) {
      settingsStore.setAllRoles(result?.allRoles);
    }
    return result;
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["manageUsersData"],
    queryFn: getUsers,
  });

  const {
    columnSizing_unstable,
    tableRef,
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSelection({
        selectionMode: "multiselect",
        selectedItems: selectedRows,
        onSelectionChange: (event, data) => {
          setSelectedRows(data?.selectedItems);
          setSelectedItems(
            Array.from(data.selectedItems).map(
              (rowId) => items[rowId as number]?.Id
            )
          );
        },
      }),
      useTableSort({
        defaultSortState: { sortColumn: "Name", sortDirection: "ascending" },
      }),
    ]
  );

  const deleteUsers = async () => {
    try {
      const result = await settingsService.deleteUsers(selectedItems);
      if ("status" in result && result?.status === "error") {
        toast.error(t(`settings.manageUsers.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else {
        settingsStore.deleteUsersById(selectedItems);
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("settings.manageUsers.errors.delete"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSelectedItems([]);
      setSelectedRows(() => new Set<TableRowId>([]));
      setIsDialogOpen(false);
    }
  };

  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error)
    return (
      <Error message={t("settings.rolesAndPermissions.errors.generalError")} />
    );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event?.target.value);
  };

  const currentPageRows = sort(
    getRows((row) => {
      const selected = isRowSelected(row.rowId);
      return {
        ...row,
        onClick: (e: React.MouseEvent) => {
          toggleRow(e, row.rowId);
        },
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === " ") {
            e.preventDefault();
            toggleRow(e, row.rowId);
          }
        },
        selected,
        appearance: selected ? ("brand" as const) : ("none" as const),
      };
    })
  );

  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  return (
    <>
      {helpers.checkHasPermissions(
        userStore?.user?.Permissions,
        "Manage Users",
        "Can Read"
      ) ? (
        <div className="usersSettingsPage">
          <div className="usersSettingsInfoContainer">
            <div className="title">{t("settings.manageUsers.title")}</div>
            <div className="detailsContainer">
              <span className="details">
                {t("settings.manageUsers.description")}
              </span>
            </div>
          </div>
          <Divider appearance="strong" />
          <div className="usersSettingsInfoContainer">
            <div className="usersTotalSeatsContainer">
              <div className="usersSeatsTotalLabel">
                {t("settings.manageUsers.seatsTotal")}
              </div>
              <Input disabled className="usersSeatsTotalInput" value={seats} />
            </div>
            <div className="usersActionsContainer">
              <Button
                type="button"
                className="usersAddUsersButton"
                appearance="primary"
                disabled={
                  isAddingUsers ||
                  !helpers.checkHasPermissions(
                    userStore?.user?.Permissions,
                    "Manage Users",
                    "Can Edit"
                  )
                }
                onClick={getUsersFromAad}
              >
                {isAddingUsers ? (
                  <Spinner size="extra-tiny" />
                ) : (
                  <>
                    <Image
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/plus-icon.svg`}
                      alt="plus-icon"
                    />
                    {t("settings.manageUsers.addUsersFromAad")}
                  </>
                )}
              </Button>
              <Button
                type="button"
                className="usersSyncNowButton"
                disabled={
                  isSyncing ||
                  !helpers.checkHasPermissions(
                    userStore?.user?.Permissions,
                    "Manage Users",
                    "Can Edit"
                  )
                }
                onClick={syncUsers}
              >
                {isSyncing ? <Spinner size="extra-tiny" /> : null}
                {t("settings.manageUsers.syncNow")}
              </Button>
            </div>
            <div className="userTableActionsContainer">
              <div className="usersFilterSectionActions">
                <div
                  onClick={() => setIsOpenFilters(true)}
                  className="usersFilterSection"
                >
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/filter.svg`}
                    alt="filter"
                  />
                  <span className="usersFilterSectionText">
                    {t("settings.manageUsers.filter")}
                  </span>
                </div>
                <Input
                  className="usersSearchInput"
                  contentAfter={
                    <Image
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/search.svg`}
                      alt="search"
                    />
                  }
                  placeholder={t("settings.manageUsers.find")}
                  value={searchValue}
                  onChange={handleSearchValueChange}
                />
              </div>
              <div className="usersFilterSectionActionButtons">
                {helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Manage Users",
                  "Can Delete"
                ) ? (
                  <Button
                    type="button"
                    className="usersAddUsersButton"
                    appearance="primary"
                    style={{
                      minWidth: "96px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                    disabled={
                      selectedItems.length === 0 ||
                      !helpers.checkHasPermissions(
                        userStore?.user?.Permissions,
                        "Manage Users",
                        "Can Delete"
                      )
                    }
                    onClick={() => setIsDialogOpen(true)}
                  >
                    <Image
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/trashcan.svg`}
                      alt="search"
                    />
                    {t("settings.manageUsers.delete")}
                  </Button>
                ) : null}
              </div>
            </div>
            {isAddingUsers || isSyncing ? (
              <div style={{ marginTop: "100px" }}>
                <Spinner />
              </div>
            ) : (
              <div className="usersTableContainer">
                <Table
                  ref={tableRef}
                  sortable
                  {...columnSizing_unstable.getTableProps()}
                  noNativeElements={true}
                  className="usersTable"
                >
                  <TableHeader className="usersTableHeaderRow">
                    <TableRow>
                      <TableSelectionCell
                        checked={
                          allRowsSelected
                            ? true
                            : someRowsSelected
                            ? "mixed"
                            : false
                        }
                        aria-checked={
                          allRowsSelected
                            ? true
                            : someRowsSelected
                            ? "mixed"
                            : false
                        }
                        role="checkbox"
                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                        onClick={toggleAllRows}
                      ></TableSelectionCell>
                      {columns.map((column) => (
                        <Menu openOnContext key={column.columnId}>
                          <MenuTrigger>
                            <TableHeaderCell
                              key={column.columnId}
                              {...headerSortProps(column.columnId)}
                              {...columnSizing_unstable.getTableHeaderCellProps(
                                column.columnId
                              )}
                            >
                              {column.renderHeaderCell()}
                            </TableHeaderCell>
                          </MenuTrigger>
                          <MenuPopover>
                            <MenuList>
                              <MenuItem
                                onClick={columnSizing_unstable.enableKeyboardMode(
                                  column.columnId
                                )}
                              >
                                Keyboard Column Resizing
                              </MenuItem>
                            </MenuList>
                          </MenuPopover>
                        </Menu>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {getCurrentPageRows().map((item) => (
                      <TableRow
                        key={item.item.Id}
                        onKeyDown={item.onKeyDown}
                        aria-selected={item.selected}
                        appearance={item.appearance}
                      >
                        <TableSelectionCell
                          role="gridcell"
                          aria-selected={item.selected}
                          checked={item.selected}
                          checkboxIndicator={{ "aria-label": "Select row" }}
                          onClick={item.onClick}
                        />
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps("Name")}
                        >
                          <TableCellLayout
                            truncate
                            media={
                              <Avatar
                                aria-label={item.item.FullName}
                                name={item.item.FullName}
                              />
                            }
                          >
                            <div className="usersNameContainer">
                              <div className="usersNameLabel">
                                {item.item.FullName}
                              </div>
                              <div className="usersDepartmentLabel">
                                {item.item?.Department?.Name}
                              </div>
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "JobTitle"
                          )}
                        >
                          <TableCellLayout truncate>
                            {item.item.JobTitle}
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps("Status")}
                        >
                          <TableCellLayout truncate>
                            {item.item.Status
                              ? item.item.Status[0].toUpperCase() +
                                item.item.Status.slice(1)
                              : null}
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          tabIndex={0}
                          role="gridcell"
                          {...columnSizing_unstable.getTableCellProps("Roles")}
                        >
                          <RoleSelector item={item.item} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
          <Filters
            isOpen={isOpenFilters}
            onClose={() => setIsOpenFilters(false)}
            nameFilters={nameFilters}
            departmentFilters={departmentFilters}
            statusFilters={statusFilters}
            positionFilters={positionFilters}
            roleFilters={roleFilters}
            customRoleFilters={customRoleFilters}
            selectedNameFilters={selectedNameFilters}
            setSelectedNameFilters={setSelectedNameFilters}
            selectedDepartmentFilters={selectedDepartmentFilters}
            setSelectedDepartmentFilters={setSelectedDepartmentFilters}
            selectedPositionFilters={selectedPositionFilters}
            setSelectedPositionFilters={setSelectedPositionFilters}
            selectedStatusFilters={selectedStatusFilters}
            setSelectedStatusFilters={setSelectedStatusFilters}
            selectedRoleFilters={selectedRoleFilters}
            setSelectedRoleFilters={setSelectedRoleFilters}
            selectedCustomRoleFilters={selectedCustomRoleFilters}
            setSelectedCustomRoleFilters={setSelectedCustomRoleFilters}
            clearFilters={clearFilters}
          />
          <DeleteDialog
            open={isDialogOpen}
            closeDialog={() => setIsDialogOpen(false)}
            deleteUsers={deleteUsers}
          />
        </div>
      ) : (
        <Error message={t("settings.general.notAuthorized")} />
      )}
    </>
  );
}
