import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <div className="ppPage">
      <h1 className="ppTitle">Privacy Policy for Targo360</h1>
      <div className="ppSection">
        <h2 className="ppSectionHeader">1. Introduction</h2>
        <p>
          Targo360 is committed to protecting the privacy of its users. As a
          comprehensive solution enhancing HR capabilities within platforms like
          Microsoft Teams, we recognize the importance of maintaining the
          confidentiality and security of your data. This privacy policy
          outlines the nature of the data we collect, how it's processed,
          stored, and shared, and the rights and choices available to you.
        </p>
        <p>
          By choosing to use Targo360, you are giving your consent for us to handle
          your data as detailed in this policy. Your trust is vital to us, and
          we're committed to being transparent and upfront about our practices.
        </p>
        <p>For clarity:</p>
        <ul>
          <li>
            Personal Information: Refers to data that can directly or indirectly
            identify you. This may include, but is not limited to, your name,
            email address, phone number, and other identifiable details.
          </li>
          <li>
            Non-Personal Information: Relates to data that does not identify you
            on its own. This encompasses metrics such as device type, usage
            patterns, and aggregated user behaviors.
          </li>
        </ul>
        <p>
          Both categories of information are treated with utmost care, ensuring
          alignment with best practices and regulatory standards. We encourage
          you to peruse this policy thoroughly. Should any queries or concerns
          arise, please don't hesitate to reach out using the contact details
          provided at the end of this document.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">2. What we collect</h2>
        <p>
          We may collect personal information from users, such as name, email
          address, and phone number, when they create an account. We may also
          collect non-personal information, such as device information and usage
          data.
        </p>
        <p>
          This information is necessary to create and manage user accounts and
          provide access to the app's features.
        </p>
        <p>
          We may also collect additional personal information, such as job title
          and company name, to personalize the user experience and provide
          targeted marketing. This information is optional, and users may choose
          not to provide it.
        </p>
        <p>
          In addition to personal information, we may collect non-personal
          information, such as device information and usage data. This
          information is collected automatically through cookies and other
          tracking technologies and is used to monitor app performance and
          improve the user experience.
        </p>
        <p>
          Examples of non-personal information we may collect include the date
          and time the app is accessed, the type of device used, and the pages
          visited within the app.
        </p>
        <p>
          Users are not required to provide personal information beyond what is
          necessary to create and manage their accounts. However, providing
          additional personal information may enhance the user experience and
          provide access to additional app features.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">3. How we use the information</h2>
        <p>
          Our commitment at Targo360 is to provide actionable insights to
          organizations while respecting the privacy and rights of individual
          employees. All data captured is treated with the utmost
          confidentiality, and we implement strict access controls to prevent
          unauthorized access.
        </p>
        <p>
          We use the information collected to provide and improve the app,
          including customer support, troubleshooting, and targeted marketing.
          Personal information such as name, email address, and phone number may
          also be used to personalize the user experience and send marketing
          materials.
        </p>
        <p>
          We may analyze app usage and collect non-personal information such as
          device information and usage data to improve app performance, create
          aggregated usage statistics, and develop targeted marketing campaigns.
          This information may also be used to personalize the user experience,
          such as by suggesting relevant content or features.
        </p>
        <p>
          We take appropriate measures to safeguard user information and prevent
          unauthorized access, use, or disclosure. This includes using
          encryption, firewalls, and access controls. We also limit access to
          user information to those employees who need it to perform their job
          duties.
        </p>
        <p>
          Users who have opted in to receive marketing materials may receive
          periodic emails or other communications about new app features,
          promotions, or other information that may be of interest. Users can
          opt out of receiving these messages at any time by following the
          unsubscribe instructions included in each communication.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">4. Third-party sharing</h2>
        <p>
          We may share user information with third-party service providers to
          assist us in providing and improving the app. For example, we may
          share payment information with a payment processor to process
          subscription payments, or email addresses with an email marketing
          service to send marketing messages. We take appropriate steps to
          ensure that our third-party service providers handle user information
          in a way that is consistent with this privacy policy.
        </p>
        <p>
          In addition, we may need to share user information with law
          enforcement or other third parties in response to a legal request,
          such as a subpoena or court order. We will only share information in
          response to a lawful request and will notify affected users as
          required by law.
        </p>
        <p>
          We take appropriate measures to safeguard user information and prevent
          unauthorized access, use, or disclosure by third-party service
          providers. We also limit the amount of user information we share and
          ensure that third-party service providers comply with contractual
          obligations or industry-standard security practices.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">
          5. Data Exchange with Third-party Platforms
        </h2>
        <p>
          When Targo360 integrates with Graph API, we may access and synchronize
          the following data:
        </p>
        <ul>
          <li>
            Employee profiles: Names, job titles, gender and contact details.
          </li>
        </ul>
        <p>
          Please Note: The actual data synchronized can vary based on user
          permissions and settings within both Targo360 and the third-party
          platform. We ensure that no data is accessed without explicit user
          consent.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">6. Cookies and Trackers</h2>
        <p>
          In the Microsoft Teams environment, our app employs essential
          authentication mechanisms to facilitate secure user interactions and
          provide necessary functionalities.
        </p>
        <ul>
          <li>Types of Tracking Used:</li>
        </ul>
        <p>
          JWT Token Authentication: We use JSON Web Token (JWT) to authenticate
          requests from our Teams app to our API. This token remains active
          during your session to ensure seamless communication between Microsoft
          Teams and our app.
        </p>
        <ul>
          <li>No Persistent or Third-Party Trackers:</li>
        </ul>
        <p>
          We do not use persistent trackers that remain active after Microsoft
          Teams is closed.
        </p>
        <p>
          Our app does not integrate third-party trackers for the purpose of
          collecting usage statistics or analytics.
        </p>
        <ul>
          <li>
            Security: The JWT Token plays an essential role in the security of
            our application by enabling secure, authenticated transactions
            between your Microsoft Teams client and our API.
          </li>
          <li>Controlling and Opting Out:</li>
        </ul>
        <p>
          Since our app operates within Microsoft Teams, permissions and data
          access can generally be controlled through your Microsoft Teams
          settings. We do not provide additional settings to opt-out of tracking
          technologies because we do not employ third-party or persistent
          trackers in our app. If you have questions or concerns about the data
          being collected, please consult the Microsoft Teams privacy settings
          or contact our support team.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">7. Opt-out options</h2>
        <p>
          Users may opt-out of certain data collection or sharing practices by
          contacting us directly. Specifically, users can opt-out of receiving
          targeted marketing messages by adjusting their preferences in the app.
          Users can also opt-out of certain data sharing practices by contacting
          our support team directly.
        </p>
        <p>
          Please note that opting out of certain data collection or sharing
          practices may affect the functionality or performance of the app. For
          example, opting out of certain data sharing practices may limit the
          ability of our third-party service providers to assist us in providing
          and improving the app.
        </p>
        <p>
          Please also note that it may take some time for opt-out requests to
          take effect, and that we may need to retain certain information in
          accordance with our legal obligations or legitimate business
          interests.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">8. Security measures</h2>
        <p>
          The safety and integrity of user data are of utmost importance to us.
          To protect against unauthorized access, use, or disclosure, we have
          implemented a robust set of security technologies and practices.
        </p>
        <h3 className="ppSectionSubheader">Types of Security Measures:</h3>
        <ul>
          <li>
            JWT Bearer Token: We utilize JWT Bearer Tokens for secure user
            authentication, providing a reliable method for authorizing
            transactions and ensuring secure communications between Microsoft
            Teams and our app's API.
          </li>
          <li>
            Encrypted Database: All user data stored in our database is
            encrypted to add an extra layer of protection against unauthorized
            access.
          </li>
          <li>
            Azure Private Endpoints: We use Azure private endpoints to securely
            isolate your data within the Azure platform, providing a more secure
            link between you and our services.
          </li>
          <li>
            Virtual Network & Azure VPN: Our services operate within a secure
            virtual network accessed only through Azure VPN, thereby reducing
            exposure to public internet threats.
          </li>
          <li>
            Bastion: Azure Bastion provides seamless RDP and SSH connectivity to
            our virtual machines, minimizing exposure to public network traffic.
          </li>
          <li>
            Private DNS: We use private DNS for secure and reliable name
            resolution, ensuring that internal network traffic remains
            confidential.
          </li>
          <li>
            Azure Monitor: Azure Monitor provides full-stack monitoring,
            advanced analytics, and intelligent insights to ensure our services
            are performing optimally and securely.
          </li>
        </ul>
        <h3 className="ppSectionSubheader">Ongoing Security Practices:</h3>
        <p>
          Regular Security Reviews: We continually review and update our
          security measures to adapt to the constantly evolving threat
          landscape.
        </p>
        <h3 className="ppSectionSubheader">Data Access Controls:</h3>
        <p>
          Access to sensitive user data is strictly controlled and is limited to
          authorized personnel with a specific need for that information.
        </p>
        <p>
          While we make considerable efforts to ensure the security of your
          data, please note that no security measures can guarantee absolute
          protection. We encourage you to take proactive measures to safeguard
          your information, such as using robust passwords and refraining from
          sharing login credentials.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">9. Data Breach Procedures</h2>
        <p>
          We prioritize the security and confidentiality of our users' data.
          Despite our security measures, we recognize the importance of being
          prepared for unforeseen circumstances.
        </p>
        <p>Procedures in the Event of a Data Breach:</p>
        <ul>
          <li>
            Immediate Investigation: Upon discovery or notification of a
            potential breach, our security team will immediately initiate an
            investigation to determine the extent and cause of the breach.
          </li>
          <li>
            Containment: We will act swiftly to contain and mitigate the effects
            of the breach, ensuring no further data is compromised.
          </li>
          <li>
            External Consultation: If necessary, we will consult with
            cybersecurity experts to better understand the breach's nature and
            to fortify our defenses against similar incidents in the future.
          </li>
          <li>
            Legal and Regulatory Reporting: We will comply with all local and
            international data protection regulations, reporting the breach to
            the necessary authorities within the stipulated timeframes.
          </li>
          <li>
            Review and Prevention: Post-incident, we will conduct a thorough
            review of the event, improving our security measures and protocols
            based on our findings.
          </li>
        </ul>
        <p>Notification to Affected Users:</p>
        <ul>
          <li>
            Timely Communication: In the unfortunate event of a data breach that
            compromises user data, we are committed to notifying affected users
            as soon as possible. Depending on the nature and scale of the
            breach, this could be within 72 hours of becoming aware of the
            incident.
          </li>
          <li>
            Details of the Breach: Our notification will provide clear
            information about what data has been compromised, the potential
            implications, and what we're doing in response.
          </li>
          <li>
            Recommended Actions: We will provide users with guidance on steps
            they can take to protect themselves, which might include changing
            passwords or monitoring accounts for suspicious activity.
          </li>
          <li>
            Continuous Updates: As we work to resolve the issue, we will keep
            users informed of our progress and any additional measures they
            should take.
          </li>
        </ul>
        <p>
          We deeply value the trust our users place in us and are committed to
          acting responsibly and transparently in the face of any challenges.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">10. Links to Other Websites/Apps</h2>
        <p>
          Targo360 may contain links to other websites, platforms, or applications.
          Please be aware that we are not responsible for the privacy practices
          or content of such external sites or applications.
        </p>
        <p>
          When you leave our application or click on a link that directs you to
          an external site or application, you will be subject to that site's or
          application's privacy policies and terms of use. Our Privacy Policy
          only governs information collected within Targo360.
        </p>
        <p>
          We encourage users to be cautious when they leave our application and
          to carefully read the privacy policies of any other site or
          application that collects personal information. It's crucial to
          understand how these third-party platforms handle your data.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">11. Contact information</h2>
        <p>
          If you have any questions or concerns about your privacy, please
          contact us using one of the following methods:
        </p>
        <p>Email: info@code-mosaic.com</p>
        <p>Physical address: Bulevardul 15 Noiembrie 44, Brașov, Romania</p>
        <p>
          We are happy to assist you with inquiries related to your personal
          information, such as what information we collect, how we use it, and
          how we share it with third parties. Please note that we may require
          additional information from you in order to process your inquiry.
        </p>
        <p>
          We strive to respond to all privacy-related inquiries within 48 hours
          of receipt. If we are unable to respond within that time frame, we
          will provide you with an estimated timeline for our response.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">
          12. Compliance with app store requirements
        </h2>
        <p>
          This privacy policy complies with the requirements of the app store on
          which our app is available.
        </p>
        <p>
          Our privacy policy complies with the Microsoft Store Policies, which
          include the Microsoft Store Principles, Microsoft Store Certification
          Requirements, and Microsoft Store Policies.
        </p>
        <p>
          Specifically, our app and privacy policy comply with the following
          requirements:
        </p>
        <p>
          Data collection and storage: We only collect and store user data that
          is necessary for providing and improving our app. We do not collect or
          store any data that is not necessary for app functionality.
        </p>
        <p>
          Data use and sharing: We only use and share user data for purposes
          that are directly related to app functionality, such as
          troubleshooting, customer support, and marketing. We do not use or
          share user data for any unrelated purposes.
        </p>
        <p>
          Data retention and deletion: We retain user data for as long as it is
          necessary to provide app functionality and comply with legal
          requirements. We delete user data when it is no longer necessary for
          these purposes.
        </p>
        <p>
          If you have any questions or concerns about our compliance with
          Microsoft App Store requirements, please contact us using the
          information provided in the "Contact information" section of this
          policy.
        </p>
      </div>
      <div className="ppSection">
        <h2 className="ppSectionHeader">13. Changes to the privacy policy</h2>
        <p>
          We may update this privacy policy from time to time to reflect changes
          in how user data is collected and used. We will notify users of any
          material changes to this policy by posting the updated policy on our
          website and, if applicable, within the app. We encourage users to
          review the privacy policy regularly to stay informed about our data
          collection and use practices.
        </p>
        <p>
          By continuing to use the app after any changes to this privacy policy,
          you agree to the updated terms.
        </p>
      </div>
    </div>
  );
}
