import React from "react";
import "./Footer.css";
import { Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useInitialSetupAdminsStore } from "../../store/initialSetupAdminsStore";
import { useInitialSetupOrganizationStore } from "../../store/initialSetupOrganizationStore";

interface FooterProps {
  onNext: () => void;
  onSkip: () => void;
  onCancel: () => void;
  onBack: () => void;
  currentStep: any;
  isNextDisabled: boolean;
}

const Footer: React.FC<FooterProps> = ({
  onNext,
  onCancel,
  onBack,
  onSkip,
  currentStep,
  isNextDisabled,
}) => {
  const { t } = useTranslation();
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  return (
    <div className="footer-container">
      {currentStep === 1 && (
        <Button className="cancelButton" onClick={onCancel}>
          {t("initialSetup.footer.cancel")}
        </Button>
      )}

      {currentStep > 1 && (
        <Button className="backButton" onClick={onBack}>
          {t("initialSetup.footer.back")}
        </Button>
      )}

      <div className="rightButtons">
        {currentStep === 2 || currentStep === 3 ? (
          <Button
            className="skipButton"
            onClick={onSkip}
            disabled={currentStep === 1}
          >
            {t("initialSetup.footer.skip")}
          </Button>
        ) : null}
        {currentStep === 1 ||
        currentStep === 2 ||
        currentStep === 3 ||
        (currentStep === 4 &&
          initialSetupOrganizationStore?.hrSystem &&
          initialSetupOrganizationStore?.hrSystem === 2) ? (
          <Button
            appearance="primary"
            className="nextButton"
            onClick={onNext}
            disabled={isNextDisabled}
          >
            {t("initialSetup.footer.next")}
          </Button>
        ) : (
          <Button appearance="primary" className="nextButton" onClick={onNext}>
            {t("initialSetup.footer.finish")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
