import "./Support.css";
import { useTranslation } from "react-i18next";

export default function Support() {
  const { t } = useTranslation();

  return (
    <div className="supportContainer">
      <div className="supportHeader">
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.53039 2.00822C4.22083 0.808086 5.51604 0 7 0C9.20914 0 11 1.79086 11 4C11 6.20914 9.20914 8 7 8C5.40519 8 4.02836 7.06667 3.38595 5.71642C3.13809 5.89743 2.83262 6.00427 2.5022 6.00427H2.0022V6.25891C2.0022 6.44889 2.07392 6.62212 2.19176 6.75301C2.32913 6.59883 2.52931 6.50171 2.7522 6.50171C3.16641 6.50171 3.5022 6.83711 3.5022 7.25085C3.5022 7.6646 3.16641 8 2.7522 8C2.7278 8 2.70368 7.99884 2.67989 7.99656C1.74788 7.96442 1.0022 7.19875 1.0022 6.25891L1.0022 2.50744C1.0022 2.2313 1.22606 2.00744 1.5022 2.00744L3.5022 2.00744C3.51166 2.00744 3.52106 2.0077 3.53039 2.00822ZM2.0022 5.00427H2.5022C2.77834 5.00427 3.0022 4.78042 3.0022 4.50427V4.13386C3.00074 4.08942 3 4.04479 3 4C3 3.95521 3.00074 3.91058 3.0022 3.86614V3.00744L2.0022 3.00744L2.0022 5.00427ZM0 11C0 9.88687 0.903151 9 2.00873 9L12 9.00001C13.1045 9.00001 14 9.89557 14 11C14 12.6912 13.1672 13.9663 11.865 14.7966C10.583 15.614 8.85474 16 7 16C5.14526 16 3.41697 15.614 2.13499 14.7966C0.83281 13.9663 0 12.6912 0 11Z"
            fill="#212121"
          />
        </svg>

        <p className="supportTitle">{t("initialSetup.support.supportHelp")}</p>
      </div>

      <h2 className="needHelpTitle">{t("initialSetup.support.needHelp")}</h2>
      <p className="supportInfo">{t("initialSetup.support.contactInfo")}</p>

      <div className="infoDetails">
        <div className="info">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 8.608V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V8.608L11.652 13.6644C11.87 13.7785 12.13 13.7785 12.348 13.6644L22 8.608ZM5.25 4H18.75C20.4347 4 21.8201 5.28191 21.9838 6.92355L12 12.1533L2.01619 6.92355C2.17386 5.34271 3.46432 4.09545 5.06409 4.00523L5.25 4H18.75H5.25Z"
              fill="#212121"
            />
          </svg>

          <a href={`mailto:${"support@code-mosaic.com"}`}>support@code-mosaic.com</a>
        </div>
      </div>
    </div>
  );
}
