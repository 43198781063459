import {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "zustand";
import { useAttendancesStore } from "../store/attendancesStore";
import { TabValue } from "@fluentui/react-components";
import { useTimeOffsStore } from "../store/timeOffsStore";
import { useEmployeeStore } from "../store/ownEmployeeManagementStore";

type TFiltersSection = {
  nameFilters?: string[];
  supervisorFilters?: string[];
  departmentFilters?: string[];
  locationFilters?: string[];
  typeFilters?: string[];
  timeOffTypeFilters?: string[];
  timeOffStatusFilters?: string[];
  positionFilters?: string[];
  contractTypeFilters?: string[];
  statusFilters?: string[];
  worksiteFilters?: string[];
};
type FiltersContextData = {
  filtersSections: TFiltersSection | null;
  isSelectingFilters: boolean;
  selectedNameFilters: string[];
  selectedSupervisorFilters: string[];
  selectedDepartmentFilters: string[];
  selectedTypeFilters: string[];
  selectedLocationFilters: string[];
  selectedTimeOffTypeFilters: string[];
  selectedTimeOffStatusFilters: string[];
  selectedEmployeePositionFilters: string[];
  selectedEmployeeContractTypeFilters: string[];
  selectedEmployeeStatusFilters: string[];
  selectedEmployeeWorksiteFilters: string[];
  setSelectedNameFilters: (filters: string[]) => void;
  setSelectedSupervisorFilters: (filters: string[]) => void;
  setSelectedDepartmentFilters: (filters: string[]) => void;
  setSelectedTypeFilters: (filters: string[]) => void;
  setSelectedLocationFilters: (filters: string[]) => void;
  setSelectedTimeOffTypeFilters: (filters: string[]) => void;
  setSelectedTimeOffStatusFilters: (filters: string[]) => void;
  setSelectedEmployeePositionFilters: (filters: string[]) => void;
  setSelectedEmployeeContractTypeFilters: (filters: string[]) => void;
  setSelectedEmployeeStatusFilters: (filters: string[]) => void;
  setSelectedEmployeeWorksiteFilters: (filters: string[]) => void;
  setIsSelectingFilters: (isSelectingFilters: boolean) => void;
  clearAllFilters: () => void;
  selectedAttendanceTab: TabValue;
  setSelectedAttendanceTab: (selectedAttendanceTab: TabValue) => void;
  dateRangeFilterDate: any;
  setDateRangeFilterDate: (dateRangeFilterDate: any) => void;
  showDatePicker: boolean;
  hireDateRangeFilter: any;
  setHireDateRangeFilter: (hireDateRangeFilter: any) => void;
  showHireDatePicker: boolean;
  showTerminationDatePicker: boolean;
  terminationDateRangeFilter: any;
  setTerminationDateRangeFilter: (terminationDateRangeFilter: any) => void;
};

const FiltersContext = createContext<FiltersContextData>(
  {} as FiltersContextData
);

const FiltersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const attendancesStore = useStore(useAttendancesStore);
  const timeOffsStore = useStore(useTimeOffsStore);
  const employeeManagementStore = useStore(useEmployeeStore);
  const [filtersSections, setFiltersSection] = useState<TFiltersSection | null>(
    null
  );
  const [selectedNameFilters, setSelectedNameFilters] = useState<string[]>([]);
  const [selectedSupervisorFilters, setSelectedSupervisorFilters] = useState<
    string[]
  >([]);
  const [selectedDepartmentFilters, setSelectedDepartmentFilters] = useState<
    string[]
  >([]);
  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>([]);
  const [selectedLocationFilters, setSelectedLocationFilters] = useState<
    string[]
  >([]);
  const [selectedTimeOffTypeFilters, setSelectedTimeOffTypeFilters] = useState<
    string[]
  >([]);
  const [selectedTimeOffStatusFilters, setSelectedTimeOffStatusFilters] =
    useState<string[]>([]);
  const [selectedEmployeePositionFilters, setSelectedEmployeePositionFilters] =
    useState<string[]>([]);
  const [
    selectedEmployeeContractTypeFilters,
    setSelectedEmployeeContractTypeFilters,
  ] = useState<string[]>([]);
  const [selectedEmployeeStatusFilters, setSelectedEmployeeStatusFilters] =
    useState<string[]>([]);
  const [selectedEmployeeWorksiteFilters, setSelectedEmployeeWorksiteFilters] =
    useState<string[]>([]);
  const [isSelectingFilters, setIsSelectingFilters] = useState<boolean>(false);
  const [selectedAttendanceTab, setSelectedAttendanceTab] =
    useState<TabValue>("detailedAttendance");
  const [dateRangeFilterDate, setDateRangeFilterDate] = useState<any>([
    null,
    null,
  ]);
  const [hireDateRangeFilter, setHireDateRangeFilter] = useState<any>([
    null,
    null,
  ]);
  const [terminationDateRangeFilter, setTerminationDateRangeFilter] =
    useState<any>([null, null]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [showHireDatePicker, setShowHireDatePicker] = useState<boolean>(false);
  const [showTerminationDatePicker, setShowTerminationDatePicker] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname === "/dashboard/attendanceOwnHr" &&
      selectedAttendanceTab === "detailedAttendance"
    ) {
      const nameFilters: string[] = [];
      const supervisorFilters: string[] = [];
      const departmentFilters: string[] = [];
      const locationFilters: string[] = [];
      const typeFilters: string[] = [];
      attendancesStore.attendances.forEach((att) => {
        if (!nameFilters.includes(att?.EmployeeName)) {
          nameFilters.push(att?.EmployeeName);
        }
        if (!supervisorFilters.includes(att?.SupervisorName)) {
          supervisorFilters.push(att?.SupervisorName);
        }
        if (
          !departmentFilters.includes(att?.DepartmentName) &&
          att?.DepartmentName
        ) {
          departmentFilters.push(att?.DepartmentName);
        }
        if (!locationFilters.includes(att?.Location)) {
          locationFilters.push(att?.Location);
        }
        if (!typeFilters.includes(att?.Type)) {
          typeFilters.push(att?.Type);
        }
      });
      setFiltersSection({
        nameFilters,
        supervisorFilters,
        departmentFilters,
        locationFilters,
        typeFilters,
      });
      setShowDatePicker(true);
      setShowHireDatePicker(false);
      setShowTerminationDatePicker(false);
    } else if (
      location.pathname === "/dashboard/attendanceOwnHr" &&
      selectedAttendanceTab === "summarizedAttendance"
    ) {
      const nameFilters: string[] = [];
      const supervisorFilters: string[] = [];
      const departmentFilters: string[] = [];
      const locationFilters: string[] = [];
      attendancesStore.summarizedAttendances.forEach((att) => {
        if (!nameFilters.includes(att?.EmployeeName)) {
          nameFilters.push(att?.EmployeeName);
        }
        if (!supervisorFilters.includes(att?.SupervisorName)) {
          supervisorFilters.push(att?.SupervisorName);
        }
        if (
          !departmentFilters.includes(att?.DepartmentName) &&
          att?.DepartmentName
        ) {
          departmentFilters.push(att?.DepartmentName);
        }
        if (!locationFilters.includes(att?.Location)) {
          locationFilters.push(att?.Location);
        }
      });
      setFiltersSection({
        nameFilters,
        supervisorFilters,
        departmentFilters,
        locationFilters,
      });
      setShowDatePicker(true);
      setShowHireDatePicker(false);
      setShowTerminationDatePicker(false);
    } else if (location.pathname === "/dashboard/absencesOwnHr") {
      const nameFilters: string[] = [];
      const supervisorFilters: string[] = [];
      const timeOffTypeFilters: string[] = [];
      const timeOffStatusFilters: string[] = [];
      timeOffsStore.timeOffs.forEach((timeOff) => {
        if (!nameFilters.includes(timeOff?.EmployeeName)) {
          nameFilters.push(timeOff?.EmployeeName);
        }
        if (!supervisorFilters.includes(timeOff?.SupervisorName)) {
          supervisorFilters.push(timeOff?.SupervisorName);
        }
        if (
          timeOff?.LeaveType?.Name &&
          !timeOffTypeFilters.includes(timeOff?.LeaveType?.Name)
        ) {
          timeOffTypeFilters.push(timeOff?.LeaveType?.Name);
        }
        let requestStatus =
          timeOff?.RequestStatus[0].toUpperCase() +
          timeOff?.RequestStatus.slice(1);
        if (!timeOffStatusFilters.includes(requestStatus)) {
          timeOffStatusFilters.push(requestStatus);
        }
      });
      setFiltersSection({
        nameFilters,
        supervisorFilters,
        timeOffTypeFilters,
        timeOffStatusFilters,
      });
      setShowDatePicker(true);
      setShowHireDatePicker(false);
      setShowTerminationDatePicker(false);
    } else if (location.pathname === "/dashboard/employeesManagement") {
      const nameFilters: string[] = [];
      const departmentFilters: string[] = [];
      const positionFilters: string[] = [];
      const contractTypeFilters: string[] = [];
      const statusFilters: string[] = [];
      const worksiteFilters: string[] = [];
      employeeManagementStore.employees.forEach((emp) => {
        if (!nameFilters.includes(emp?.Name)) {
          nameFilters.push(emp?.Name);
        }
        if (
          !departmentFilters.includes(emp?.DepartmentName) &&
          emp?.DepartmentName
        ) {
          departmentFilters.push(emp?.DepartmentName);
        }
        if (!positionFilters.includes(emp?.Position) && emp?.Position) {
          positionFilters.push(emp?.Position);
        }
        if (emp?.ContractType) {
          let contractType =
            emp?.ContractType[0].toUpperCase() + emp?.ContractType.slice(1);
          if (!contractTypeFilters.includes(contractType) && contractType) {
            contractTypeFilters.push(contractType);
          }
        }
        let employeeStatus =
          emp?.Status[0].toUpperCase() + emp?.Status.slice(1);
        if (!statusFilters.includes(employeeStatus) && employeeStatus) {
          statusFilters.push(employeeStatus);
        }
        let worksite = emp?.Worksite[0].toUpperCase() + emp?.Worksite.slice(1);
        if (!worksiteFilters.includes(worksite) && worksite) {
          worksiteFilters.push(worksite);
        }
      });
      setFiltersSection({
        nameFilters,
        departmentFilters,
        positionFilters,
        contractTypeFilters,
        statusFilters,
        worksiteFilters,
      });
      setShowDatePicker(false);
      setShowHireDatePicker(true);
      setShowTerminationDatePicker(true);
    } else {
      setFiltersSection(null);
      setShowDatePicker(false);
      setShowHireDatePicker(false);
      setShowTerminationDatePicker(false);
    }
    setDateRangeFilterDate([null, null]);
    setHireDateRangeFilter([null, null]);
    setTerminationDateRangeFilter([null, null]);
  }, [
    location,
    attendancesStore.attendances,
    attendancesStore.summarizedAttendances,
    timeOffsStore.timeOffs,
    employeeManagementStore.employees,
    selectedAttendanceTab,
  ]);

  useEffect(() => {
    clearAllFilters();
  }, [location]);

  const clearAllFilters = () => {
    setSelectedNameFilters([]);
    setSelectedSupervisorFilters([]);
    setSelectedDepartmentFilters([]);
    setSelectedTypeFilters([]);
    setSelectedLocationFilters([]);
    setSelectedTimeOffTypeFilters([]);
    setSelectedTimeOffStatusFilters([]);
    setSelectedEmployeePositionFilters([]);
    setSelectedEmployeeContractTypeFilters([]);
    setSelectedEmployeeStatusFilters([]);
    setSelectedEmployeeWorksiteFilters([]);
    setDateRangeFilterDate([null, null]);
    setHireDateRangeFilter([null, null]);
    setTerminationDateRangeFilter([null, null]);
    setIsSelectingFilters(false);
  };
  return (
    <FiltersContext.Provider
      value={{
        filtersSections,
        isSelectingFilters,
        selectedNameFilters,
        selectedSupervisorFilters,
        selectedDepartmentFilters,
        selectedTypeFilters,
        selectedLocationFilters,
        selectedTimeOffTypeFilters,
        selectedTimeOffStatusFilters,
        selectedEmployeePositionFilters,
        selectedEmployeeContractTypeFilters,
        selectedEmployeeStatusFilters,
        selectedEmployeeWorksiteFilters,
        setSelectedNameFilters,
        setSelectedSupervisorFilters,
        setSelectedDepartmentFilters,
        setSelectedTypeFilters,
        setSelectedLocationFilters,
        setSelectedTimeOffTypeFilters,
        setSelectedTimeOffStatusFilters,
        setSelectedEmployeePositionFilters,
        setSelectedEmployeeContractTypeFilters,
        setSelectedEmployeeStatusFilters,
        setSelectedEmployeeWorksiteFilters,
        setIsSelectingFilters,
        clearAllFilters,
        selectedAttendanceTab,
        setSelectedAttendanceTab,
        dateRangeFilterDate,
        setDateRangeFilterDate,
        showDatePicker,
        hireDateRangeFilter,
        setHireDateRangeFilter,
        showHireDatePicker,
        terminationDateRangeFilter,
        setTerminationDateRangeFilter,
        showTerminationDatePicker,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFiltersContext = () => {
  return useContext(FiltersContext);
};

export { FiltersContext, FiltersProvider, useFiltersContext };
