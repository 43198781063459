import { Image, Button } from "@fluentui/react-components";
import "./Welcome.css";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Welcome() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToSteps = () => {
    navigate("/setupSteps");
  };

  return (
    <div className="welcome page center">
      <div className="narrow page-padding">
        <Image src="hello.png" />
        <div className="welcomeContainer">
          <h1 className="welcomeMessage">{t("initialSetup.welcomeToTargo")}</h1>
          <p className="completeMessage">{t("initialSetup.completeMessage")}</p>
        </div>

        <Button
          className="getStartedButton"
          appearance="primary"
          onClick={() => redirectToSteps()}
        >
          {t("initialSetup.getStarted")}
        </Button>
      </div>
    </div>
  );
}
