import { Permission, User, UserRole } from "../types";
import httpService from "./http";

const getPermissions = async () => {
  const response = await httpService.get("/settings/");
  let permissions: Permission[] = [];
  response?.data?.permissions?.forEach((permission: any) => {
    permissions.push({
      Id: permission?.PermissionId,
      Category: permission?.PermissionCategory,
      Name: permission?.PermissionName,
      Description: permission?.PermissionDescription,
    });
  });
  let customRoles: UserRole[] = [];
  response?.data?.customRoles?.forEach((customRole: any) => {
    let perm: Permission[] = [];
    customRole?.Permissions?.forEach((customRolePerm: any) => {
      perm.push({
        Id: customRolePerm?.PermissionId,
        Category: customRolePerm?.PermissionCategory,
        Name: customRolePerm?.PermissionName,
        Description: customRolePerm?.PermissionDescription,
      });
    });
    customRoles.push({
      Type: "custom",
      Id: customRole?.CustomRoleId,
      RoleName: customRole?.CustomRoleName,
      Description: customRole?.CustomRoleDescription,
      Permissions: perm,
    });
  });
  let defaultRoles: UserRole[] = [];
  response?.data?.roles?.forEach((defaultRole: any) => {
    let perm: Permission[] = [];
    defaultRole?.Permissions?.forEach((defaultRolePerm: any) => {
      perm.push({
        Id: defaultRolePerm.PermissionId,
        Category: defaultRolePerm?.PermissionCategory,
        Name: defaultRolePerm?.PermissionName,
        Description: defaultRolePerm?.PermissionDescription,
      });
    });
    defaultRoles.push({
      Type: "builtIn",
      Id: defaultRole?.RoleId,
      RoleName: defaultRole?.RoleName,
      Description: defaultRole?.RoleDescription,
      Permissions: perm,
    });
  });
  return { permissions, customRoles, defaultRoles };
};

const createRole = async (
  name: string,
  description: string,
  permissions: number[]
) => {
  const response = await httpService.post("/settings/create", {
    name,
    description,
    permissions,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response.data;
  }
  const perms: Permission[] = [];
  response?.data?.role?.Permissions?.forEach((perm: any) => {
    perms.push({
      Id: perm?.PermissionId,
      Category: perm?.PermissionCategory,
      Name: perm?.PermissionName,
      Description: perm?.PermissionDescription,
    });
  });
  const customRole: UserRole = {
    Type: "custom",
    Id: response?.data?.role?.CustomRoleId,
    RoleName: response?.data?.role?.CustomRoleName,
    Description: response?.data?.role?.CustomRoleDescription,
    Permissions: perms,
  };
  return { customRole };
};

const editRole = async (
  customRoleId: number,
  name: string,
  description: string,
  permissions: number[]
) => {
  const response = await httpService.patch("/settings/edit", {
    customRoleId,
    name,
    description,
    permissions,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response.data;
  }
  const perms: Permission[] = [];
  response?.data?.role?.Permissions?.forEach((perm: any) => {
    perms.push({
      Id: perm?.PermissionId,
      Category: perm?.PermissionCategory,
      Name: perm?.PermissionName,
      Description: perm?.PermissionDescription,
    });
  });
  const customRole: UserRole = {
    Type: "custom",
    Id: response?.data?.role?.CustomRoleId,
    RoleName: response?.data?.role?.CustomRoleName,
    Description: response?.data?.role?.CustomRoleDescription,
    Permissions: perms,
  };
  return { customRole };
};

const deleteRole = async (roleId: number) => {
  const response = await httpService.post("/settings/role/delete", {
    roleId,
  });
  return response.data;
};

const getUsers = async () => {
  const response = await httpService.get("/settings/users");
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    const userRoles: UserRole[] = [];
    if (user?.Roles?.length > 0) {
      userRoles.push({
        Type: "builtIn",
        Id: user?.Roles[0]?.RoleId,
        RoleName: user?.Roles[0]?.RoleName,
      });
    }
    if (user?.CustomRoles?.length > 0) {
      userRoles.push({
        Type: "custom",
        Id: user?.CustomRoles[0]?.CustomRoleId,
        RoleName: user?.CustomRoles[0]?.CustomRoleName,
      });
    }
    users.push({
      Id: user?.UserId,
      FullName: `${user?.FirstName ?? ""} ${user?.LastName ?? ""}`,
      Department: user?.OwnDepartment
        ? {
            Id: user?.OwnDepartment?.DepartmentId,
            Name: user?.OwnDepartment?.DepartmentName,
          }
        : null,
      JobTitle: user?.JobTitle,
      EmploymentType: user?.EmploymentType,
      Status: user?.Status,
      Role: userRoles,
    });
  });

  const allRoles: UserRole[] = [];
  response?.data?.roles?.forEach((role: any) => {
    allRoles.push({
      Type: "builtIn",
      Id: role?.RoleId,
      RoleName: role?.RoleName,
    });
  });
  response?.data?.customRoles?.forEach((customRole: any) => {
    allRoles.push({
      Type: "custom",
      Id: customRole?.CustomRoleId,
      RoleName: customRole?.CustomRoleName,
    });
  });

  return { users, allRoles };
};

const setUserRole = async (userId: number, selectedOptions: string[]) => {
  const response = await httpService.post("/settings/user/role", {
    userId,
    selectedOptions,
  });
  if ("status" in response?.data) {
    return response.data;
  }
  if ("roles" in response?.data) {
    const roles: UserRole[] = [];
    response?.data?.roles?.forEach((rl: any) => {
      roles.push({
        Type: rl?.roleType,
        Id: rl?.Type === "custom" ? rl?.CustomRoleId : rl?.RoleId,
        RoleName: rl?.Type === "custom" ? rl?.CustomRoleName : rl?.RoleName,
      });
    });
    return { roles };
  }
  const userRole: UserRole = {
    Type: response?.data?.roleType,
    Id:
      response?.data?.roleType === "custom"
        ? response?.data?.role?.CustomRoleId
        : response?.data?.role?.RoleId,
    RoleName:
      response?.data?.roleType === "custom"
        ? response?.data?.role?.CustomRoleName
        : response?.data?.role?.RoleName,
  };
  return { userRole };
};

const deleteUsers = async (selectedItems: number[]) => {
  const response = await httpService.post("/settings/users/delete", {
    userIds: selectedItems,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  return { status: "success" };
};

const addUsersFromAad = async () => {
  const response = await httpService.get("/settings/users/aad");
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    const userRoles: UserRole[] = [];
    if (user?.Roles?.length > 0) {
      userRoles.push({
        Type: "builtIn",
        Id: user?.Roles[0]?.RoleId,
        RoleName: user?.Roles[0]?.RoleName,
      });
    }
    if (user?.CustomRoles?.length > 0) {
      userRoles.push({
        Type: "custom",
        Id: user?.CustomRoles[0]?.CustomRoleId,
        RoleName: user?.CustomRoles[0]?.CustomRoleName,
      });
    }
    users.push({
      Id: user?.UserId,
      FullName: `${user?.FirstName ?? ""} ${user?.LastName ?? ""}`,
      Department: user?.OwnDepartment
        ? {
            Id: user?.OwnDepartment?.DepartmentId,
            Name: user?.OwnDepartment?.DepartmentName,
          }
        : null,
      JobTitle: user?.JobTitle,
      EmploymentType: user?.EmploymentType,
      Role: userRoles,
    });
  });
  return { users };
};

const syncUsersWithAad = async () => {
  const response = await httpService.get("/settings/users/sync");
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    const userRoles: UserRole[] = [];
    if (user?.Roles?.length > 0) {
      userRoles.push({
        Type: "builtIn",
        Id: user?.Roles[0]?.RoleId,
        RoleName: user?.Roles[0]?.RoleName,
      });
    }
    if (user?.CustomRoles?.length > 0) {
      userRoles.push({
        Type: "custom",
        Id: user?.CustomRoles[0]?.CustomRoleId,
        RoleName: user?.CustomRoles[0]?.CustomRoleName,
      });
    }
    users.push({
      Id: user?.UserId,
      FullName: `${user?.FirstName ?? ""} ${user?.LastName ?? ""}`,
      Department: user?.OwnDepartment
        ? {
            Id: user?.OwnDepartment?.DepartmentId,
            Name: user?.OwnDepartment?.DepartmentName,
          }
        : null,
      JobTitle: user?.JobTitle,
      EmploymentType: user?.EmploymentType,
      Status: user?.Status,
      Role: userRoles,
    });
  });
  return { users };
};

const getOrganigram = async () => {
  const response = await httpService.get("/settings/users/organigram");
  const users: User[] = [];
  response?.data?.org?.forEach((user: any) => {
    users.push({
      Id: user?.UserId,
      FullName: `${user?.FirstName ?? ""} ${user?.LastName ?? ""}`,
      Department: user?.DepartmentId
        ? {
            Id: user?.DepartmentId,
            Name: user?.DepartmentName,
          }
        : null,
      JobTitle: user?.JobTitle,
      EmploymentType: user?.EmploymentType,
      Role: [
        {
          Type: "builtIn",
          Id: 99,
          RoleName: user?.Roles ? user?.Roles?.RoleName : "",
        },
      ],
      Level: user?.level,
    });
  });
  return { users };
};

const settingsService = {
  getPermissions,
  createRole,
  editRole,
  deleteRole,
  getUsers,
  setUserRole,
  deleteUsers,
  addUsersFromAad,
  syncUsersWithAad,
  getOrganigram,
};
export default settingsService;
