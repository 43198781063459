import Modal from "../../../Modal";
import * as Yup from "yup";
import "./CheckInModal.css";
import { ErrorMessage, Form, Formik } from "formik";
import { Button, Select } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useAttendancesStore } from "../../../../store/attendancesStore";
import { useStore } from "zustand";
import attendanceService from "../../../../services/attendance";
import { toast } from "react-toastify";

interface ICheckInModal {
  isBreak: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  setIsChecked: (isChecked: boolean) => void;
}

const CheckInModal: React.FC<ICheckInModal> = ({
  isBreak,
  isModalOpen,
  closeModal,
  setIsChecked,
}) => {
  const { t } = useTranslation();
  const attendancesStore = useStore(useAttendancesStore);

  const initialValues = {
    location: "",
  };

  const validationSchema = Yup.object().shape({
    location: Yup.string().required(
      t("attendanceOwnHr.checkIn.locationIsRequired")
    ),
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const result = await attendanceService.startAttendance(
        values.location,
        isBreak ? "break" : "work",
        new Date()
      );
      if ("attendance" in result) {
        attendancesStore.setStarted({
          attendanceId: result?.attendance?.AttendanceId,
          time: result?.attendance?.AttendanceStartTime,
          type: result?.attendance?.AttendanceType,
        });
        if (!isBreak) {
          setIsChecked(true);
        }
        toast.success(t("attendanceOwnHr.checkIn.success"), {
          position: "bottom-right",
        });
      } else if ("status" in result && result?.status === "error") {
        toast.error(t(`attendanceOwnHr.checkIn.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      }
    } catch (e) {
      toast.error(t("attendanceOwnHr.checkIn.errors.errorStarting"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSubmitting(false);
      closeModal();
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Targo360"
        subtitle="Attendance"
        contentTitle={
          isBreak
            ? t("attendanceOwnHr.checkIn.break")
            : t("attendanceOwnHr.checkIn.checkIn")
        }
        headerStyle="checkInModalHeader"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, handleChange, handleBlur }) => (
            <Form className="checkInForm">
              <div className="checkInInputContainer">
                <label className="checkInInputLabel">
                  {t("attendanceOwnHr.checkIn.location")}
                </label>
                <Select
                  name="location"
                  className="checkInSelectInput checkInInputStyle"
                  appearance="filled-lighter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">
                    {t("attendanceOwnHr.checkIn.selectLocation")}
                  </option>
                  <option value="remote">
                    {t("attendanceOwnHr.checkIn.remote")}
                  </option>
                  <option value="office">
                    {t("attendanceOwnHr.checkIn.office")}
                  </option>
                </Select>
                <ErrorMessage
                  name="location"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="checkInButtonContainer">
                <Button
                  className="checkInButton"
                  appearance="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("attendanceOwnHr.checkIn.start")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
export default CheckInModal;
