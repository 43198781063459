import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  Divider,
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Image,
  Spinner,
  useTableSort,
  TableColumnId,
  Tooltip,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./RolesAndPermissions.css";
import AddRole from "../../components/Settings/Modals/AddRole";
import Error from "../Error";
import settingsService from "../../services/settings";
import { useStore } from "zustand";
import { useSettingsStore } from "../../store/settingsStore";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { UserRole } from "../../types";
import { useUserStore } from "../../store/userStore";
import helpers from "../../helpers/helpers";
import { DeleteDialog } from "../../components/Settings/RolesAndPermissions/DeleteDialog";
import Pagination from "../../components/InitialSetupWizard/Pagination";
import ViewRole from "../../components/Settings/Modals/ViewRole";

const columnSizingOptions = {
  Name: {
    minWidth: 100,
    defaultWidth: 135,
  },
  Description: {
    minWidth: 230,
    defaultWidth: 230,
  },
  actions: {
    minWidth: 92,
    defaultWidth: 92,
  },
};

export default function RolesAndPermissionsSettings() {
  const { t } = useTranslation();
  const [syncing, setSyncing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const settingsStore = useStore(useSettingsStore);
  const [items, setItems] = useState<UserRole[]>(settingsStore.customRoles);
  const [openModal, setOpenModal] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const userStore = useStore(useUserStore);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCustomRoleId, setSelectedCustomRoleId] = useState<
    number | null
  >(null);
  const [selectedDefaultRoleId, setSelectedDefaultRoleId] = useState<
    number | null
  >(null);
  const itemsPerPage = 7;
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const columnsDef: TableColumnDefinition<UserRole>[] = [
    createTableColumn<UserRole>({
      columnId: "Name",
      compare: (a, b) => {
        if (!a.RoleName && !b.RoleName) {
          return 0;
        } else if (!a.RoleName) {
          return 1;
        } else if (!b.RoleName) {
          return -1;
        } else {
          return a.RoleName.trim().localeCompare(b.RoleName.trim());
        }
      },
      renderHeaderCell: () => (
        <>{t("settings.rolesAndPermissions.table.roles")}</>
      ),
    }),
    createTableColumn<UserRole>({
      columnId: "Description",
      compare: (a, b) => {
        if (!a?.Description && !b?.Description) {
          return 0;
        } else if (!a.Description) {
          return 1;
        } else if (!b.Description) {
          return -1;
        } else {
          return a?.Description.trim().localeCompare(b?.Description.trim());
        }
      },
      renderHeaderCell: () => (
        <>{t("settings.rolesAndPermissions.table.description")}</>
      ),
    }),
    createTableColumn<UserRole>({
      columnId: "actions",
      renderHeaderCell: () => <></>,
    }),
  ];
  const [columns] = useState<TableColumnDefinition<UserRole>[]>(columnsDef);

  useEffect(() => {
    if (searchValue === "") {
      setItems(settingsStore.customRoles);
    } else {
      let filteredItems = settingsStore.customRoles;
      filteredItems = filteredItems.filter(
        (role) =>
          role?.RoleName?.toLowerCase().includes(searchValue.toLowerCase()) ||
          role?.Description?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setItems(filteredItems);
    }
  }, [searchValue, settingsStore.customRoles]);

  const getPermissionsData = async () => {
    const result = await settingsService.getPermissions();
    settingsStore.setPermissions(result?.permissions);
    settingsStore.setCustomRoles(result?.customRoles);
    settingsStore.setRoles(result?.defaultRoles);
    return result;
  };

  const deleteRole = async () => {
    try {
      if (selectedCustomRoleId) {
        const result = await settingsService.deleteRole(selectedCustomRoleId);
        if ("status" in result && result?.status === "error") {
          toast.error(
            t(`settings.rolesAndPermissions.modals.${result?.message}`),
            {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            }
          );
        } else if ("status" in result && result?.status === "success") {
          settingsStore.deleteCustomRoleById(selectedCustomRoleId);
          toast.success(t("settings.rolesAndPermissions.modals.success"), {
            position: "bottom-right",
          });
        }
      }
    } catch (e) {
      toast.error(t("settings.rolesAndPermissions.errors.delete"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setIsDialogOpen(false);
      setSelectedCustomRoleId(null);
    }
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["permissionsData"],
    queryFn: getPermissionsData,
  });

  const {
    columnSizing_unstable,
    tableRef,
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSort({
        defaultSortState: {
          sortColumn: "UpdatedDate",
          sortDirection: "descending",
        },
      }),
    ]
  );

  const currentPageRows = sort(
    getRows((row) => {
      return {
        ...row,
      };
    })
  );

  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error)
    return (
      <Error message={t("settings.rolesAndPermissions.errors.generalError")} />
    );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const handleSyncNow = async () => {
    setSyncing(true);
    try {
      await getPermissionsData();
    } catch (e) {
      toast.error(t("settings.manageUsers.errors.sync"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSyncing(false);
    }
  };

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event?.target.value);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <>
      {helpers.checkHasPermissions(
        userStore?.user?.Permissions,
        "Roles and Permissions",
        "Can Read"
      ) ? (
        <div className="rolesAndPermissionsSettingsPage">
          <div className="rolesAndPermissionsSettingsInfoContainer">
            <div className="title">
              {t("settings.rolesAndPermissions.title")}
            </div>
            <div className="detailsContainer">
              <span className="details">
                {t("settings.rolesAndPermissions.description")}
              </span>
            </div>
          </div>
          <Divider appearance="strong" />
          <div className="rolesAndPermissionsSettingsInfoContainer">
            <div className="subtitle">
              {t("settings.rolesAndPermissions.accessData")}
            </div>
            <div className="subdescription">
              {t(
                "settings.rolesAndPermissions.hereSAnExampleOfHowASectionCanBeUsedToGroupInputs"
              )}
            </div>
            <Button
              type="button"
              onClick={handleSyncNow}
              appearance="primary"
              className="settingsSyncNowBtn"
              disabled={
                !helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Roles and Permissions",
                  "Can Edit"
                )
              }
            >
              {syncing ? (
                <span>
                  <Spinner size="extra-tiny" />
                </span>
              ) : null}
              {t("settings.rolesAndPermissions.syncNow")}
            </Button>
          </div>
          <div className="rolesAndPermissionsTableSection">
            <div className="rolesAndPermissionsTableSectionHeader">
              <Button
                type="button"
                appearance="primary"
                className="rolesAndPermissionsAddRoleButton"
                onClick={() => setOpenModal(true)}
                disabled={
                  !helpers.checkHasPermissions(
                    userStore?.user?.Permissions,
                    "Roles and Permissions",
                    "Can Edit"
                  )
                }
              >
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/plus-icon.svg`}
                  alt="plus-icon"
                />
                {t("settings.rolesAndPermissions.addNewRole")}
              </Button>
              <div className="rolesAndPermissionsFilterSectionActions">
                <Input
                  className="rolesAndPermissionsSearchInput"
                  contentAfter={
                    <Image
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/search.svg`}
                      alt="search"
                    />
                  }
                  placeholder={t("settings.rolesAndPermissions.find")}
                  value={searchValue}
                  onChange={handleSearchValueChange}
                />
              </div>
            </div>
            <div className="rolesAndPermissionsTableContainer">
              <Table
                ref={tableRef}
                sortable
                {...columnSizing_unstable.getTableProps()}
                noNativeElements={true}
                className="rolesAndPermissionsTable"
              >
                <TableHeader className="rolesAndPermissionsTableHeaderRow">
                  <TableRow>
                    {columns.map((column) => (
                      <Menu openOnContext key={column.columnId}>
                        <MenuTrigger>
                          <TableHeaderCell
                            key={column.columnId}
                            {...headerSortProps(column.columnId)}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                              column.columnId
                            )}
                          >
                            {column.renderHeaderCell()}
                          </TableHeaderCell>
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem
                              onClick={columnSizing_unstable.enableKeyboardMode(
                                column.columnId
                              )}
                            >
                              Keyboard Column Resizing
                            </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {getCurrentPageRows().map(({ item }) => (
                    <TableRow key={item.Id}>
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps("Name")}
                      >
                        <TableCellLayout truncate>
                          <div className="rolesAndPermissionsTableCellStyle">
                            {item.RoleName}
                          </div>
                        </TableCellLayout>
                      </TableCell>
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps(
                          "Description"
                        )}
                      >
                        <TableCellLayout truncate>
                          <div className="rolesAndPermissionsTableCellStyle">
                            {item.Description}
                          </div>
                        </TableCellLayout>
                      </TableCell>
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps("actions")}
                      >
                        <TableCellLayout truncate>
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Roles and Permissions",
                            "Can Edit"
                          ) ||
                          helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Roles and Permissions",
                            "Can Delete"
                          ) ? (
                            <Menu>
                              <MenuTrigger disableButtonEnhancement>
                                <Tooltip
                                  content="Menu action"
                                  withArrow
                                  positioning="below"
                                  appearance="inverted"
                                  relationship="label"
                                >
                                  <Image
                                    className="actionIcon"
                                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                    alt="horizontalMenu"
                                  />
                                </Tooltip>
                              </MenuTrigger>
                              <MenuPopover>
                                <MenuList>
                                  {helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Roles and Permissions",
                                    "Can Edit"
                                  ) ? (
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedCustomRoleId(item.Id);
                                        setOpenModal(true);
                                      }}
                                    >
                                      <span>
                                        {t("settings.rolesAndPermissions.edit")}
                                      </span>
                                    </MenuItem>
                                  ) : null}
                                  {helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Roles and Permissions",
                                    "Can Delete"
                                  ) ? (
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedCustomRoleId(item.Id);
                                        setIsDialogOpen(true);
                                      }}
                                    >
                                      <span>
                                        {t(
                                          "settings.rolesAndPermissions.delete"
                                        )}
                                      </span>
                                    </MenuItem>
                                  ) : null}
                                </MenuList>
                              </MenuPopover>
                            </Menu>
                          ) : null}
                        </TableCellLayout>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />

              <AddRole
                isModalOpen={openModal}
                closeModal={() => {
                  setOpenModal(false);
                  setSelectedCustomRoleId(null);
                }}
                customRoleId={selectedCustomRoleId}
              />
              <DeleteDialog
                open={isDialogOpen}
                closeDialog={() => setIsDialogOpen(false)}
                deleteUsers={deleteRole}
              />
            </div>
            <div className="rolesAndPermissionsTableContainer">
              <div className="rolesAndPermissionsTableContainerDefaultRolesTitle">
                {t("settings.rolesAndPermissions.defaultRoles")}
              </div>
              <Table
                {...columnSizing_unstable.getTableProps()}
                noNativeElements={true}
                className="rolesAndPermissionsTable"
              >
                <TableHeader className="rolesAndPermissionsTableHeaderRow">
                  <TableRow>
                    {columns.map((column) => (
                      <Menu openOnContext key={column.columnId}>
                        <MenuTrigger>
                          <TableHeaderCell
                            key={column.columnId}
                            {...columnSizing_unstable.getTableHeaderCellProps(
                              column.columnId
                            )}
                          >
                            {column.renderHeaderCell()}
                          </TableHeaderCell>
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem
                              onClick={columnSizing_unstable.enableKeyboardMode(
                                column.columnId
                              )}
                            >
                              Keyboard Column Resizing
                            </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {settingsStore?.roles.map((item) => (
                    <TableRow key={item.Id}>
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps("Name")}
                      >
                        <TableCellLayout truncate>
                          <div className="rolesAndPermissionsTableCellStyle">
                            {item.RoleName}
                          </div>
                        </TableCellLayout>
                      </TableCell>
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps(
                          "Description"
                        )}
                      >
                        <TableCellLayout truncate>
                          <div className="rolesAndPermissionsTableCellStyle">
                            {item.Description}
                          </div>
                        </TableCellLayout>
                      </TableCell>
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps("actions")}
                      >
                        <TableCellLayout truncate>
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Roles and Permissions",
                            "Can Read"
                          ) ? (
                            <Menu>
                              <MenuTrigger disableButtonEnhancement>
                                <Tooltip
                                  content="Menu action"
                                  withArrow
                                  positioning="below"
                                  appearance="inverted"
                                  relationship="label"
                                >
                                  <Image
                                    className="actionIcon"
                                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                    alt="horizontalMenu"
                                  />
                                </Tooltip>
                              </MenuTrigger>
                              <MenuPopover>
                                <MenuList>
                                  {helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Roles and Permissions",
                                    "Can Read"
                                  ) ? (
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedDefaultRoleId(item.Id);
                                        setViewModalOpen(true);
                                      }}
                                    >
                                      <span>
                                        {t("settings.rolesAndPermissions.view")}
                                      </span>
                                    </MenuItem>
                                  ) : null}
                                </MenuList>
                              </MenuPopover>
                            </Menu>
                          ) : null}
                        </TableCellLayout>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <ViewRole
                isModalOpen={viewModalOpen}
                closeModal={() => {
                  setViewModalOpen(false);
                  setSelectedDefaultRoleId(null);
                }}
                roleId={selectedDefaultRoleId}
              />
            </div>
          </div>
        </div>
      ) : (
        <Error message={t("settings.general.notAuthorized")} />
      )}
    </>
  );
}
