import { useLocation } from "react-router-dom";
import ContactUsForm from "../components/ContactUs/ContactUsForm";
import MoreActions from "../components/ContactUs/MoreActions";
import "./ContactUs.css";

export default function ContactUs() {
  return (
    <div className="contactUsPageContainer">
      <ContactUsForm />
      <MoreActions />
    </div>
  );
}
