import { create } from "zustand";
import { IAttendancesStore } from "../types";

export const useAttendancesStore = create<IAttendancesStore>((set) => ({
  started: {},
  attendances: [],
  summarizedAttendances: [],
  users: [],
  setStarted: (started) => set({ started }),
  setAttendances: (attendances) => set({ attendances }),
  setSummarizedAttendances: (summarizedAttendances) =>
    set({ summarizedAttendances }),
  addAttendance: (newAttendance) =>
    set((state) => ({ attendances: [newAttendance, ...state.attendances] })),
  addAttendances: (newAttendances) =>
    set((state) => ({
      attendances: [...newAttendances, ...state.attendances],
    })),
  setUsers: (users) => set({ users }),
  replaceAttendanceById: (updatedAttendance) => {
    set((state) => ({
      attendances: state.attendances.map((attendance) =>
        attendance.Id === updatedAttendance.Id ? updatedAttendance : attendance
      ),
    }));
  },
  deleteAttendanceById: (attendanceId) => {
    set((state) => ({
      attendances: state.attendances.filter(
        (attendance) => attendance.Id !== attendanceId
      ),
    }));
  },
  deleteAttendancesSummarized: (date, employeeId, location) => {
    set((state) => ({
      attendances: state.attendances.filter(
        (attendance) =>
          !(
            attendance.Location === location &&
            attendance.Date.format("DD-MM-YYYY") ===
              date.format("DD-MM-YYYY") &&
            attendance.EmployeeId === employeeId
          )
      ),
      summarizedAttendances: state.summarizedAttendances.filter(
        (sumAttendance) =>
          !(
            sumAttendance.Location === location &&
            sumAttendance.Date.format("DD-MM-YYYY") ===
              date.format("DD-MM-YYYY") &&
            sumAttendance.EmployeeId === employeeId
          )
      ),
    }));
  },
}));
