import "./Step2.css";
import { Spinner } from "@fluentui/react-components";
import { Step2Table } from "../StepsTables/Step2Table";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import OrganizationService from "../../../../services/organization";
import { AdminUser } from "../../../../types";
import Support from "../../Support";

export function Step2(props: {
  setIsError: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [admins, setAdmins] = useState<AdminUser[]>([]);

  useEffect(() => {
    const getInitialAdmins = async () => {
      setLoading(true);
      try {
        const result = await OrganizationService.getInitialAdmins();
        setAdmins(result);
      } catch (e) {
        props.setIsError(true);
        props.setErrorMessage(t("initialSetup.errors.errorGettingTheAdmins"));
      } finally {
        setLoading(false);
      }
    };
    getInitialAdmins();
  }, []);

  return (
    <>
      <div className="step2Container">
        <div className="step2HeaderContainer">
          <div className="step2HeaderTextSection">
            <div className="titleSection">
              <h2 className="step2Title">
                {t("initialSetup.steps.step2.step2Admin")}
              </h2>
              <p className="step2Subtitle">
                {t("initialSetup.steps.step2.step2Subtitle")}
              </p>
            </div>
          </div>
          <Support />
        </div>

        <div>
          {loading ? (
            <Spinner style={{ margin: 100 }} />
          ) : (
            <Step2Table admins={admins} />
          )}
        </div>
      </div>
    </>
  );
}
