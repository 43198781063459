import { Image, Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import "./Filters.css";
import { useFiltersContext } from "../../contexts/FiltersContext";
import FiltersSection from "./Section";
import DatePickerComponent from "../AbsencesOwnHr/AbsencesComponents/DatePicker";

interface IFiltersModal {
  isOpen: boolean;
  onClose: () => void;
}

const Filters: React.FC<IFiltersModal> = ({ isOpen, onClose }) => {
  const {
    filtersSections,
    selectedNameFilters,
    selectedSupervisorFilters,
    selectedDepartmentFilters,
    selectedTypeFilters,
    selectedLocationFilters,
    selectedTimeOffTypeFilters,
    selectedTimeOffStatusFilters,
    selectedEmployeePositionFilters,
    selectedEmployeeContractTypeFilters,
    selectedEmployeeStatusFilters,
    selectedEmployeeWorksiteFilters,
    setSelectedNameFilters,
    setSelectedSupervisorFilters,
    setSelectedDepartmentFilters,
    setSelectedTypeFilters,
    setSelectedLocationFilters,
    setSelectedTimeOffTypeFilters,
    setSelectedTimeOffStatusFilters,
    setSelectedEmployeePositionFilters,
    setSelectedEmployeeContractTypeFilters,
    setSelectedEmployeeStatusFilters,
    setSelectedEmployeeWorksiteFilters,
    clearAllFilters,
    dateRangeFilterDate,
    setDateRangeFilterDate,
    showDatePicker,
    hireDateRangeFilter,
    setHireDateRangeFilter,
    showHireDatePicker,
    showTerminationDatePicker,
    terminationDateRangeFilter,
    setTerminationDateRangeFilter,
  } = useFiltersContext();
  const { t } = useTranslation();

  if (!isOpen) return null;
  return (
    <div className="filtersModalOverlay">
      <div className="filtersModal">
        <div className="filtersModalHeaderContainer">
          <div className="filtersModalTitle">{t("filters.filters")}</div>
          <button className="filters-modal-close-button" onClick={onClose}>
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/close.svg`}
              alt="close"
            />
          </button>
        </div>
        <div className="filtersModalContent">
          <div className="filtersModalContentSection">
            {showDatePicker ? (
              <div className="filtersDatePickerSection">
                <div
                  className="filterSectionTitle"
                  style={{ paddingTop: "12px" }}
                >
                  <span>{t("filters.sections.dateRange")}</span>
                </div>
                <DatePickerComponent
                  name="filterInterval"
                  handleChange={(update: any) => setDateRangeFilterDate(update)}
                  handleBlur={() => {}}
                  styleProps="filtersIntervalPicker filtersIntervalPickerInputStyle"
                  dateRange={dateRangeFilterDate}
                  value={dateRangeFilterDate}
                />
              </div>
            ) : null}
            {showHireDatePicker ? (
              <div className="filtersDatePickerSection">
                <div
                  className="filterSectionTitle"
                  style={{ paddingTop: "12px" }}
                >
                  <span>{t("filters.sections.hireDateRange")}</span>
                </div>
                <DatePickerComponent
                  name="filterInterval"
                  handleChange={(update: any) => setHireDateRangeFilter(update)}
                  handleBlur={() => {}}
                  styleProps="filtersIntervalPicker filtersIntervalPickerInputStyle"
                  dateRange={hireDateRangeFilter}
                  value={hireDateRangeFilter}
                />
              </div>
            ) : null}
            {showTerminationDatePicker ? (
              <div className="filtersDatePickerSection">
                <div
                  className="filterSectionTitle"
                  style={{ paddingTop: "12px" }}
                >
                  <span>{t("filters.sections.terminationDateRange")}</span>
                </div>
                <DatePickerComponent
                  name="filterInterval"
                  handleChange={(update: any) =>
                    setTerminationDateRangeFilter(update)
                  }
                  handleBlur={() => {}}
                  styleProps="filtersIntervalPicker filtersIntervalPickerInputStyle"
                  dateRange={terminationDateRangeFilter}
                  value={terminationDateRangeFilter}
                />
              </div>
            ) : null}

            {filtersSections && "nameFilters" in filtersSections ? (
              <FiltersSection
                type="name"
                filters={filtersSections.nameFilters}
                selectedFilters={selectedNameFilters}
                setSelectedFilters={setSelectedNameFilters}
              />
            ) : null}
            {filtersSections && "supervisorFilters" in filtersSections ? (
              <FiltersSection
                type="manager"
                filters={filtersSections.supervisorFilters}
                selectedFilters={selectedSupervisorFilters}
                setSelectedFilters={setSelectedSupervisorFilters}
              />
            ) : null}
            {filtersSections && "departmentFilters" in filtersSections ? (
              <FiltersSection
                type="department"
                filters={filtersSections.departmentFilters}
                selectedFilters={selectedDepartmentFilters}
                setSelectedFilters={setSelectedDepartmentFilters}
              />
            ) : null}
            {filtersSections && "typeFilters" in filtersSections ? (
              <FiltersSection
                type="type"
                filters={filtersSections.typeFilters}
                selectedFilters={selectedTypeFilters}
                setSelectedFilters={setSelectedTypeFilters}
              />
            ) : null}
            {filtersSections && "locationFilters" in filtersSections ? (
              <FiltersSection
                type="location"
                filters={filtersSections.locationFilters}
                selectedFilters={selectedLocationFilters}
                setSelectedFilters={setSelectedLocationFilters}
              />
            ) : null}
            {filtersSections && "timeOffTypeFilters" in filtersSections ? (
              <FiltersSection
                type="timeOffType"
                filters={filtersSections.timeOffTypeFilters}
                selectedFilters={selectedTimeOffTypeFilters}
                setSelectedFilters={setSelectedTimeOffTypeFilters}
              />
            ) : null}
            {filtersSections && "timeOffStatusFilters" in filtersSections ? (
              <FiltersSection
                type="timeOffStatus"
                filters={filtersSections.timeOffStatusFilters}
                selectedFilters={selectedTimeOffStatusFilters}
                setSelectedFilters={setSelectedTimeOffStatusFilters}
              />
            ) : null}
            {filtersSections && "positionFilters" in filtersSections ? (
              <FiltersSection
                type="position"
                filters={filtersSections.positionFilters}
                selectedFilters={selectedEmployeePositionFilters}
                setSelectedFilters={setSelectedEmployeePositionFilters}
              />
            ) : null}
            {filtersSections && "contractTypeFilters" in filtersSections ? (
              <FiltersSection
                type="contractType"
                filters={filtersSections.contractTypeFilters}
                selectedFilters={selectedEmployeeContractTypeFilters}
                setSelectedFilters={setSelectedEmployeeContractTypeFilters}
              />
            ) : null}
            {filtersSections && "statusFilters" in filtersSections ? (
              <FiltersSection
                type="status"
                filters={filtersSections.statusFilters}
                selectedFilters={selectedEmployeeStatusFilters}
                setSelectedFilters={setSelectedEmployeeStatusFilters}
              />
            ) : null}
            {filtersSections && "worksiteFilters" in filtersSections ? (
              <FiltersSection
                type="worksite"
                filters={filtersSections.worksiteFilters}
                selectedFilters={selectedEmployeeWorksiteFilters}
                setSelectedFilters={setSelectedEmployeeWorksiteFilters}
              />
            ) : null}
          </div>
          <div className="filtersModalActionButtons">
            <div className="clearAllButton" onClick={clearAllFilters}>
              {t("filters.buttons.clearAll")}
            </div>
            <div className="filtersModalButtons">
              <Button type="button" onClick={onClose}>
                {t("filters.buttons.cancel")}
              </Button>
              <Button
                appearance="primary"
                type="button"
                className="applyButton"
                onClick={onClose}
              >
                {t("filters.buttons.apply")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
