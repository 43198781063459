import { useState } from "react";
import { Button, Image, Select, SelectProps } from "@fluentui/react-components";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./AttendanceLogHours.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import TrackLogHourComponent from "./TrackLogHourComponent";
import { useTranslation } from "react-i18next";
import attendanceService from "../../../services/attendance";
import { useAttendancesStore } from "../../../store/attendancesStore";
import { useStore } from "zustand";
import { toast } from "react-toastify";
import { Track } from "../../../types";

export default function AttendanceLogHours(props: SelectProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [date, setDate] = useState(new Date());
  const [fromHour, setFromHour] = useState(new Date());
  const [toHour, setToHour] = useState(
    new Date(new Date().setHours(new Date().getHours() + 1))
  );
  const [tracks, setTracks] = useState<Track[]>([]);
  const { t } = useTranslation();
  const attendancesStore = useStore(useAttendancesStore);
  const [loading, setLoading] = useState<boolean>(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
    setTracks([]);
  };

  const calculateHourDifference = () => {
    const difference = Math.abs(toHour.getTime() - fromHour.getTime());
    const hours = Math.floor(difference / (1000 * 3600));
    const minutes = Math.floor((difference % (1000 * 3600)) / (1000 * 60));
    return `${hours}h${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const initialValues = {
    date: new Date(),
    location: "",
    type: "",
    fromHour: new Date(),
    toHour: new Date(new Date().setHours(new Date().getHours() + 1)),
    duration: calculateHourDifference(),
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .nullable()
      .required(t("attendanceOwnHr.attendanceLogHours.dateIsRequired")),
    location: Yup.string().required(
      t("attendanceOwnHr.attendanceLogHours.locationIsRequired")
    ),
    type: Yup.string().required(
      t("attendanceOwnHr.attendanceLogHours.typeIsRequired")
    ),
    fromHour: Yup.string().required(
      t("attendanceOwnHr.attendanceLogHours.hourIsRequired")
    ),
    toHour: Yup.string()
      .required(t("attendanceOwnHr.attendanceLogHours.hourIsRequired"))
      .test(
        "is-after",
        t("attendanceOwnHr.attendanceLogHours.toHourAfterFromHour"),
        function (value) {
          const { fromHour } = this.parent;
          return fromHour && value && new Date(value) >= new Date(fromHour);
        }
      ),
  });

  const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    const duration = calculateHourDifference();
    const updatedValues = {
      ...values,
      duration: duration,
      id: tracks.length + 1,
    };
    setTracks([...tracks, updatedValues] as any);
    setSubmitting(false);
    resetForm({
      values: {
        date: new Date(),
        location: "",
        type: "",
        fromHour: new Date(),
        toHour: new Date(new Date().setHours(new Date().getHours() + 1)),
        duration: calculateHourDifference(),
      },
    });
    setFromHour(new Date());
    setToHour(new Date(new Date().setHours(new Date().getHours() + 1)));
    setDate(new Date());
  };

  const handleDeleteTrack = (id: number) => {
    setTracks(tracks?.filter((track) => track?.id !== id));
  };

  const sendTracks = async () => {
    setLoading(true);
    try {
      const result = await attendanceService.sendTracks(tracks);
      if ("status" in result && result?.status === "error") {
        toast.error(
          t(`attendanceOwnHr.attendanceLogHours.errors.${result?.message}`),
          {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          }
        );
      }
      if ("attendances" in result) {
        attendancesStore.addAttendances(result?.attendances);
        setTracks([]);
        setIsExpanded(false);
        toast.success(t("attendanceOwnHr.attendanceLogHours.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("attendanceOwnHr.attendanceLogHours.errors.sending"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`attendanceLogHoursContainer ${isExpanded ? "expanded" : ""}`}
    >
      <div className="attendanceLogHoursContent">
        <div
          className={`attendanceLogHoursTitle ${
            isExpanded ? "expandedAttendanceLogHoursTitle" : ""
          }`}
        >
          {t("attendanceOwnHr.attendanceLogHours.attendance")}
        </div>
        <div className="attendanceLogHoursActions">
          {!isExpanded && (
            <>
              <Button
                className="attendanceLogHoursButton"
                appearance="primary"
                onClick={handleExpand}
              >
                {t("attendanceOwnHr.attendanceLogHours.logHours")}
              </Button>
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/arrowDown.svg`}
                alt="arrowDown"
                onClick={handleExpand}
              />
            </>
          )}
          {isExpanded && (
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/arrowUp.svg`}
              alt="arrowUp"
              onClick={handleCollapse}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => (
            <Form className="attendanceLogHoursForm">
              <div className="attendanceLogHoursFormContainer">
                <div className="expandedContent">
                  <div className="attendanceLogHoursInputContainer">
                    <label className="defaultAttendanceLogHoursLabel">
                      {t("attendanceOwnHr.attendanceLogHours.date")}
                    </label>

                    <DatePicker
                      name="date"
                      selected={date}
                      className="attendanceLogHoursDatePickerInput"
                      isClearable
                      onChange={(date) => {
                        setDate(date!);
                        handleChange({
                          target: { name: "date", value: date },
                        });
                      }}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="attendanceLogHoursInputContainer">
                    <label className="defaultAttendanceLogHoursLabel">
                      {t("attendanceOwnHr.attendanceLogHours.location")}
                    </label>
                    <Select
                      name="location"
                      className="attendanceLogHoursInput"
                      appearance="filled-lighter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location}
                    >
                      <option value="">
                        {t("attendanceOwnHr.attendanceLogHours.selectLocation")}
                      </option>
                      <option value="remote">
                        {t("attendanceOwnHr.attendanceLogHours.remote")}
                      </option>
                      <option value="office">
                        {t("attendanceOwnHr.attendanceLogHours.office")}
                      </option>
                    </Select>
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="attendanceLogHoursInputContainer">
                    <label className="defaultAttendanceLogHoursLabel">
                      {t("attendanceOwnHr.attendanceLogHours.type")}
                    </label>
                    <Select
                      name="type"
                      className="attendanceLogHoursInput"
                      appearance="filled-lighter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                    >
                      <option value="">
                        {t("attendanceOwnHr.attendanceLogHours.selectType")}
                      </option>
                      <option value="work">
                        {t("attendanceOwnHr.attendanceLogHours.work")}
                      </option>
                      <option value="break">
                        {t("attendanceOwnHr.attendanceLogHours.break")}
                      </option>
                    </Select>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="attendanceLogHoursInputContainer">
                    <label className="defaultAttendanceLogHoursLabel">
                      {t("attendanceOwnHr.attendanceLogHours.from")}
                    </label>
                    <div className="datePickerContainer">
                      <DatePicker
                        name="fromHour"
                        className="attendanceLogHoursDateInput"
                        selected={fromHour}
                        onChange={(date) => {
                          setFromHour(date!);
                          handleChange({
                            target: { name: "fromHour", value: date },
                          });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      <Image
                        className="inputClock"
                        src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                        alt="inputClock"
                      />
                    </div>
                    <ErrorMessage
                      name="fromHour"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="attendanceLogHoursInputContainer">
                    <label className="defaultAttendanceLogHoursLabel">
                      {t("attendanceOwnHr.attendanceLogHours.to")}
                    </label>
                    <div className="datePickerContainer">
                      <DatePicker
                        name="toHour"
                        className="attendanceLogHoursDateInput"
                        selected={toHour}
                        onChange={(date) => {
                          setToHour(date!);
                          handleChange({
                            target: { name: "toHour", value: date },
                          });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      <Image
                        className="inputClock"
                        src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                        alt="inputClock"
                      />
                    </div>
                    <ErrorMessage
                      name="toHour"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="attendanceLogHoursInputContainer attendanceHoursInputDurationContainer">
                    <div className="hourDifference">
                      <span className="hourDifferenceText">
                        {calculateHourDifference()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="addTimeContainer">
                  <Button
                    appearance="primary"
                    type="submit"
                    className="addTimeButton"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? t("attendanceOwnHr.attendanceLogHours.submitting")
                      : t("attendanceOwnHr.attendanceLogHours.addTime")}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div>
        {tracks.map((data, index) => (
          <div key={data?.id}>
            <TrackLogHourComponent
              data={data}
              index={index}
              onDelete={handleDeleteTrack}
            />
          </div>
        ))}
      </div>
      {tracks && tracks.length > 0 && (
        <div className="saveTrackLogHours">
          <Button
            appearance="primary"
            className="addTimeButton"
            onClick={sendTracks}
            disabled={loading}
          >
            {t("attendanceOwnHr.attendanceLogHours.save")}
          </Button>
        </div>
      )}
    </div>
  );
}
