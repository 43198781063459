import Modal from "../../Modal";
import { useTranslation } from "react-i18next";
import "./AddRole.css";
import { ErrorMessage, Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button, Input, Checkbox } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useSettingsStore } from "../../../store/settingsStore";
import settingsService from "../../../services/settings";
import { IAddRole } from "../../../types";

const AddRole: React.FC<IAddRole> = ({
  isModalOpen,
  closeModal,
  customRoleId,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    permissions: [],
  });
  const settingsStore = useStore(useSettingsStore);

  useEffect(() => {
    if (customRoleId) {
      const customRole = settingsStore?.customRoles?.find(
        (cR) => cR?.Id === customRoleId
      );
      if (customRole) {
        let perms: number[] = [];
        customRole?.Permissions?.forEach((perm) => perms.push(perm?.Id));
        setInitialValues({
          name: customRole?.RoleName,
          description: customRole?.Description,
          permissions: perms,
        });
      }
    } else {
      setInitialValues({
        name: "",
        description: "",
        permissions: [],
      });
    }
  }, [customRoleId]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t("settings.rolesAndPermissions.modals.fieldRequired")
    ),
    permissions: Yup.array()
      .min(1, t("settings.rolesAndPermissions.modals.fieldRequired"))
      .required(t("settings.rolesAndPermissions.modals.fieldRequired")),
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      if (customRoleId) {
        const result = await settingsService.editRole(
          customRoleId,
          values.name,
          values.description,
          values.permissions
        );
        if ("status" in result && result?.status === "error") {
          toast.error(
            t(`settings.rolesAndPermissions.modals.${result?.message}`),
            {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            }
          );
        } else if ("customRole" in result) {
          settingsStore.replaceCustomRole(result?.customRole);
          closeModal();
          toast.success(t("settings.rolesAndPermissions.modals.success"), {
            position: "bottom-right",
          });
        }
      } else {
        const result = await settingsService.createRole(
          values.name,
          values.description,
          values.permissions
        );
        if ("status" in result && result?.status === "error") {
          toast.error(
            t(`settings.rolesAndPermissions.modals.${result?.message}`),
            {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            }
          );
        } else if ("customRole" in result) {
          settingsStore.addCustomRole(result?.customRole);
          closeModal();
          toast.success(t("settings.rolesAndPermissions.modals.success"), {
            position: "bottom-right",
          });
        }
      }
    } catch (e) {
      toast.error(t("settings.rolesAndPermissions.modals.error"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Targo360"
        subtitle={t("settings.rolesAndPermissions.modals.settingsRoles")}
        contentTitle={t("settings.rolesAndPermissions.modals.createRole")}
        headerStyle="addRoleModalHeader"
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="addRoleForm">
              <div className="addRoleFormField">
                <label className="addRoleFieldLabel">
                  {t("settings.rolesAndPermissions.modals.name")}
                </label>
                <Input
                  name="name"
                  value={values.name}
                  className="addRoleInputStyle"
                  appearance="filled-lighter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="addRoleFormField">
                <label className="addRoleFieldLabel">
                  {t("settings.rolesAndPermissions.modals.description")}
                </label>
                <Input
                  name="description"
                  value={values.description}
                  className="addRoleInputStyle"
                  appearance="filled-lighter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="addRoleFormField">
                <label className="addRoleFieldLabel">
                  {t("settings.rolesAndPermissions.modals.permissions")}
                </label>
                <FieldArray name="permissions">
                  {({ push, remove }) => (
                    <div>
                      {settingsStore.permissions.map((permission) => (
                        <div key={permission?.Id}>
                          <Checkbox
                            shape="circular"
                            label={
                              <div className="addRoleRadioLabelContainer">
                                <div>{permission?.Category}</div>
                                <div>{permission?.Name}</div>
                              </div>
                            }
                            checked={values.permissions.includes(
                              permission?.Id
                            )}
                            onChange={() => {
                              if (
                                values.permissions?.includes(permission?.Id)
                              ) {
                                setFieldValue(
                                  "permissions",
                                  values.permissions.filter(
                                    (p: number) => p !== permission?.Id
                                  )
                                );
                              } else {
                                push(permission?.Id);
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
                <ErrorMessage
                  name="permissions"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="addRoleSubmitButtonContainer">
                <Button
                  className="addRoleSubmitButton"
                  type="submit"
                  appearance="primary"
                  disabled={isSubmitting}
                >
                  {t("settings.rolesAndPermissions.modals.save")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddRole;
