import httpService from "./http";

const sendMessage = async (
  firstName: string,
  lastName: string,
  title: string,
  topic: string,
  message: string
) => {
  const response = await httpService.post("/contact-us", {
    firstName,
    lastName,
    title,
    topic,
    message,
  });
  return response.data;
};

const contactUsService = { sendMessage };
export default contactUsService;
