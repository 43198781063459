import { Image } from "@fluentui/react-components";
import "./OwnHR.css";
import PersonCard from "./PersonCard";
import { useTranslation } from "react-i18next";

export default function OwnHR() {
  const { t } = useTranslation();

  return (
    <div className="ownHrComponent">
      <h2 className="ownHrTitle">
        {t("initialSetup.steps.step1.ownHr.title")}
      </h2>
      <p className="ownHrDescription">
        {t("initialSetup.steps.step1.ownHr.description")}
      </p>
      <div className="ownHrContainer">
        <ul>
          <li>Simplified Workforce Management</li>
          <li>Automated Time-Off Tracking</li>
          <li>Efficient Leave Management</li>
          <li>Effortless Time-Off Requests</li>
          <li>Quick Access to Employee Information</li>
          <li>Improved Decision-Making with Real-Time Data</li>
          <li>Secure Data Handling</li>
          <li>User-Friendly Interface for All Team Members</li>
        </ul>
        <div className="imageContainer">
          <Image
            className="image"
            src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/ownhr.jpg`}
          />
        </div>
      </div>
    </div>
  );
}
