import httpService from "./http";
import {
  TimeOffType,
  UserBalance,
  TimeOff,
  User,
  OwnDepartment,
} from "../types";

const getTimeOffTypes = async () => {
  const response = await httpService.get("/time-off/types");
  const timeOffTypes: TimeOffType[] = [];
  response.data.forEach((timeOffTypeData: any) => {
    timeOffTypes.push({
      Id: timeOffTypeData?.TimeOffTypeId,
      Name: timeOffTypeData?.TimeOffTypeName,
    });
  });
  return timeOffTypes;
};

const importCsvFile = async (csvData: any) => {
  const response = await httpService.post("/time-off/import", { csvData });
  const timeOffTypes: TimeOffType[] = [];
  if ("errors" in response.data) {
    return response.data;
  }
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    timeOffTypes.push({
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
      Description: timeOffType?.TimeOffTypeDescription,
    });
  });

  const timeOffs: TimeOff[] = [];
  response?.data?.timeOffs?.forEach((timeOff: any) => {
    let tO: TimeOff = {
      Id: timeOff?.TimeOffId,
      EmployeeId: timeOff?.EmployeeId,
      EmployeeName: timeOff?.OwnEmployee?.EmployeeName,
      DepartmentName: timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: timeOff?.SupervisorId,
      SupervisorName:
        timeOff?.SupervisorName !== "" && timeOff?.SupervisorName !== null
          ? timeOff?.SupervisorName
          : "Unassigned",
      StartDate: timeOff?.TimeOffStartDate,
      EndDate: timeOff?.TimeOffEndDate,
      DaysNumber: timeOff?.TimeOffDaysCount,
      RequestStatus: timeOff?.TimeOffStatus,
      LeaveType: timeOffTypes.find(
        (el: TimeOffType) => el?.Id === timeOff?.TimeOffTypeId
      ),
      RequestDate: timeOff?.RequestedDate,
      UpdatedDate: timeOff?.UpdatedDate,
      Comment: timeOff?.TimeOffComment,
      Overlaps: [],
    };
    if (timeOff?.Overlaps && timeOff?.Overlaps.length > 0) {
      timeOff?.Overlaps?.forEach((overlap: any) => {
        tO?.Overlaps?.push({
          Id: overlap?.overlapId,
          EmployeeId: overlap?.EmployeeId,
          EmployeeName: overlap?.OwnEmployee?.EmployeeName,
          DepartmentName: overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
          SupervisorId: overlap?.SupervisorId,
          SupervisorName:
            overlap?.SupervisorName !== "" && overlap?.SupervisorName !== null
              ? overlap?.SupervisorName
              : "Unassigned",
          StartDate: overlap?.TimeOffStartDate,
          EndDate: overlap?.TimeOffEndDate,
          DaysNumber: overlap?.TimeOffDaysCount,
          RequestStatus: overlap?.TimeOffStatus,
          LeaveType: timeOffTypes.find(
            (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
          ),
          RequestDate: overlap?.RequestedDate,
          UpdatedDate: overlap?.UpdatedDate,
          Comment: overlap?.TimeOffComment,
        });
      });
    }
    timeOffs.push(tO);
  });
  return { timeOffs };
};

const getTimeOffsData = async () => {
  const response = await httpService.get("/time-off");
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    timeOffTypes.push({
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
      Description: timeOffType?.TimeOffTypeDescription,
    });
  });

  const userBalance: UserBalance = {
    AnnualDays: response?.data?.user?.Employee?.AnnualDays,
    AnnualBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    BereavementBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 4
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    ParentalBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 3
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    SickBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 2
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    UnpaidBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 5
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
  };
  const timeOffs: TimeOff[] = [];
  response?.data?.timeOffs?.forEach((timeOff: any) => {
    let tO: TimeOff = {
      Id: timeOff?.TimeOffId,
      EmployeeId: timeOff?.EmployeeId,
      EmployeeName: timeOff?.OwnEmployee?.EmployeeName,
      DepartmentName: timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: timeOff?.SupervisorId,
      SupervisorName:
        timeOff?.SupervisorName !== "" && timeOff?.SupervisorName !== null
          ? timeOff?.SupervisorName
          : "Unassigned",
      StartDate: timeOff?.TimeOffStartDate,
      EndDate: timeOff?.TimeOffEndDate,
      DaysNumber: timeOff?.TimeOffDaysCount,
      RequestStatus: timeOff?.TimeOffStatus,
      LeaveType: timeOffTypes.find(
        (el: TimeOffType) => el?.Id === timeOff?.TimeOffTypeId
      ),
      RequestDate: timeOff?.RequestedDate,
      UpdatedDate: timeOff?.UpdatedDate,
      Comment: timeOff?.TimeOffComment,
      Overlaps: [],
    };
    if (timeOff?.Overlaps && timeOff?.Overlaps.length > 0) {
      timeOff?.Overlaps?.forEach((overlap: any) => {
        tO?.Overlaps?.push({
          Id: overlap?.overlapId,
          EmployeeId: overlap?.EmployeeId,
          EmployeeName: overlap?.OwnEmployee?.EmployeeName,
          DepartmentName: overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
          SupervisorId: overlap?.SupervisorId,
          SupervisorName:
            overlap?.SupervisorName !== "" && overlap?.SupervisorName !== null
              ? overlap?.SupervisorName
              : "Unassigned",
          StartDate: overlap?.TimeOffStartDate,
          EndDate: overlap?.TimeOffEndDate,
          DaysNumber: overlap?.TimeOffDaysCount,
          RequestStatus: overlap?.TimeOffStatus,
          LeaveType: timeOffTypes.find(
            (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
          ),
          RequestDate: overlap?.RequestedDate,
          UpdatedDate: overlap?.UpdatedDate,
          Comment: overlap?.TimeOffComment,
        });
      });
    }
    timeOffs.push(tO);
  });
  return {
    annualDays: response?.data?.annualDays,
    balanceDays: response?.data?.balanceDays,
    teamAnnualDays: response?.data?.teamAnnualDays,
    teamBalanceDays: response?.data?.teamBalanceDays,
    timeOffTypes,
    userBalance,
    timeOffs,
  };
};

const addTimeOff = async (
  startDate: any,
  endDate: any,
  typeId: number,
  comment: string
) => {
  const response = await httpService.post("/time-off/add", {
    startDate,
    endDate,
    typeId,
    comment,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response.data;
  }
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    timeOffTypes.push({
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
      Description: timeOffType?.TimeOffTypeDescription,
    });
  });
  let timeOff: TimeOff = {
    Id: response?.data?.timeOff?.TimeOffId,
    EmployeeId: response?.data?.timeOff?.EmployeeId,
    EmployeeName: response?.data?.timeOff?.OwnEmployee?.EmployeeName,
    DepartmentName:
      response?.data?.timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.timeOff?.SupervisorId,
    SupervisorName:
      response?.data?.timeOff?.SupervisorName !== "" &&
      response?.data?.timeOff?.SupervisorName !== null
        ? response?.data?.timeOff?.SupervisorName
        : "Unassigned",
    StartDate: response?.data?.timeOff?.TimeOffStartDate,
    EndDate: response?.data?.timeOff?.TimeOffEndDate,
    DaysNumber: response?.data?.timeOff?.TimeOffDaysCount,
    RequestStatus: response?.data?.timeOff?.TimeOffStatus,
    LeaveType: timeOffTypes.find(
      (el: TimeOffType) => el?.Id === response?.data?.timeOff?.TimeOffTypeId
    ),
    RequestDate: response?.data?.timeOff?.RequestedDate,
    UpdatedDate: response?.data?.timeOff?.UpdatedDate,
    Comment: response?.data?.timeOff?.TimeOffComment,
    Overlaps: [],
  };
  if (
    response?.data?.timeOff?.Overlaps &&
    response?.data?.timeOff?.Overlaps.length > 0
  ) {
    response?.data?.timeOff?.Overlaps?.forEach((overlap: any) => {
      timeOff?.Overlaps?.push({
        Id: overlap?.overlapId,
        EmployeeId: overlap?.EmployeeId,
        EmployeeName: overlap?.OwnEmployee?.EmployeeName,
        DepartmentName: overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: overlap?.SupervisorId,
        SupervisorName:
          overlap?.SupervisorName !== "" && overlap?.SupervisorName != null
            ? overlap?.SupervisorName
            : "Unassigned",
        StartDate: overlap?.TimeOffStartDate,
        EndDate: overlap?.TimeOffEndDate,
        DaysNumber: overlap?.TimeOffDaysCount,
        RequestStatus: overlap?.TimeOffStatus,
        LeaveType: timeOffTypes.find(
          (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
        ),
        RequestDate: overlap?.RequestedDate,
        UpdatedDate: overlap?.UpdatedDate,
        Comment: overlap?.TimeOffComment,
      });
    });
  }
  const userBalance: UserBalance = {
    AnnualDays: response?.data?.user?.Employee?.AnnualDays,
    AnnualBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    BereavementBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 4
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    ParentalBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 3
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    SickBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 2
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    UnpaidBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 5
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
  };
  return { timeOff, userBalance };
};

const approveTimeOff = async (timeOffId: number) => {
  const response = await httpService.patch("/time-off/approve", { timeOffId });
  return response.data;
};

const denyTimeOff = async (timeOffId: number) => {
  const response = await httpService.patch("/time-off/deny", { timeOffId });
  return response.data;
};

const modalsData = async () => {
  const response = await httpService.get("/time-off/modals-data");
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    users.push({
      Id: user?.UserId,
      EmployeeId: user?.Employee?.EmployeeId,
      FirstName: user?.FirstName,
      LastName: user?.LastName,
      Email: user?.Email,
      Role: [
        {
          Type: "builtIn",
          Id: user?.Roles[0]?.RoleId,
          RoleName: user?.Roles[0]?.RoleName,
        },
      ],
      Department: user?.OwnDepartment
        ? {
            Id: user?.OwnDepartment?.DepartmentId,
            Name: user?.OwnDepartment?.DepartmentName,
          }
        : null,
    });
  });
  const departments: OwnDepartment[] = [];
  response?.data?.departments?.forEach((department: any) => {
    departments.push({
      Id: department?.DepartmentId,
      Name: department?.DepartmentName,
    });
  });
  const managers: User[] = [];
  response?.data?.managers?.forEach((manager: any) => {
    managers.push({
      Id: manager?.UserId,
      EmployeeId: manager?.Employee?.EmployeeId,
      FirstName: manager?.FirstName,
      LastName: manager?.LastName,
      Email: manager?.Email,
      Role: [
        {
          Type: "builtIn",
          Id: manager?.Roles[0]?.RoleId,
          RoleName: manager?.Roles[0]?.RoleName,
        },
      ],
    });
  });
  return { users, departments, managers };
};

const addEmployeeTimeOff = async (
  startDate: any,
  endDate: any,
  typeId: number,
  status: string,
  userId: number,
  departmentId: number,
  comment: string
) => {
  const response = await httpService.post("/time-off/employee/add", {
    startDate,
    endDate,
    typeId,
    status,
    userId,
    departmentId,
    comment,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    timeOffTypes.push({
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
      Description: timeOffType?.TimeOffTypeDescription,
    });
  });
  let timeOff: TimeOff = {
    Id: response?.data?.timeOff?.TimeOffId,
    EmployeeId: response?.data?.timeOff?.EmployeeId,
    EmployeeName: response?.data?.timeOff?.OwnEmployee?.EmployeeName,
    DepartmentName:
      response?.data?.timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.timeOff?.SupervisorId,
    SupervisorName:
      response?.data?.timeOff?.SupervisorName !== "" &&
      response?.data?.timeOff?.SupervisorName !== null
        ? response?.data?.timeOff?.SupervisorName
        : "Unassigned",
    StartDate: response?.data?.timeOff?.TimeOffStartDate,
    EndDate: response?.data?.timeOff?.TimeOffEndDate,
    DaysNumber: response?.data?.timeOff?.TimeOffDaysCount,
    RequestStatus: response?.data?.timeOff?.TimeOffStatus,
    LeaveType: timeOffTypes.find(
      (el: TimeOffType) => el?.Id === response?.data?.timeOff?.TimeOffTypeId
    ),
    RequestDate: response?.data?.timeOff?.RequestedDate,
    UpdatedDate: response?.data?.timeOff?.UpdatedDate,
    Comment: response?.data?.timeOff?.TimeOffComment,
    Overlaps: [],
  };
  if (
    response?.data?.timeOff?.Overlaps &&
    response?.data?.timeOff?.Overlaps.length > 0
  ) {
    response?.data?.timeOff?.Overlaps?.forEach((overlap: any) => {
      timeOff?.Overlaps?.push({
        Id: overlap?.overlapId,
        EmployeeId: overlap?.EmployeeId,
        EmployeeName: overlap?.OwnEmployee?.EmployeeName,
        DepartmentName: overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: overlap?.SupervisorId,
        SupervisorName:
          overlap?.SupervisorName !== "" && overlap?.SupervisorName != null
            ? overlap?.SupervisorName
            : "Unassigned",
        StartDate: overlap?.TimeOffStartDate,
        EndDate: overlap?.TimeOffEndDate,
        DaysNumber: overlap?.TimeOffDaysCount,
        RequestStatus: overlap?.TimeOffStatus,
        LeaveType: timeOffTypes.find(
          (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
        ),
        RequestDate: overlap?.RequestedDate,
        UpdatedDate: overlap?.UpdatedDate,
        Comment: overlap?.TimeOffComment,
      });
    });
  }

  const userBalance: UserBalance = {
    AnnualDays: response?.data?.user?.Employee?.AnnualDays,
    AnnualBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    BereavementBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 4
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    ParentalBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 3
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    SickBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 2
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    UnpaidBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 5
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
  };

  return { timeOff, userBalance };
};

const editEmployeeTimeOff = async (
  timeOffId: number,
  startDate: any,
  endDate: any,
  typeId: number,
  status: string,
  userId: number,
  departmentId: number,
  comment: string
) => {
  const response = await httpService.patch("/time-off/employee/edit", {
    timeOffId,
    startDate,
    endDate,
    typeId,
    status,
    userId,
    departmentId,
    comment,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    timeOffTypes.push({
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
      Description: timeOffType?.TimeOffTypeDescription,
    });
  });
  let timeOff: TimeOff = {
    Id: response?.data?.timeOff?.TimeOffId,
    EmployeeId: response?.data?.timeOff?.EmployeeId,
    EmployeeName: response?.data?.timeOff?.OwnEmployee?.EmployeeName,
    DepartmentName:
      response?.data?.timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.timeOff?.SupervisorId,
    SupervisorName:
      response?.data?.timeOff?.SupervisorName !== "" &&
      response?.data?.timeOff?.SupervisorName !== null
        ? response?.data?.timeOff?.SupervisorName
        : "Unassigned",
    StartDate: response?.data?.timeOff?.TimeOffStartDate,
    EndDate: response?.data?.timeOff?.TimeOffEndDate,
    DaysNumber: response?.data?.timeOff?.TimeOffDaysCount,
    RequestStatus: response?.data?.timeOff?.TimeOffStatus,
    LeaveType: timeOffTypes.find(
      (el: TimeOffType) => el?.Id === response?.data?.timeOff?.TimeOffTypeId
    ),
    RequestDate: response?.data?.timeOff?.RequestedDate,
    UpdatedDate: response?.data?.timeOff?.UpdatedDate,
    Comment: response?.data?.timeOff?.TimeOffComment,
    Overlaps: [],
  };
  if (
    response?.data?.timeOff?.Overlaps &&
    response?.data?.timeOff?.Overlaps.length > 0
  ) {
    response?.data?.timeOff?.Overlaps?.forEach((overlap: any) => {
      timeOff?.Overlaps?.push({
        Id: overlap?.overlapId,
        EmployeeId: overlap?.EmployeeId,
        EmployeeName: overlap?.OwnEmployee?.EmployeeName,
        DepartmentName: overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: overlap?.SupervisorId,
        SupervisorName:
          overlap?.SupervisorName !== "" && overlap?.SupervisorName != null
            ? overlap?.SupervisorName
            : "Unassigned",
        StartDate: overlap?.TimeOffStartDate,
        EndDate: overlap?.TimeOffEndDate,
        DaysNumber: overlap?.TimeOffDaysCount,
        RequestStatus: overlap?.TimeOffStatus,
        LeaveType: timeOffTypes.find(
          (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
        ),
        RequestDate: overlap?.RequestedDate,
        UpdatedDate: overlap?.UpdatedDate,
        Comment: overlap?.TimeOffComment,
      });
    });
  }
  const userBalance: UserBalance = {
    AnnualDays: response?.data?.user?.Employee?.AnnualDays,
    AnnualBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    BereavementBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 4
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    ParentalBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 3
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    SickBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 2
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    UnpaidBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 5
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
  };

  return {
    annualDays: response?.data?.annualDays,
    balanceDays: response?.data?.balanceDays,
    teamAnnualDays: response?.data?.teamAnnualDays,
    teamBalanceDays: response?.data?.teamBalanceDays,
    timeOff,
    userBalance,
  };
};

const deleteTimeOff = async (timeOffId: number) => {
  const response = await httpService.post("/time-off/delete", {
    timeOffId,
  });
  if ("status" in response.data && response.data.status === "error") {
    return response.data;
  }
  const userBalance: UserBalance = {
    AnnualDays: response?.data?.user?.Employee?.AnnualDays,
    AnnualBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    BereavementBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 4
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    ParentalBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 3
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    SickBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 2
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    UnpaidBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 5
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
  };
  return { userBalance };
};

const timeOffService = {
  getTimeOffTypes,
  importCsvFile,
  getTimeOffsData,
  addTimeOff,
  approveTimeOff,
  denyTimeOff,
  modalsData,
  addEmployeeTimeOff,
  editEmployeeTimeOff,
  deleteTimeOff,
};
export default timeOffService;
