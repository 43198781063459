const moment = require("moment");

const getRandomDate = () => {
  const start = moment().startOf("year");
  const end = moment().endOf("year");
  const diff = end.diff(start, "days");
  const randomDiff = Math.floor(Math.random() * diff);
  const randomDate = start.clone().add(randomDiff, "days");
  return randomDate;
};

const getRandomElement = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

const checkHasPermissions = (permissions, category, name) => {
  return permissions.some(
    (permission) =>
      permission?.Category === category && permission?.Name === name
  );
};

const helpers = { getRandomDate, getRandomElement, checkHasPermissions };
export default helpers;
