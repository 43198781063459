import * as msTeams from "@microsoft/teams-js";
import axios, { AxiosInstance } from "axios";
import { useTeamsContextStore } from "../store/teamsContextStore";

interface IHTTPServiceConfig {
  baseURL: string;
}

const getAuthToken = async (): Promise<string> => {
  return await msTeams.authentication.getAuthToken();
};

const getAadTokenFromTeams = async (): Promise<string> => {
  let result: string = "";
  try {
    await msTeams.app.initialize();
    result = await getAuthToken();
  } catch (error) {
    console.log("error getting aad in interceptor", error);
  }
  return result;
};

class HttpService {
  private readonly service: AxiosInstance;
  constructor({ baseURL }: IHTTPServiceConfig) {
    this.service = axios.create({ baseURL });
  }

  getService(): AxiosInstance {
    this.service.interceptors.request.use(async (config) => {
      const teamsContextStore = useTeamsContextStore.getState();
      const aadToken: string = await getAadTokenFromTeams();
      if (aadToken.length > 0) {
        teamsContextStore.setAadToken(aadToken);
        config.headers.Authorization = `Bearer ${aadToken}`;
      }
      return config;
    });
    return this.service;
  }
}

export default new HttpService({
  // baseURL: "http://localhost:3000",
  baseURL: process.env.REACT_APP_TARGO_API_URL ?? "",
}).getService();
