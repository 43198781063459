import { create } from "zustand";
import { IUseSettingsStore, User } from "../types";

export const useSettingsStore = create<IUseSettingsStore>((set) => ({
  permissions: [],
  customRoles: [],
  roles: [],
  users: [],
  allRoles: [],
  totalSeats: 0,
  setPermissions: (permissions) => set({ permissions }),
  setCustomRoles: (customRoles) => set({ customRoles }),
  setRoles: (roles) => set({ roles }),
  addCustomRole: (newCustomRole) =>
    set((state) => ({ customRoles: [newCustomRole, ...state.customRoles] })),
  replaceCustomRole: (updatedCustomRole) => {
    set((state) => ({
      customRoles: state.customRoles.map((customRole) =>
        customRole.Id === updatedCustomRole.Id ? updatedCustomRole : customRole
      ),
    }));
  },
  setUsers: (users) => set({ users }),
  setAllRoles: (allRoles) => set({ allRoles }),
  removeUserRoles: (userId) => {
    set((state) => ({
      users: state?.users?.map((user) =>
        user?.Id === userId ? { ...user, Role: undefined } : user
      ) as User[],
    }));
  },
  replaceUserRole: (newRole, userId) => {
    set((state) => ({
      users: state?.users?.map((user) =>
        user?.Id === userId ? { ...user, Role: [newRole] } : user
      ),
    }));
  },
  setUserRoles: (userRoles, userId) => {
    set((state) => ({
      users: state?.users?.map((user) =>
        user?.Id === userId ? { ...user, Role: userRoles } : user
      ),
    }));
  },
  setTotalSeats: (totalSeats) => set({ totalSeats }),
  deleteUsersById: (userIds) =>
    set((state) => ({
      users: state?.users?.filter((user) => !userIds.includes(user?.Id)),
    })),
  deleteCustomRoleById: (customRoleId) =>
    set((state) => ({
      customRoles: state.customRoles.filter(
        (customRole) => customRole.Id !== customRoleId
      ),
    })),
}));
