import { Image, Input } from "@fluentui/react-components";
import "./SimpleNavbar.css";
import { MenuItemNavigation } from "../AbsencesOwnHr/MenuItemNavigation";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface ISimpleNavbar {
  searchField: string;
  setSearchField: (searchField: string) => void;
}

const SimpleNavbar: React.FC<ISimpleNavbar> = ({
  searchField,
  setSearchField,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchField(event.target.value);
  };

  return (
    <nav className="simpleAbsencesNavbar">
      <div className="simpleAbsencesNavContainer">
        <div className="simpleAbsencesInfoContainer">
          <div className="simpleAbsencensInfoWrapper">
            <div className="simpleAbsencesLogoContainer">
              <Image
                className="simpleAbsencesLogo"
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/Avatar.svg`}
                alt="logo"
              />
            </div>
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/separationLine.svg`}
              alt="line"
            />
            <MenuItemNavigation />
          </div>
        </div>
        <div className="absencesActionContainer">
          {location?.pathname !== "/" &&
          location?.pathname !== "/menu/settings" &&
          location?.pathname !== "/menu/settings/roles" &&
          location?.pathname !== "/menu/settings/manage" &&
          location?.pathname !== "/menu/integrations/personio" &&
          location?.pathname !== "/menu/integrations/microsoft" &&
          location?.pathname !== "/menu/contact-us" &&
          location?.pathname !== "/menu/about" &&
          location?.pathname !== "/menu/privacy" &&
          location?.pathname !== "/menu/termsofuse" ? (
            <Input
              className="absencesSearch"
              contentAfter={
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/search.svg`}
                  alt="search"
                />
              }
              placeholder={t("absencesOwnHr.navbar.find")}
              value={searchField}
              onChange={handleSearchValueChange}
            />
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default SimpleNavbar;
