import { Button } from "@fluentui/react-components";
import { TeamsConfigWrapper } from "../contexts/TeamsConfigWrapper";
import { useStore } from "zustand";
import { useTeamsContextStore } from "../store/teamsContextStore";
import OrganizationService from "../services/organization";
import "./Config.css";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";
import Error from "./Error";

export default function Config() {
  const permissionsUrl: string = `https://login.microsoftonline.com/common/adminconsent?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(
    `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/auth-end.html`
  )}`;
  const windowParams: string =
    "popup=true,width=610,height=650,location=no,menubar=no,toolbar=no,status=no";
  const teamsContext = useStore(useTeamsContextStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  const onSubmitConfig = async () => {
    try {
      const result = await OrganizationService.checkOrganization();
      if ("status" in result && result?.status === "error") {
        throw <Error message="error" />;
      } else {
        initialSetupOrganizationStore.setSetupCompleted(
          result?.organizationExists
        );
      }
    } catch (e) {
      throw <Error message="error" />;
    }
  };
  return (
    <TeamsConfigWrapper
      contentUrl={process.env.REACT_APP_TARGO_TAB_ENDPOINT ?? ""}
      suggestedDisplayName="Targo360"
      entityId="teamstargotab"
      onSubmitConfig={onSubmitConfig}
      {...teamsContext}
    >
      <div className="configPageWrapper">
        <div className="configTitle">
          To get started, Targo360 needs a few permissions from your Microsoft
          account. You must review and accept the requested permissions to use
          the app.
        </div>
        <Button
          appearance="primary"
          type="button"
          onClick={() => {
            window.open(permissionsUrl, "targoConfig", windowParams);
          }}
        >
          Review Permissions
        </Button>
      </div>
    </TeamsConfigWrapper>
  );
}
