import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "./DatePicker.css";

export default function DatePickerComponent({
  name,
  handleChange,
  handleBlur,
  styleProps,
  dateRange,
  value,
  disabled,
}: any) {
  const [startDate, endDate] = dateRange;

  const years = range(getYear(new Date()) - 1, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <DatePicker
      disabled={disabled}
      name={name}
      value={value}
      formatWeekDay={(day) => day[0]}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        return (
          <div className="headerDatePicker">
            <div className="infoMonthAndYear">
              <select
                className="selectDatePicker"
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                className="selectDatePicker"
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value as any)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <button
                className="arrowButton"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_509_43323"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M12.3544 15.853C12.1594 16.0486 11.8429 16.0492 11.6472 15.8542L6.16276 10.3893C5.94705 10.1743 5.94705 9.82501 6.16276 9.61006L11.6472 4.14508C11.8429 3.95017 12.1594 3.95074 12.3544 4.14634C12.5493 4.34195 12.5487 4.65854 12.3531 4.85345L7.18851 9.99967L12.3531 15.1459C12.5487 15.3408 12.5493 15.6574 12.3544 15.853Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_509_43323)">
                    <rect width="20" height="20" fill="#242424" />
                  </g>
                </svg>
              </button>

              <button
                className="arrowButton"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_509_43333"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M7.64582 4.14708C7.84073 3.95147 8.15731 3.9509 8.35292 4.14582L13.8374 9.6108C14.0531 9.82574 14.0531 10.1751 13.8374 10.39L8.35292 15.855C8.15731 16.0499 7.84073 16.0493 7.64582 15.8537C7.4509 15.6581 7.45147 15.3415 7.64708 15.1466L12.8117 10.0004L7.64708 4.85418C7.45147 4.65927 7.4509 4.34269 7.64582 4.14708Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_509_43333)">
                    <rect width="20" height="20" fill="#242424" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        );
      }}
      className={styleProps}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      onBlur={handleBlur}
      isClearable={true}
      dateFormat="dd/MM/yyyy"
    />
  );
}
