import { Button, Image, Radio } from "@fluentui/react-components";
import { useEffect, useState, useRef } from "react";
import "./TimerComponent.css";
import CheckInModal from "./Modals/CheckInModal";
import { useTranslation } from "react-i18next";
import { useAttendancesStore } from "../../../store/attendancesStore";
import { useStore } from "zustand";
import attendanceService from "../../../services/attendance";
import moment from "moment";
import { toast } from "react-toastify";

function TimerComponent() {
  const attendancesStore = useStore(useAttendancesStore);
  const [isChecked, setIsChecked] = useState(
    Object.keys(attendancesStore.started).length !== 0 &&
      attendancesStore.started.type === "work"
  );
  const [time, setTime] = useState<number>(0);
  const [isBreak, setIsBreak] = useState(
    Object.keys(attendancesStore.started).length !== 0 &&
      attendancesStore.started.type === "break"
  );
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);
  const { t } = useTranslation();
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const getInitialStartAttendance = async () => {
    const result = await attendanceService.getInitialStartAttendance();
    if (result && "attendance" in result) {
      if (result.attendance && result?.attendance?.AttendanceEndTime == null) {
        attendancesStore.setStarted({
          attendanceId: result?.attendance?.AttendanceId,
          time: moment(result?.attendance?.AttendanceStartTime),
          type: result?.attendance?.AttendanceType,
        });
        if (result?.attendance?.AttendanceType === "break") {
          setIsBreak(true);
        } else {
          setIsChecked(true);
          setIsBreak(false);
        }
      }
    }
  };

  const endAttendance = async () => {
    if (attendancesStore.started.attendanceId) {
      try {
        const result = await attendanceService.endAttendance(
          attendancesStore.started.attendanceId
        );
        if ("attendance" in result) {
          attendancesStore.addAttendance(result?.attendance);
          attendancesStore.setStarted({});
          setIsChecked(false);
          if (intervalId.current) {
            clearInterval(intervalId.current);
            intervalId.current = null;
            setTime(0);
          }
          toast.success(t("attendanceOwnHr.checkIn.success"), {
            position: "bottom-right",
          });
        }
      } catch (e) {
        toast.error(t("attendanceOwnHr.checkIn.errors.errorStarting"), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      }
    }
  };

  useEffect(() => {
    if (Object.keys(attendancesStore.started).length === 0) {
      getInitialStartAttendance();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(attendancesStore.started).length !== 0) {
      if (isChecked || isBreak) {
        intervalId.current = setInterval(() => {
          const timer = moment().diff(attendancesStore.started.time, "seconds");
          setTime(timer);
        }, 1000);
      }
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [attendancesStore.started, isChecked, isBreak]);

  const handleToggle = async () => {
    if ((isChecked || isBreak) && attendancesStore.started.attendanceId) {
      const result = await attendanceService.endAttendance(
        attendancesStore.started.attendanceId
      );
      if ("attendance" in result) {
        attendancesStore.addAttendance(result?.attendance);
        attendancesStore.setStarted({});
        setIsChecked(false);
        if (intervalId.current) {
          clearInterval(intervalId.current);
          intervalId.current = null;
          setTime(0);
        }
      }
    }
    if (!isBreak) {
      setIsBreak(true);
      openCheckInModal();
    } else {
      setIsBreak(!isBreak);
    }
  };

  const handleCheckin = async () => {
    if (isBreak && attendancesStore.started.attendanceId) {
      const result = await attendanceService.endAttendance(
        attendancesStore.started.attendanceId
      );
      if ("attendance" in result) {
        attendancesStore.addAttendance(result?.attendance);
        attendancesStore.setStarted({});
        setIsChecked(false);
        if (intervalId.current) {
          clearInterval(intervalId.current);
          intervalId.current = null;
          setTime(0);
        }
      }
      setIsBreak(false);
    }
    openCheckInModal();
  };

  const formatTimer = (dt: number) => {
    const seconds = dt % 60;
    const minutes = Math.floor((dt % 3600) / 60);
    const hours = Math.floor(dt / 3600);
    if (hours === 0)
      return `${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`;
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  const openCheckInModal = () => {
    setCheckInModalOpen(true);
  };

  const closeCheckInModal = () => {
    setCheckInModalOpen(false);
  };

  return (
    <div className="attendanceActions">
      <div className="attendanceActionsButtons">
        <Button
          appearance="primary"
          className={isChecked ? "checkOutButton" : "checkInButton"}
          onClick={handleCheckin}
          disabled={
            Object.keys(attendancesStore.started).length !== 0 && isChecked
          }
        >
          <span
            className={
              isChecked ? "checkOutButtonContent" : "checkInButtonContent"
            }
          >
            <Radio
              value="check"
              checked={isChecked}
              disabled={
                Object.keys(attendancesStore.started).length !== 0 && isChecked
              }
            />
            {t("attendanceOwnHr.timer.checkIn")}
          </span>
        </Button>
        <Button
          appearance={isChecked ? "primary" : "secondary"}
          className={isChecked ? "checkInButton" : "checkOutButton"}
          onClick={endAttendance}
          disabled={!isChecked}
        >
          <span
            className={
              isChecked ? "checkInButtonContent" : "checkOutButtonContent"
            }
          >
            {t("attendanceOwnHr.timer.checkOut")}
          </span>
        </Button>
      </div>

      <div className="attendanceActionsTimer">
        <div className="timerContainer">
          <span>
            {isChecked &&
            attendancesStore.started &&
            attendancesStore.started.type === "work"
              ? formatTimer(time)
              : "00:00"}
          </span>
          <span className="timerDuration">
            (
            {isBreak &&
            attendancesStore.started &&
            attendancesStore.started.type === "break"
              ? formatTimer(time)
              : "00:00"}
            )
          </span>
          <Image
            className="clock"
            src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
            alt="clock"
          />
        </div>

        <div className="toggleContainer">
          <button
            className={`toggleBtn ${isBreak ? "toggled" : ""}`}
            onClick={() => {
              setIsBreak(!isBreak);
              handleToggle();
            }}
          >
            <div className="thumb"></div>
          </button>
          {isBreak ? (
            <span className="toggleMessage">
              {t("attendanceOwnHr.timer.breakOn")}
            </span>
          ) : (
            <span className="toggleMessage">
              {t("attendanceOwnHr.timer.breakOff")}
            </span>
          )}
        </div>
      </div>

      <CheckInModal
        isBreak={isBreak}
        setIsChecked={setIsChecked}
        isModalOpen={checkInModalOpen}
        closeModal={closeCheckInModal}
      />
    </div>
  );
}

export default TimerComponent;
