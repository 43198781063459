import { Image } from "@fluentui/react-components";
import "./ContactUsCard.css";

interface ContactUsCardProps {
  title: string;
  cardLink: string;
  cardLinkUrl: string;
  imageUrl: string;
  iconUrl: string;
}

const ContactUsCard: React.FC<ContactUsCardProps> = ({
  title,
  cardLink,
  cardLinkUrl,
  imageUrl,
  iconUrl,
}) => {
  return (
    <div className="cardContainer">
      <Image src={imageUrl} className="contactUsCardImage" alt="" />
      <div className="cardInfoContainer">
        <span className="cardTitle">{title}</span>
        <div className="linksContainer">
          <a className="cardLink" href={cardLinkUrl} target="_blank">
            {cardLink}
          </a>
          <Image src={iconUrl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ContactUsCard;
