import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Image,
  Input,
  Select,
  SelectProps,
} from "@fluentui/react-components";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./AbsenceRequest.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerComponent from "./DatePicker";
import { useTimeOffsStore } from "../../../store/timeOffsStore";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import moment from "moment";
import timeOffService from "../../../services/timeOff";

export default function AbsenceRequest(props: SelectProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [daysLeft, setDaysLeft] = useState<number | undefined>(undefined);
  const [selectedDays, setSelectedDays] = useState<number | undefined>(
    undefined
  );
  const [selectedType, setSelectedType] = useState<number | undefined>(
    undefined
  );
  const [dateRange, setDateRange] = useState([null, null]);
  const timeOffStore = useStore(useTimeOffsStore);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  const initialValues = {
    absencesPeriod: "",
    absencesType: "",
    comment: "",
  };

  const validationSchema = Yup.object().shape({
    absencesPeriod: Yup.array()
      .of(
        Yup.date().required(
          t("absencesOwnHr.request.errors.absencesPeriodIsRequired")
        )
      )
      .test(
        "numberOfDays",
        t("absencesOwnHr.request.errors.youDontHaveEnoughDays"),
        () => {
          if (!selectedType) return true;
          if (
            selectedDays !== undefined &&
            daysLeft !== undefined &&
            selectedDays > daysLeft
          )
            return false;
          return true;
        }
      )
      .required(
        t("absencesOwnHr.request.errors.absencesPeriodRangeIsRequired")
      ),
    absencesType: Yup.string().required(
      t("absencesOwnHr.request.errors.typeIsRequired")
    ),
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      const result = await timeOffService.addTimeOff(
        moment(values.absencesPeriod[0]).format("YYYY-MM-DD"),
        moment(values.absencesPeriod[1]).format("YYYY-MM-DD"),
        values.absencesType,
        values.comment
      );
      if ("status" in result && result?.status === "error") {
        toast.error(t(`absencesOwnHr.request.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else {
        timeOffStore.addTimeOff(result?.timeOff);
        timeOffStore.setUserBalance(result?.userBalance);
        toast.success(t("absencesOwnHr.table.success"), {
          position: "bottom-right",
        });
        setIsExpanded(false);
        resetForm();
        setDateRange([null, null]);
        setDaysLeft(undefined);
        setSelectedDays(undefined);
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.request.errors.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
      setIsExpanded(false);
      resetForm();
      setDateRange([null, null]);
    } finally {
      setSubmitting(false);
    }
  };

  const updateDaysLeft = (selectedType: string) => {
    if (selectedType === "1") {
      setSelectedType(1);
      setDaysLeft(
        (timeOffStore?.userBalance?.AnnualDays ?? 0) -
          (timeOffStore.userBalance.AnnualBalance ?? 0)
      );
    } else if (selectedType === "2") {
      setSelectedType(2);
      setDaysLeft(undefined);
    } else if (selectedType === "3") {
      setSelectedType(3);
      setDaysLeft(undefined);
    } else if (selectedType === "4") {
      setSelectedType(4);
      setDaysLeft(undefined);
    } else if (selectedType === "5") {
      setSelectedType(5);
      setDaysLeft(undefined);
    } else {
      setSelectedType(undefined);
      setDaysLeft(undefined);
    }
  };

  const updateNumberOfDays = ([start, end]: [any, any]) => {
    if (start && end) {
      const startDate = moment(start);
      const endDate = moment(end);
      let overlapDuration = 0;
      let currentDate = startDate.clone();

      while (currentDate.isSameOrBefore(endDate, "day")) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          overlapDuration++;
        }
        currentDate.add(1, "day");
      }
      setSelectedDays(overlapDuration);
    } else {
      setSelectedDays(undefined);
    }
  };

  return (
    <div className={`absenceRequestContainer ${isExpanded ? "expanded" : ""}`}>
      <div className="absenceRequestContent">
        <div
          className={`absenceRequestTitle ${
            isExpanded ? "expandedAbsenceRequestTitle" : ""
          }`}
        >
          {!isExpanded ? "Absence" : "Absences"}
        </div>
        <div className="absenceRequestActions">
          {!isExpanded && (
            <>
              <Button
                appearance="primary"
                className="absenceRequestButton"
                onClick={handleExpand}
              >
                {t("absencesOwnHr.request.request")}
              </Button>
              <Image
                className="arrowDownLogo"
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/arrowDown.svg`}
                alt="arrowDown"
                onClick={handleExpand}
              />
            </>
          )}
          {isExpanded && (
            <Image
              className="arrowUpLogo"
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/arrowUp.svg`}
              alt="arrowUp"
              onClick={handleCollapse}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => (
            <Form className="absenceRequestForm">
              <div className="absenceRequestFormContainer">
                <div className="expandedContent">
                  <div className="absencesInputContainer">
                    <div className="datePickerLabel">
                      <label className="defaultAbsencesRequestLabel">
                        {t("absencesOwnHr.request.absencesPeriod")}
                      </label>
                      {daysLeft || daysLeft === 0 ? (
                        <div className="daysLeftContainer">
                          <span className="daysLeftText">
                            {daysLeft === 1
                              ? `${daysLeft} ${t(
                                  "absencesOwnHr.request.dayLeft"
                                )}`
                              : `${daysLeft} ${t(
                                  "absencesOwnHr.request.daysLeft"
                                )}`}
                          </span>
                          <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.02006 8.35374C6.21532 8.15847 6.21532 7.84189 6.02006 7.64663C5.8248 7.45137 5.50821 7.45137 5.31295 7.64663L3.81295 9.14663C3.61769 9.34189 3.61769 9.65847 3.81295 9.85374C4.00821 10.049 4.3248 10.049 4.52006 9.85374L6.02006 8.35374ZM11.6165 1.05052C10.2497 -0.316318 8.0336 -0.316318 6.66677 1.05052L1.71702 6.00026C0.350186 7.3671 0.350186 9.58318 1.71702 10.95C3.08386 12.3168 5.29993 12.3168 6.66677 10.95L11.6165 6.00026C12.9833 4.63343 12.9834 2.41735 11.6165 1.05052ZM4.54545 4.58605L8.08098 8.12158L5.95966 10.2429C4.98335 11.2192 3.40044 11.2192 2.42413 10.2429C1.44782 9.26659 1.44782 7.68368 2.42413 6.70737L4.54545 4.58605ZM10.9094 5.29316L8.78809 7.41447L5.25256 3.87894L7.37387 1.75762C8.35019 0.781313 9.9331 0.781313 10.9094 1.75762C11.8857 2.73393 11.8857 4.31685 10.9094 5.29316Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      ) : null}
                    </div>
                    <DatePickerComponent
                      name="absencesPeriod"
                      styleProps="absencesInput"
                      handleChange={(update: any) => {
                        setDateRange(update);
                        handleChange({
                          target: { name: "absencesPeriod", value: update },
                        });
                        updateNumberOfDays(update);
                      }}
                      onBlur={handleBlur}
                      dateRange={dateRange}
                    />
                    <div className="datePickerFotter">
                      <ErrorMessage
                        name="absencesPeriod"
                        component="div"
                        className="error-message"
                      />
                      {selectedDays ? (
                        <div className="selectedDaysNumber">
                          {selectedDays === 1
                            ? `${selectedDays} ${t(
                                "absencesOwnHr.request.day"
                              )}`
                            : `${selectedDays} ${t(
                                "absencesOwnHr.request.days"
                              )}`}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="absencesInputContainer">
                    <label className="defaultAbsencesRequestSecondLabel">
                      {t("absencesOwnHr.request.type")}
                    </label>
                    <Select
                      name="absencesType"
                      className="absencesInput"
                      appearance="filled-lighter"
                      onChange={(e) => {
                        handleChange(e);
                        updateDaysLeft(e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">
                        {t("absencesOwnHr.request.selectType")}
                      </option>
                      {timeOffStore.timeOffTypes.map((timeOffType) => (
                        <option value={timeOffType?.Id} key={timeOffType?.Id}>
                          {timeOffType.Name}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="absencesType"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="absencesInputContainer">
                    <label className="defaultAbsencesRequestSecondLabel">
                      {t("absencesOwnHr.request.comment")}
                    </label>
                    <Input
                      name="comment"
                      className="absencesInput"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="requestAbsenceContainer">
                  <Button
                    appearance="primary"
                    type="submit"
                    className="requestAbsenceButton"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Request Absence"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
