interface ErrorType {
  message: string;
}

export default function Error(props: ErrorType) {
  const { message } = props;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {message}
    </div>
  );
}
