import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
} from "@fluentui/react-components";

interface IDeleteDialog {
  open: boolean;
  closeDialog: React.MouseEventHandler<HTMLButtonElement>;
  deleteF: React.MouseEventHandler<HTMLButtonElement>;
  question: string;
  description: string;
}

export const DeleteDialog: React.FC<IDeleteDialog> = ({
  open,
  closeDialog,
  deleteF,
  question,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog modalType="alert" open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{question}</DialogTitle>
          <DialogContent>{description}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                type="button"
                onClick={closeDialog}
              >
                {t("attendanceOwnHr.detailedAttendanceTable.dialog.close")}
              </Button>
            </DialogTrigger>
            <Button appearance="primary" type="button" onClick={deleteF}>
              {t("attendanceOwnHr.detailedAttendanceTable.dialog.yes")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
