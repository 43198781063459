import { useState } from "react";
import { Button, Image, Spinner } from "@fluentui/react-components";

interface IInitialSSOFallbackConsentModal {
  afterInitialSSOConsented: () => Promise<void>;
  closeInitialSSOConsentModal: () => void;
}

export const InitialSSOFallbackConsentModal = ({
  afterInitialSSOConsented,
  closeInitialSSOConsentModal,
}: IInitialSSOFallbackConsentModal): React.ReactElement => {
  const windowParams: string =
    "popup=true,width=610,height=650,location=no,menubar=no,toolbar=no,status=no";
  const channelAPIpermissionsAADUrl: string = `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const [isNextConsentButtonVisible, setIsNextConsentButtonVisible] =
    useState<boolean>(false);

  return (
    <>
      <p>
        We need to ask for permissions. Targo360 will sign in using your Teams
        account (SSO).
      </p>
      <div>
        {isNextConsentButtonVisible ? (
          <Button
            type="button"
            onClick={async () => {
              await afterInitialSSOConsented();
              closeInitialSSOConsentModal();
            }}
          >
            Next
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => {
              const consentWindow = window.open(
                channelAPIpermissionsAADUrl,
                "targoConfig",
                windowParams
              );
              setIsNextConsentButtonVisible(true);
              window.addEventListener("message", (event: any): void => {
                let timer: ReturnType<typeof setTimeout>;

                if (event.origin === process.env.REACT_APP_TARGO_TAB_ENDPOINT) {
                  timer = setTimeout(() => {
                    consentWindow?.close();
                    clearTimeout(timer);
                  }, 1500);
                }
              });
            }}
          >
            Continue
          </Button>
        )}
      </div>
    </>
  );
};
