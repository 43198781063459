import Modal from "../../Modal";
import { useTranslation } from "react-i18next";
import "./AddRole.css";
import { Form, Formik, FieldArray } from "formik";
import { Input, Checkbox } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useSettingsStore } from "../../../store/settingsStore";

interface IViewRole {
  isModalOpen: boolean;
  closeModal: () => void;
  roleId: number | null;
}

const ViewRole: React.FC<IViewRole> = ({ isModalOpen, closeModal, roleId }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    permissions: [],
  });
  const settingsStore = useStore(useSettingsStore);

  useEffect(() => {
    if (roleId) {
      const role = settingsStore?.roles?.find((rl) => rl?.Id === roleId);
      if (role) {
        let perms: number[] = [];
        role?.Permissions?.forEach((perm) => perms.push(perm?.Id));
        setInitialValues({
          name: role?.RoleName || "",
          description: role?.Description || "",
          permissions: perms,
        });
      } else {
        setInitialValues({
          name: "",
          description: "",
          permissions: [],
        });
      }
    } else {
      setInitialValues({
        name: "",
        description: "",
        permissions: [],
      });
    }
  }, [roleId, settingsStore.roles]);

  const handleSubmit = async () => {
    console.log("");
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Targo360"
        subtitle={t("settings.rolesAndPermissions.modals.settingsRoles")}
        contentTitle={t("settings.rolesAndPermissions.modals.viewRole")}
        headerStyle="addRoleModalHeader"
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="addRoleForm">
              <div className="addRoleFormField">
                <label className="addRoleFieldLabel">
                  {t("settings.rolesAndPermissions.modals.name")}
                </label>
                <Input
                  name="name"
                  value={values.name || ""}
                  className="addRoleInputStyle"
                  appearance="filled-lighter"
                  disabled
                />
              </div>
              <div className="addRoleFormField">
                <label className="addRoleFieldLabel">
                  {t("settings.rolesAndPermissions.modals.description")}
                </label>
                <Input
                  name="description"
                  value={values.description || ""}
                  className="addRoleInputStyle"
                  appearance="filled-lighter"
                  disabled
                />
              </div>
              <div className="addRoleFormField">
                <label className="addRoleFieldLabel">
                  {t("settings.rolesAndPermissions.modals.permissions")}
                </label>
                <FieldArray name="permissions">
                  {() => (
                    <div>
                      {settingsStore.permissions.map((permission) => (
                        <div key={permission?.Id}>
                          <Checkbox
                            shape="circular"
                            label={
                              <div className="addRoleRadioLabelContainer">
                                <div>{permission?.Category}</div>
                                <div>{permission?.Name}</div>
                              </div>
                            }
                            checked={values.permissions.includes(
                              permission?.Id
                            )}
                            disabled
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ViewRole;
