import { useState } from "react";
import { Button, Image, Spinner } from "@fluentui/react-components";

interface IApiConsentModal {
  closeApiConsentModal: () => void;
}

export const ApiConsentModal = ({
  closeApiConsentModal,
}: IApiConsentModal): React.ReactElement => {
  const windowParams: string =
    "popup=true,width=610,height=650,location=no,menubar=no,toolbar=no,status=no";
  const channelAPIpermissionsAADUrl: string = `https://login.microsoftonline.com/common/adminconsent?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(
    `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/auth-end.html`
  )}`;
  const [isRefreshButtonVisible, setIsRefreshButtonVisible] =
    useState<boolean>(false);
  return (
    <>
      <p>
        We need to ask for additional permissions. You should only need to do
        this once for Targo360.
      </p>
      <div>
        <Button
          type="button"
          onClick={() => {
            const consentWindow = window.open(
              channelAPIpermissionsAADUrl,
              "targoConfig",
              windowParams
            );
            setIsRefreshButtonVisible(true);
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};
