import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as msTeams from "@microsoft/teams-js";
import { ITeamsContextStore } from "../store/teamsContextStore";
import i18next from "i18next";
import "../i18n/i18n";
import { useStore } from "zustand";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";

interface TeamsConfigWrapperProps extends ITeamsContextStore {
  children: React.ReactNode;
  openInitialSSOFallbackConsent?: () => Promise<void>;
  noSSOConsentRoute: string;
  excludedRoutes: string[];
  openAADApiConsentModal?: () => Promise<void>;
  checkOrganization: () => Promise<void>;
  configRoute: string;
  aadToken: string;
}

export const TeamsContextWrapper = ({
  children,
  excludedRoutes,
  isAppInitialized,
  setAadToken,
  setIsAppInitialized,
  openInitialSSOFallbackConsent = async () => {},
  noSSOConsentRoute,
  openAADApiConsentModal = async () => {},
  setIsInTeams,
  checkOrganization,
  configRoute,
  aadToken,
}: TeamsConfigWrapperProps): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  const needsAADConsent = (error: Error): boolean => {
    const mustConsent =
      error.message === "resourceRequiresConsent" ||
      error.message.includes("AADSTS65001");
    return mustConsent;
  };

  const getAADConsentIfNeeded = async () => {
    if (
      !currentPath.includes(noSSOConsentRoute) &&
      !excludedRoutes.includes(currentPath)
    ) {
      try {
        await openAADApiConsentModal();
        const token = await getAuthToken(false);
        setAadToken(token);
        msTeams.app.notifySuccess(); // close loading screen
      } catch (error) {
        if (needsAADConsent(error as Error)) {
          console.error(
            "interactive pop-up method failed, still needs consent, falling back to custom modal"
          );
          await openInitialSSOFallbackConsent();
        }
        if ((error as Error).message === "CancelledByUser") {
          navigate(
            `${noSSOConsentRoute}/?callbackurl=${encodeURIComponent(
              currentPath
            )}`
          );
        }
      }
    }
  };

  useEffect(() => {
    if (isAppInitialized && !currentPath.includes(configRoute)) {
      checkOrganization();
    }
  }, [
    isAppInitialized,
    aadToken,
    configRoute,
    initialSetupOrganizationStore.setupCompleted,
  ]);

  const refreshToken = async (): Promise<void> => {
    try {
      const token = await getAuthToken();
      setAadToken(token);
    } catch (error) {
      if (needsAADConsent(error as Error)) {
        await getAADConsentIfNeeded();
      } else if ((error as Error).message !== "resourceDisabled") {
        setIsInTeams(false);
      }
    }
  };

  useEffect(() => {
    const getTeamsContext = async (): Promise<void> => {
      if (!isAppInitialized && !currentPath.includes(configRoute)) {
        try {
          await msTeams.app.initialize();
          const context = await msTeams.app.getContext();
          const existingLanguage = localStorage.getItem("selectedLanguage");
          if (existingLanguage) {
            i18next.changeLanguage(existingLanguage);
          } else {
            const userLanguage = context?.app?.locale?.split("-")[0] || "en";
            i18next.changeLanguage(userLanguage);
          }
          setIsAppInitialized(msTeams.app.isInitialized());
          setIsInTeams(true);
          await refreshToken();
        } catch (e) {
          console.log("e", e);
          if (needsAADConsent(e as Error)) {
            await refreshToken();
          } else if ((e as Error).message !== "resourceDisabled") {
            setIsInTeams(false);
          }
        } finally {
          msTeams.app.notifySuccess();
        }
      } else {
        await refreshToken();
      }
    };

    getTeamsContext().catch(console.error);
  }, [currentPath, navigate, isAppInitialized]);

  const getAuthToken = async (isSilent: boolean = true) => {
    return await msTeams.authentication.getAuthToken({ silent: isSilent });
  };

  const requestConsent = () => {
    return new Promise<void>((resolve, reject) => {
      msTeams.authentication
        .authenticate({
          url: `${window.location.origin}/auth-start?clientId=${process.env.REACT_APP_CLIENT_ID}&scope=openid profile offline_access Mail.Send RoleManagement.Read.Directory User.Read.All User.ReadBasic.All`,
          width: 600,
          height: 535,
        })
        .then((result) => {
          resolve();
        })
        .catch((reason) => {
          console.error("Consent failed: ", reason);
          reject(reason);
        });
    });
  };

  const getClientSideToken = () => {
    return new Promise<string>((resolve, reject) => {
      msTeams.authentication
        .getAuthToken()
        .then((result: string) => {
          resolve(result);
        })
        .catch((error) => {
          console.error("Error getting token: ", error);
          reject("Error getting token: " + error);
        });
    });
  };

  return <>{children}</>;
};
